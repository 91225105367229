<div class=" ma-5 pa-2 block-padding" style="background-color: #EDEDED">
  <div class="content">
    <h3 class="is-size-3-desktop has-text-centered is-size-4-mobile is-size-4-tablet">
      {{ "HOME.TEXT_25" | translate }}
    </h3>
    <div class="columns services-wrapper">
      <div class="column is-half-desktop pb-0">
        <img src="assets/img/service-1.jpg" />
        <p class="text_30 details mt-3" id="home_car">
          <span>{{ "HOME.TEXT_27" | translate }}</span><span style="font-weight:bold">6 Ay</span><span> veya</span><span style="font-weight:bold"> 5.000 Km arabamaservis.com </span><span>garantisindedir.</span>
        </p>
        <!-- <div class="columns services">
          <div class="column">
            <img src="assets/img/stering.png" />
          </div>
          <div class="column">
            <h5 class="">{{ "HOME.TEXT_26" | translate }}</h5>
          </div>
          <div class="column service-title-wrapper">
            <div class="service-title">
              <h6>{{ "HOME.TEXT_57" | translate }}</h6>
            </div>
          </div>
        </div>
        <div class="column">
          <p class="text_30 details">
            {{ "HOME.TEXT_27" | translate }}
          </p>
        </div> -->
      </div>
      <div class="column is-half-desktop pb-0">
        <img src="assets/img/service-2.jpg" />
        <p class="text_30 details mt-3">
          <span  style="font-weight:bold">arabamaservis.com</span> {{ "HOME.TEXT_65" | translate }}<span style="font-weight:bold">Arabam güvende sigorta&ensp;</span><span>koruması altındadır.</span>
        </p>
        <!-- <div class="columns services">
          <div class="column">
            <img src="assets/img/stering.png" />
          </div>
          <div class="column">
            <h6 class="is-size-h6 has-text-white">
              {{ "HOME.TEXT_28" | translate }}
            </h6>
          </div>
          <div class="column service-title-wrapper">
            <div class="service-title">
              <h6>{{ "HOME.TEXT_57" | translate }}</h6>
            </div>
          </div>
        </div>
        <div class="column">
          <p class="text_30 details">
            {{ "HOME.TEXT_29" | translate }}
          </p>
        </div> -->
      </div>
    </div>
  </div>
  <div></div>
</div>
