<div>
    <nav class="navbar" role="navigation" aria-label="main navigation" style="min-height: 48px;">
      <div
        class="navbar-brand is-pa-l-10" 
      >
        <img
          routerLink="/home"
          class="is-clickable"
          src="assets/img/arabama.png"
          style="display: block"/>
</div>
          <div class="navbar-menu pr-6">
            <div  class="navbar-end" style="margin-left: auto;">
            <div class="navbar-item">
                <a routerLink="/cookie-policy" class="navbar-item">
                  <span class="has-text-weight-bold color-arabama-green">Cookies Policy</span>
                </a>
              </div>
              <div class="navbar-item">
                <a routerLink="/privacy-policy" class="navbar-item">
                  <span class="has-text-weight-bold">Privacy Policy</span>
                </a>
              </div>
              <div class="navbar-item">
                <a routerLink="/terms-conditions" class="navbar-item">
                  <span class="has-text-weight-bold">Terms & Conditions</span>
                </a>
              </div>
          </div>
          </div>
        </nav>
</div>


<div class="content">
    <!-- <img src="../../../assets/img/arabama.png" alt="arabama-logo"> -->
    <h1>Cookies Policy</h1>
    <h3 class="pt-20">Introduction</h3>
    <p>Welcome to Arabama Servis. This Cookie Policy explains how we use cookies and similar tracking technologies on our mobile application ("App"). By using our App, you agree to the use of cookies in accordance with this policy. If you do not agree with our use of cookies, you should disable cookies in your browser or refrain from using our App.</p>
    <br>
    <h3>What Are Cookies?</h3>
    <p>Cookies are small text files that are stored on your device (computer, smartphone, or other devices) when you visit certain websites or use certain mobile applications. Cookies allow the app to recognize your device and store information about your preferences or past actions.</p>
    <br>
    <h3>How We Use Cookies</h3>
    <p>We use cookies and similar tracking technologies for the following purposes:</p>
    <div class="pt-10">
        <p class="text-ul">Essential Cookies:</p>
        <p class="">These cookies are necessary for the App to function correctly. They enable you to navigate our App and use its features, such as accessing secure areas of the App.</p>
    </div>
    <div class="pt-10">
        <p class="text-ul">Performance and Analytics Cookies:</p>
        <p class="">These cookies collect information about how you use our App, such as which pages you visit and if you experience any errors. We use this information to improve the performance of our App.</p>
    </div>
    <div class="pt-10">
        <p class="text-ul">Functionality Cookies:</p>
        <p class="">These cookies allow our App to remember the choices you make (such as your username, language, or the region you are in) and provide enhanced, more personal features.</p>
    </div>
    <div class="pt-10">
        <p class="text-ul">Advertising Cookies:</p>
        <p class="">These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and to help measure the effectiveness of advertising campaigns.</p>
    </div>
    <br>
    <p>Cookies We Use</p>
    <p>Below is a list of cookies that we use in our App:</p>
    <div class="pt-10">
        <p class="text-ul">Session Cookies:</p>
        <p class="">Temporary cookies that remain in the cookie file of your browser until you close the App.</p>
    </div>
    <div class="pt-10">
        <p class="text-ul">Persistent Cookies:</p>
        <p class="">These remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie).</p>
    </div>
    <br>
    <h3>Third-Party Cookies</h3>
    <p>We may also use third-party cookies from service providers like Google Analytics to understand how our App is used and improve user experience. These third-party cookies are governed by the respective privacy policies of the third parties.</p>
    <br>
    <h3>Managing Cookies</h3>
    <p>You have the right to accept or reject cookies. Most browsers and devices automatically accept cookies, but you can modify your settings to decline cookies if you prefer. However, this may prevent you from taking full advantage of our App.</p>
    <br>
    <h3>How to Disable Cookies</h3>
    <div class="">
        <p class="text-ul">Browser Settings:</p>
        <p class="">You can usually modify your browser settings to disable cookies. Please refer to your browser’s help section for more information on how to do this.</p>
    </div>
    <div class="">
        <p class="text-ul">Device Settings:</p>
        <p class="">You can control cookies through your device settings by enabling or disabling specific cookie categories.</p>
    </div>
    <p class="pt-5">Changes to This Cookie Policy</p>
    <p>We may update our Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically to stay informed about our use of cookies and related technologies.</p>
    <br>
    <h3>Contact Us</h3>
    <p>If you have any questions about our use of cookies or this Cookie Policy, please contact us at:</p>
    <br>
    <p>[Arabama servis]</p>
    <p>[Your Address]</p>
    <p>[Your Email Address]</p>
    <p>[Your Contact Number]</p>
    <br>
    <p>Effective Date: [Insert Date]</p>
</div>