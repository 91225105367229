<div
  class="dropdown-head px-2 py-1 is-flex is-align-items-center is-clickable"
  (click)="showOptions()" [ngClass]="disable? 'disable': ''" [ngClass]="alignmentEnd ? 'is-justify-content-end': 'is-justify-content-space-between'">
  <p class="select_input has-text-weight-semibold">{{ selected_option | translate }}</p>
  <img class="mt-2" src="../../../assets/svg/VectorUp.svg" alt="" width="12px" height="12px">
  <div class="options" *ngIf="options">
    <div *ngFor="let option of option_list">   
      <p (click)="selectOption(option)"  [ngClass]="getOptionBackgroundColor(option) == true ? 'is-active' : 'in-active'">{{ option.name | translate }}</p>
    </div>
  </div>
</div>
