<div class="block-padding" style="background-color: #FAFAFA">
    <div class="content ma-4">
    <div class="colums">
        <div class="column p-0">
            <h3 class="is-size-3-desktop is-size-4-mobile is-size-4-tablet has-text-centered mb-0">{{ 'HOME.TEXT_16' | translate }}</h3>
        </div>
    </div>
    <div class="columns is-4 mb-6">
        <div class="column is-5 is-flex is-justify-content-center is-align-items-center"> 
            <img class="pt-5 pr-3" src="assets/img/Arabama-Aboutus.png" />
        </div>
        <div class="column is-7 heading-color-1 pl-0">
            <div class="pt-5">
                <p class="text_30 has-text-weight-bold">
                    {{ 'HOME.TEXT_47' | translate }}
                </p>
                <p class="text_30">{{ 'HOME.TEXT_17' | translate }}</p>
                <p class="text_30">{{ 'HOME.TEXT_18' | translate }}</p>
                <p class="text_30m">{{ 'HOME.TEXT_19' | translate }}</p>
                <div class="columns">
                    <div class="column is-6 pr-0 py-1 is-flex is-align-items-flex-start">
                        <img src="../../../assets/svg/how_driver_new.svg"  alt="" width="28px" height="28px">
                        <p class="text-size-16 pl-2">{{ 'HOME.TEXT_20' | translate }}</p>
                    </div>
                    <div class="column is-6 pr-0 py-1 is-flex is-align-items-flex-start">
                        <img src="../../../assets/svg/how_driver_new.svg" alt="" width="28px" height="28px">
                        <p class="text-size-16 pl-2">{{ 'HOME.TEXT_21' | translate }}</p>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6 pr-0 py-1 is-flex is-align-items-flex-start">
                        <img src="../../../assets/svg/how_driver_new.svg" alt="" width="28px" height="28px">
                        <p class="text-size-16 pl-2">{{ 'HOME.TEXT_22' | translate }}</p>
                    </div>
                    <div class="column is-6 pr-0 py-1 is-flex is-align-items-flex-start">
                        <img src="../../../assets/svg/how_driver_new.svg" alt="" width="28px" height="28px">
                        <p class="text-size-16 pl-2">{{ 'HOME.TEXT_24' | translate }}</p>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6 pr-0 py-1 is-flex is-align-items-flex-start"> 
                        <img src="../../../assets/svg/how_driver_new.svg" alt="" width="28px" height="28px">
                        <p class="text-size-16 pl-2"> {{ 'HOME.TEXT_23' | translate }}</p>
                    </div>
                    <div class="column is-6 pr-0 py-1 is-flex is-align-items-flex-start">
                        <img src="../../../assets/svg/how_driver_new.svg" alt="" width="28px" height="28px">
                        <p class="text-size-16 pl-2">{{ 'HOME.TEXT_64' | translate }}</p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
</div>