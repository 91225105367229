<div class="map-tab">
 <div class="columns m-0">
  <div class="column is-12-mobile is-7-desktop ">
    <!-- <div class="">
      <iframe width="100%" height="200" referrerpolicy="no-referrer-when-downgrade" style="border: 0"
        [src]="mapURL | safe" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div class="">
      <address>  
        {{estimate_item.address1}} {{estimate_item.address2}} {{estimate_item.city}} {{estimate_item.state}}
        {{estimate_item.country}} {{estimate_item.zip_code}} 
      </address>
      <a href="https://www.google.com/maps/dir/?api=1&amp;origin='{{origin}}'&destination={{destination}}" target="_blank">
        Get Directions
      </a>
    </div>
  </div> -->
  <div class="column is-12-mobile is-8-desktop"> 
    <ul *ngFor="let item of estimate_item.timings;index as i" class="location-hours has-text-center">
      <div class="columns m-0"> 
          <span class="mb-3 has-text-weight-semibold is-size-6-tablet" style="display: inline-block; min-width: 60px; color: #838383;">{{item.day}} :</span>  
          <span class="has-text-weight-bold is-size-6-tablet">{{item.time}}</span>
      </div> 
    </ul>
  </div>
</div> 
</div>