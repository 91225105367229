import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Utils } from 'src/app/common/utils';
@Component({
  selector: 'app-current-location',
  templateUrl: './current-location.component.html',
  styleUrls: ['./current-location.component.scss']
})
export class CurrentLocationComponent implements OnInit {

  @Output()
  currentLocation: EventEmitter<any> = new EventEmitter<any>()
  isCurrentLocationEnabled:boolean = false;
  constructor(private passDataService: PassDataService,
              private Utils: Utils) { }

  ngOnInit(): void {
    this.getCurrentLocation('auto')
  }
  onClickCurrentLocation(){
    this.getCurrentLocation('click')
  }
  getCurrentLocation(type:string){
    let _this = this;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.isCurrentLocationEnabled = true
        if(type === 'click'){
          this.passDataService.setCurrentLocation(position)
        }
      },function(err){
        _this.isCurrentLocationEnabled = false
        console.log('location not enabled')
        if(type != 'auto'){
          alert("Location not enabled");  
        }
      },{enableHighAccuracy: true,timeout : 5000});
    }else{
      if(type != 'auto'){
        alert("Location not enabled");  
      }
    }
  }
  
}
