<div>
    <nav class="navbar" role="navigation" aria-label="main navigation" style="min-height: 48px;">
      <div
        class="navbar-brand is-pa-l-10" 
      >
        <img
          routerLink="/home"
          class="is-clickable"
          src="assets/img/arabama.png"
          style="display: block"/>
      </div>
        </nav>
</div>
    <div class="pad-60">
      <div class="column is-8-desktop is-10-tablet">
        <p class="is-size-4 has-text-weight-bold">Title</p>
        <p class="pt-3 has-text-weight-bold">Lorem ipsum dolor sit amet consectetur adipisicing elit. <br> Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.</p>
        <p class="pt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.</p>
        <div class="mt-5 is-flex-mobile is-justify-content-center" >
          <div class="bg-card" >

          </div>
        </div>
      </div>
        
    </div>
