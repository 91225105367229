<div class="mt-3">
<p class="buttons">
    <button class="button is-medium">
    <span class="is-size-6">  {{'COMMON.FRONT' | translate}} </span>
    </button>
    <button class="button is-medium">
      <span class="is-size-6"> {{'COMMON.BACK' | translate}} </span>
    </button>
    <button class="button is-medium">
      <span class="is-size-6"> {{'COMMON.REAR' | translate}} </span>
    </button>
    <button class="button is-medium">
      <span class="is-size-6"> {{'COMMON.BOTH' | translate}}  </span>   
    </button>
  </p>
  <div class="mt-3">
  <!-- <button class="button is-success"  (click)="movePreviousStep()">Previous</button> -->
  <button class="button is-pulled-right is-success mt-3 mr-6"  (click)="moveNextStep()">{{'COMMON.CONTINUE' | translate}}</button>
  </div>
  </div>