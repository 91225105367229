import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassDataService {

  constructor() {
    this.estimateDetails = new BehaviorSubject<any>("default")
  }

  private updateVehicle = new Subject<string>();
  private clickViewAll = new Subject<any>();
  private estimateDetails = new BehaviorSubject<any>("default");
  private currentLocation = new Subject<string>();
  private onBackPressed = new Subject<string>();
  private showMap = new Subject<string>();
  private changeMapText = new Subject<string>()
  private mapGarageValue = new Subject<string>()
  private locationList = new Subject<any>();
  private saveEstimateListener = new Subject<any>();
  private isFromEstimate = new Subject<any>();
  private updateEstimateStatus = new Subject<any>()
  private outsideClickListener = new Subject<any>();
  pageIndex: any = "profile";


  public getVehicleData(): Observable<string> {
    return this.updateVehicle.asObservable();
  }

  public updateVehicleData(data: any): void {
    this.updateVehicle.next(data);
  }

  public getViewAllData(): Observable<any> {
    return this.clickViewAll.asObservable();
  }

  public updateViewAlldata(data: any): void {
    this.clickViewAll.next(data)
  }

  public updateEstimateData(data: any): void {
    this.estimateDetails.next(data)
  }

  public getEstimateData(): Observable<any> {
    return this.estimateDetails.asObservable();
  }

  //get current location data
  public setCurrentLocation(currentLocationLatLog: any) {
    return this.currentLocation.next(currentLocationLatLog)
  }
  public getCurrentLocation() {
    return this.currentLocation.asObservable()
  }

  //on Back pressed clicked handle
  public setBackPressed(data: any) {
    return this.onBackPressed.next(data)
  }
  public getBackPressed() {
    return this.onBackPressed.asObservable()
  }


  public setShowMapView(data: any) {
    return this.showMap.next(data)
  }
  public getMapView() {
    return this.showMap.asObservable()
  }

  public setChangeMapText(data: any) {
    return this.changeMapText.next(data)
  }
  public getChangeMapText() {
    return this.changeMapText.asObservable()
  }

  public setGarageValue(data: any) {
    return this.mapGarageValue.next(data)
  }
  public getGarageValue() {
    return this.mapGarageValue.asObservable()
  }

  public setSaveEstimateListener(data: any) {
    return this.saveEstimateListener.next(data)
  }
  public getSaveEstimateListener() {
    return this.saveEstimateListener.asObservable()
  }

  public setIsFromEstimate(data: any) {
    return this.isFromEstimate.next(data)
  }
  public getIsFromEstimate() {
    return this.isFromEstimate.asObservable()
  }

  public setUpdateFromEstimate(data: any) {
    return this.updateEstimateStatus.next(data)
  }
  public getUpdateFromEstimate() {
    return this.updateEstimateStatus.asObservable()
  }

  public setOutsideClickListener() {
    return this.outsideClickListener.next(0)
  }
  public getOutsideClickListener() {
    return this.outsideClickListener.asObservable()
  }

  closeSubscribe() {
    this.updateVehicle.unsubscribe()
    this.currentLocation.unsubscribe()
  }

  closeCurrentLocation() {
    this.currentLocation.complete()
  }
}
