<div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 300px;">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div *ngIf="!isDataLoading" class="columns m-0 sec-title-wrapper">
  <div class="column is-8 px-0" style="align-items: center;display: flex;">
      <button class="button back_button" (click)="onBackPressed()">
        <span class="icon is-small is-left"  style="height: 60px; color: #fff;">
          <i class="fas fa-arrow-left"></i>
        </span>
      </button>
      <span class="has-text-weight-bold ml-4 is-size-6-mobile	" style="font-size: 1.3rem;">
        {{'PROFILE.ACCOUT_INFO' | translate}}
      </span>
    </div>
</div>
<div *ngIf="!isDataLoading" class="container-wrapper pb-4">
  <div class="columns">
    <div class="column is-3 pt-4">
      <app-upload-profile-image [profile_img]="profile_img" (upload_profile_img)="get_profile_img($event)"></app-upload-profile-image>
    </div>
    <div class="column is-9 pt-0">
      <div class="columns is-flex-desktop is-flex-tablet is-justify-content-center m-0">

        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3 has-icons-right">
              <input id="firstname" class="input" type="text" [formControl]="firstNameFormControl" (focus)="onFocus('firstname')" (blur)="onBlur('firstname')"
                (ngModelChange)="onInputFirstNameChange($event)"
                [(ngModel)]="user.firstName">
                <label [ngClass]="isFirstNameValid? 'floating-label' : 'floating-label-new'" [class.active]="isFirstnameActive" for="firstname">{{'COMMON.FIRST_NAME' | translate}}</label>

              <span class="icon column is-flex is-justify-content-end is-right is-size-4 has-text-danger" style="padding: 1px 13px 22px;">*</span>
              <label *ngIf="!isFirstNameValid" class="pl-2 text-size-11"
                style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_first_name'|translate}}</label>
            </div>
          </div>
        </div>
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3 has-icons-right">
              <input id="lastname" class="input" type="text" [formControl]="lastNameFormControl" (focus)="onFocus('lastname')" (blur)="onBlur('lastname')"
                (ngModelChange)="onInputLastNameChange($event)"
                [(ngModel)]="user.lastName">
                <label [ngClass]="isLastNameValid? 'floating-label' : 'floating-label-new'" [class.active]="isLastnameActive" for="lastname">{{'COMMON.LAST_NAME' | translate}}</label>
                <span class="icon column is-flex is-justify-content-end is-right is-size-4 has-text-danger" style="padding: 1px 13px 22px;">*</span>
              <label *ngIf="!isLastNameValid" class="pl-2 text-size-11"
                style="margin-left : 3px; color : red">{{'ERROR_MESSAGE.error_valid_last_name' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-flex-desktop is-flex-tablet is-justify-content-center m-0">
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3 has-icons-right">
              <input id="email" class="input" type="email" [formControl]="emailIdFormControl" (focus)="onFocus('email')" (blur)="onBlur('email')"
                (ngModelChange)="onInputEmailChange($event)"
                [(ngModel)]="user.email" readonly>
                <label [ngClass]="isEmailValid? 'floating-label' : 'floating-label-new'" [class.active]="isEmailActive" for="email">{{'COMMON.E_MAIL_TEXT' | translate}}</label>
                <span class="icon column is-flex is-justify-content-end is-right is-size-4 has-text-danger" style="padding: 1px 13px 22px;">*</span>
              <label *ngIf="!isEmailValid" class="pl-2 text-size-11"
                style="margin-left : 3px; color : red">{{'ERROR_MESSAGE.error_valid_email' | translate}}</label>
            </div>
          </div>
        </div>
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3 has-icons-right">
              <input id="phone" class="input" type="tel" [formControl]="phoFormControl" minlength="17" maxlength="17" (focus)="onFocus('phone')" (blur)="onBlur('phone')"
                 [value]=[ph] (ngModelChange)="onInputPhChange($event)"
                [(ngModel)]="user.mobile" (keypress)="numberOnly($event)">
                <label [ngClass]="isPhnValid? 'floating-label' : 'floating-label-new'" [class.active]="isPhoneActive" for="phone">{{'COMMON.PHONE_TEXT' | translate}}</label>
                <span class="icon column is-flex is-justify-content-end is-right is-size-4 has-text-danger" style="padding: 1px 13px 22px;">*</span>
              <label *ngIf="!isPhnValid" class="pl-2 text-size-11"
                style="margin-left : 3px; color:red">{{'ERROR_MESSAGE.error_valid_phone' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-flex-desktop is-flex-tablet is-justify-content-center m-0">
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3 dob-field">
                <mat-form-field appearance="fill" (click)="picker.open()">
                  <input id="dob" matInput [matDatepicker]="picker" [matDatepickerFilter]="myFilter" [formControl]="dobFormControl" [(ngModel)]="user.dob" (focus)="onFocus('dob')" (blur)="onBlur('dob')" readonly>
                  <label class="floating-label" [class.active]="isdobActive" for="dob">{{ 'COMMON.SELECT_DOB' | translate }}</label>
                  <mat-datepicker-toggle matSuffix [for]="picker" (click)="onFocus('dob')"></mat-datepicker-toggle>
                  <mat-datepicker #picker (closed)="onBlur('dob')"></mat-datepicker>
                </mat-form-field>
            </div>
          </div>
        </div>
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field pb-1">
            <div class="control mb-3">
                <!-- <input id="gender" #inputElement class="input pl-3 drop-input" type="text" (focus)="onFocus('gender')" (blur)="onBlur('gender')"
                [formControl]="genderFormControl" [(ngModel)]="user.gender"
                [matAutocomplete]="genderSource" maxlength="0" (click)="openAutocomplete(0)" readonly>
                <label class="floating-label" [class.active]="isGenderActive" for="gender">{{'COMMON.SELECT_GENDER' | translate}}</label>
              <mat-autocomplete #genderSource="matAutocomplete">
                <mat-option *ngFor="let option of genderOptions" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
              <div class="icon drop-icon is-clickable" (click)="openAutocomplete(0); $event.stopPropagation()">
                <i class="fas fa-chevron-down"></i>
              </div> -->
              <app-filter-dropdown [background]="'profile'" [placeholder]="'COMMON.SELECT_GENDER' | translate" [defaultValue]="genderFormControl.value" [optionList]="this.genderOptions" (optionChanged)="selectGender($event)"></app-filter-dropdown>

            </div>
          </div>
        </div>
      </div>
      <div class="columns is-flex-desktop is-flex-tablet is-justify-content-center m-0">
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3">
                <app-filter-dropdown [background]="'profile'" [placeholder]="'COMMON.OCCUPATION' | translate" [defaultValue]="occupationFormControl.value" [optionList]="this.occupationOptions" (optionChanged)="selectOccupation($event)"></app-filter-dropdown>
            </div>
          </div>
        </div>
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control is-medium mb-3">
              <input id="address" class="input" type="text" [formControl]="addressFormControl" (focus)="onFocus('address')" (blur)="onBlur('address')"
                 [(ngModel)]="user.address">
                <label class="floating-label" [class.active]="isAddressActive" for="address">{{'COMMON.ADDRESS' | translate}}</label>
              <!-- <label *ngIf="!isAddressValid" class="pl-2 text-size-11"
                style="margin-left : 3px; color:red">{{'ERROR_MESSAGE.error_valid_address' | translate}}</label> -->
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-flex-desktop is-flex-tablet is-justify-content-center m-0 pb-1">
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control mb-3">
              <app-filter-dropdown [background]="'profile'" [placeholder]="'COMMON.SELECT_CITY' | translate" [defaultValue]="cityFormControl.value" [optionList]="this.optionCity" (optionChanged)="selectCity($event)"></app-filter-dropdown>
              <label *ngIf="!isCityValid" class="pl-2 text-size-11"
              style="margin-left : 3px; color : red">{{'ERROR_MESSAGE.error_city_required'|translate}}</label>
            </div>
          </div>
        </div>
        <div class="column is-narrow-mobile is-6-desktop py-0">
          <div class="field">
            <div class="control mb-3">
              <app-filter-dropdown [background]="'profile'" [placeholder]="'COMMON.SELECT_PROVINCE' | translate" [defaultValue]="districtFormControl.value" [disable]="disableDistrict" [optionList]="this.optionDistrict" (optionChanged)="selectProvince($event)"></app-filter-dropdown>
              <label *ngIf="!isProvinceValid" class="pl-2 text-size-11"
              style="margin-left : 3px; color : red">{{'ERROR_MESSAGE.error_distict_required'|translate}}</label>
            </div>
          </div>
        </div>
       
      </div>
      <div class="columns m-0 is-flex is-justify-content-center">
        <div class="column is-12 py-0">
          <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'PROFILE.TEXT_1' | translate }}</span>{{ 'PROFILE.TEXT_2' | translate }}</p>
          <div class="pt-1">
            <div class="is-flex is-align-items-center">
              <input style="pointer-events: none;" type="checkbox" name="answer" class="mr-2" [formControl] = "memberApprovalFormControl" >
              <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'PROFILE.TEXT_3' | translate }}</span>{{ 'PROFILE.TEXT_4' | translate }}
                <span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'PROFILE.TEXT_5' | translate }}</span>{{ 'PROFILE.TEXT_8' | translate }} <span class="has-text-weight-bold">{{user.date_create | date : "dd/MM/yyyy"}}</span></p>
            </div>
          </div>
          <div class="pt-1">
            <div class="is-flex is-align-items-center">
              <input style="pointer-events: none;" type="checkbox" name="answer" class="mr-2" [formControl] = "notificationFormControl" >
              <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'PROFILE.TEXT_6' | translate }}</span>{{ 'PROFILE.TEXT_4' | translate }}
                <span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'PROFILE.TEXT_7' | translate }}</span>{{ 'PROFILE.TEXT_8' | translate }} <span class="has-text-weight-bold">{{user.date_create | date : "dd/MM/yyyy"}}</span></p>            
              </div>
          </div>
          <div class="pt-1">
            <div class="is-flex is-align-items-center">
              <input type="checkbox" name="answer" class="mr-2" [formControl] = "commercialMsgFormControl">
              <p class="check-content">{{ 'PROFILE.TEXT_9' | translate }} <span class="has-text-weight-bold"> {{user.commercial_updated_at | date : "dd/MM/yyyy"}}</span></p>      
            </div>
          </div>
        </div>
      </div>
      <div class="columns m-0 pt-2 is-flex is-justify-content-center is-multiline">
        <div class="column is-6-tablet is-12-mobile is-flex is-justify-content-center pb-2 pt-0">
          <button id="btnSignUp" [disabled]="btnDisabled" [ngClass]="!btnDisabled ? 'is-success' : 'button-disabled'"
            class="column is-flex is-12 button is-success has-text-weight-semibold submit-btn" (click)="onclickUpdateProfile()">
            {{'PROFILE.UPDATE_INFORMATION' | translate}}
          </button>
        </div>
      
          
      </div>
      <div class="is-flex is-justify-content-center has-text-weight-semibold is-clickable" (click)="confirmDeletePopup = true">
        {{'PROFILE.CLOSE_ACCOUNT' | translate}}
      </div>
    </div>

  </div>
</div>
<div *ngIf="confirmDeletePopup" class="modal fade is-active">
  <div class="modal-card">
    <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
      <div>
        <div class="has-text-centered pb-4 has-text-weight-bold px-4">
          {{'PROFILE.CLOSE_ACCOUNT_CONFIRMATION_CONTENT' | translate}}
        </div>
        
        <div class="has-text-centered">
          <div class="button btn_cancel has-text-weight-semibold m-2" (click)="confirmDeletePopup = false">
            {{'DASHBOARD.NO' | translate}}
          </div>
          <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="deleteAccount()">
            {{'COMMON.YES' | translate}}
          </div>
        </div>
      </div>
      
    </div>
    </div>
  </div>
  <div *ngIf="deleteMsgPopup" class="modal fade is-active">
    <div class="modal-card close-account-modal">
      <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
        <div>
          <div class="has-text-centered pb-4 has-text-weight-bold px-4">
            {{'PROFILE.CLOSE_ACCOUNT_MESSAGE' | translate}}
          </div>
          
          <div class="has-text-centered">
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="goToDashboard()">
              {{'COMMON.OK' | translate}}
            </div>
          </div>
        </div>
        
      </div>
      </div>
    </div>
  

