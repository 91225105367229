import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'msgDate'
})
export class MsgDatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    return moment(value).format('DD-MMM-YYYY, h:mm A');
  }


}
