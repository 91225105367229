<div (click)="clickOutside()">
<app-user-header></app-user-header>
<div class="container-wrapper">
    <!-- <div class="columns m-0 sec-title-wrapper mt-3">
        <div class="column is-8" style="align-items: center;display: flex;">
            <button class="button back_button" >
              <span class="icon is-small is-left" (click)="onBackPressed()" style="height: 60px; color: #fff;">
                <i class="fas fa-arrow-left"></i>
              </span>
            </button>
            <span class="has-text-weight-bold ml-4 is-size-6-mobile	" style="font-size: 1.3rem;">
                {{name}}
            </span>
          </div>
    </div> -->
    <router-outlet></router-outlet>
    <!-- <div class="column mt-1 w-bg-with-border-radius is-justify-content-space-around inner-wrapper">
            <mat-tab-group [selectedIndex]=index>
                <mat-tab label="{{'PROFILE.ACCOUT_INFO' | translate}}">
        <app-account-info (updateProfileInfo)="updateProfileInfo()"></app-account-info>
                </mat-tab>
                <mat-tab label="Password">
                    <app-password-info></app-password-info>
                </mat-tab>
                <mat-tab label="Payment">
                    <app-payment></app-payment>
                </mat-tab>
                <mat-tab label="{{'PROFILE.VEHICLE' | translate}}">
                    <app-vehicle></app-vehicle>
                </mat-tab>
                <mat-tab label="{{'PROFILE.REVIEWS' | translate}}">
                    <app-reviews></app-reviews>
                </mat-tab>
                <mat-tab label="{{'DASHBOARD.SERVICE_HISTORY' | translate}}">
                    <app-service-history></app-service-history>
                </mat-tab>
            </mat-tab-group>
    </div> -->
</div>
</div>

