import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { map, Observable, startWith, takeWhile } from 'rxjs';
import { Utils } from 'src/app/common/utils';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Constants } from 'src/app/Constants/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-location-search',
  templateUrl: './new-location-search.component.html',
  styleUrls: ['./new-location-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewLocationSearchComponent implements OnInit {

  @Input() banner: boolean = false;
  pincodefil: any[] = []
  servicesList: any = []
  tempServiceList: any = []
  tempProvinceList: any = []
  provinceList: any = []
  servicesControl = new FormControl();
  servicesOptions: any = new Observable<string[]>();

  searchText: string = '';
  searchProvince: string = '';

  @ViewChild('search')
  searchAddress: ElementRef | undefined
  pincodeControl = new FormControl()
  pincodeOptions: any = new Observable<String[]>();

  //current location
  private geoCoder: any;
  latitude: number = 0.0;
  longitude: number = 0.0;
  pincode: string = '';
  address: string = '';
  selectedItem: any = {}
  selectedProvince: any;
  alive: boolean = true
  isFocusClick: boolean = false;
  outsideClick: boolean = false;
  isShowDropDown: boolean = false;
  isFocusOut: boolean = false
  isFocusClickProvince: boolean = false;
  outsideClickProvince: boolean = false;
  isShowDropDownProvince: boolean = false;
  isFocusOutProvince: boolean = false;
  clickCurrentLocation: boolean = false;
  constructor(private bookService: BookingServiceService,
    private passDataService: PassDataService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private translate: TranslateService,
    private route: Router,
    private utils: Utils,
    private sharedServices: SharedServiceService) { }

  ngOnInit(): void {


    this.initMap()
    this.fetchServicesAndZipCode();
    this.hideDropDown()
    this.hideProvinceDropDown();


    this.passDataService.getOutsideClickListener().pipe(takeWhile(() => this.alive)).subscribe(() => {
      if (this.isShowDropDown && !this.isFocusClick && this.isFocusOut) {
        this.hideDropDown()
        this.isShowDropDown = false
        this.isFocusClick = false
      }
    })

    this.passDataService.getOutsideClickListener().pipe(takeWhile(() => this.alive)).subscribe(() => {
      if (this.isShowDropDownProvince && !this.isFocusClickProvince && this.isFocusOutProvince) {

        this.hideProvinceDropDown()
        this.isShowDropDownProvince = false
        this.isFocusClickProvince = false
      }
    })
  }
  onFocus(): void {
    this.isFocusOut = false;
    this.isFocusClick = true
    this.isShowDropDown = false
    // console.log(this.isShowDropDown);
    if (!this.isShowDropDown) {
      this.showDropDown()
    }
  }
  onFocusOut() {
    this.isFocusOut = true
  }


  onFocusProvince(): void {
    this.isFocusOutProvince = false;
    this.isFocusClickProvince = true
    this.isShowDropDownProvince = false
    // console.log(this.isShowDropDownProvince);
    // if(!this.isShowDropDownProvince){
    //   this.showProvinceDropDown()
    // }
  }
  onFocusOutProvince() {
    this.isFocusOutProvince = true
  }

  // focusOutFunction(): void{
  //  this.passDataService.setOutsideClickListener();
  // }

  hideDropDown() {
    // document.getElementById('txtPopular')?.classList.add('display_drop_down');
    document.getElementById('btnServices')?.classList.add('display_drop_down');
    document.getElementById('btnAllServices')?.classList.add('display_drop_down');
    document.getElementById('dropDown')?.classList.add('show-dropdown')

  }

  hideProvinceDropDown() {
    // document.getElementById('txtPopular')?.classList.add('display_drop_down');
    document.getElementById('btnProvince')?.classList.add('display_drop_down_province');
    document.getElementById('dropDownProvince')?.classList.add('show-dropdown-province')

  }

  showDropDown() {
    // document.getElementById('txtPopular')?.classList.remove('display_drop_down');
    document.getElementById('btnServices')?.classList.remove('display_drop_down');
    document.getElementById('dropDown')?.classList.remove('show-dropdown')
    this.isShowDropDown = true
    setTimeout(() => {
      this.isFocusClick = false
    }, 500)
  }

  showProvinceDropDown() {
    document.getElementById('btnProvince')?.classList.remove('display_drop_down_province');
    document.getElementById('dropDownProvince')?.classList.remove('show-dropdown-province')
    this.isShowDropDownProvince = true
    setTimeout(() => {
      this.isFocusClickProvince = false
    }, 500)
  }

  onClickServices(item: any) {
    this.selectedItem = item
    this.servicesControl.setValue(item.name)
    this.isShowDropDown = true
    this.outsideClick = false
    this.isFocusClick = false
    this.hideDropDown()
  }

  onClickProvince(item: any) {
    this.selectedProvince = item.place + ", " + item.province;
    this.pincodeControl.setValue(this.selectedProvince);
    // console.log(this.pincodeControl.value);
    // console.log(item.place+","+item.province+","+item.state);
    this.isShowDropDownProvince = true
    this.outsideClickProvince = false
    this.isFocusClickProvince = false
    localStorage.setItem("address", this.selectedProvince);
    this.hideProvinceDropDown()
  }

  onInputServiceChange(value: any) {
    value = value == null ? '' : value
    if (typeof value === 'object' && value != undefined) {
      value = Object.keys(value).length != 0 ? value.name : '';
    }
    let tempServiceListWithStart = this.servicesList.filter((option: any) => option.name.toLowerCase().startsWith(value?.trim().toLowerCase()));
    let tempServiceListIncludes = this.servicesList.filter((option: any) =>{
      if(option.name.toLowerCase().includes(value?.trim().toLowerCase())){
        if(tempServiceListWithStart.some((item: { name: string; }) => item.name.toLowerCase() === option.name.toLowerCase())){
          return false
        }
        return true
      }
      return false
    });
    this.tempServiceList = [...tempServiceListWithStart, ...tempServiceListIncludes]
    if (this.tempServiceList.length == 0) {
      this.hideDropDown()
      this.tempServiceList = this.servicesList
    } else {
      this.isShowDropDown = true
      this.showDropDown()
    }
  }

  onInputProvinceChange(value: any) {
    localStorage.removeItem("address");
    this.pincodefil = this.provinceList
    value = value == null ? '' : value
    if (value == '') {
      this.clickCurrentLocation = false
      this.hideProvinceDropDown()
    } else {
      if (typeof value === 'object' && value != undefined) {
        value = Object.keys(value).length != 0 ? value.place : '';
      }
      let placeFilter = this.pincodefil.filter((option: any) => {
        const searchTerm = value != undefined ? value.trim().toLowerCase() : '';
        const placeLowerCase = option.place.toLowerCase();
        if (placeLowerCase.startsWith(searchTerm)) {
          return true;
        }
        return false;
      });
      let provinceFilter = this.pincodefil.filter((option: any) => {
        const searchTerm = value != undefined ? value.trim().toLowerCase() : '';
        const provinceLowerCase = option.province.toLowerCase();
        if (provinceLowerCase.startsWith(searchTerm)) {
          if(placeFilter.some((item) => item.place.toLowerCase() === option.place.toLowerCase())){
            return false
          }
          return true;
        }
        return false;
      });
      this.tempProvinceList = [...placeFilter, ...provinceFilter]
      this.pincodefil = this.tempProvinceList
      if (this.tempProvinceList.length == 0) {
        this.hideProvinceDropDown()
      } else {
        if (!this.clickCurrentLocation) {
          this.showProvinceDropDown()
        }
      }
    }
  }
  formatService(input: string): string {
    if (!this.searchText || !input) {
      return input;
    }
    const regEx = new RegExp(this.searchText, 'gi');
    return input.replace(regEx, '<strong>$&</strong>');
  }
  formatProvince(input: string): string {
    if (!this.searchProvince || !input) {
      return input;
    }
    const regEx = new RegExp(this.searchProvince, 'gi');
    return input.replace(regEx, '<strong>$&</strong>');
  }
  onAddressFocus() {
    this.hideDropDown()
  }

  //fetch services and zipcode api
  fetchServicesAndZipCode() {
    this.bookService.getServicesAndPincode().subscribe({
      next: (res) => {
        if (res.statusCode == 1) {
          this.servicesList = res.service_list;
          this.tempServiceList = this.servicesList;
          this.pincodefil = res.zipcode
          this.provinceList = res.zipcode
        }
      },
      error: (err) => {
        //  console.log(err)
      }
    })
  }


  displaypincode(value: any) {
    return value ? value.place ? value.place + "," + value.province + "," + value.state : value : "";
  }


  goToServices() {
    if (localStorage.getItem("address") == undefined || localStorage.getItem("address") == '') {
      this.route.navigate(['/location'])
    } else {
      this.route.navigate(['/service'])
    }
  }

  onClickGo() {
    // if (this.servicesControl.value) 
      if (this.selectedItem.name != undefined && this.selectedItem.name != '' && this.selectedItem.name != null){
      let data = {
        service_id: this.selectedItem.id
      }
      this.bookService.getServiceList(data).subscribe({
        next: (res) => {
          this.sharedServices.setEndServiceData(res.data[0])
          this.pincode = localStorage.getItem('address') || '';
          if (this.pincode.length == 0 || this.pincode.includes("undefined")) {
            this.route.navigate(['/location'])
          } else if ((this.servicesControl.value !== null || this.servicesControl.value !== undefined || this.servicesControl.value !== '')) {
            // this.sharedServices.setPopularServiceData(this.servicesControl.value) 
            this.route.navigate(['/service'])
          } else {
            this.utils.openErrorSnackBar(this.translate.instant('COMMON.PLEASE_SELECT_LOCATION'))
          }

        },
        error: (err) => {
          //  console.log(err)
        }
      })
    } else {
      this.pincode = localStorage.getItem('address') || '';
      if (this.pincode.length == 0 || this.pincode.includes("undefined")) {
        this.route.navigate(['/location'])
      } else if ((this.servicesControl.value !== null || this.servicesControl.value !== undefined || this.servicesControl.value !== '')) {
        // this.sharedServices.setPopularServiceData(this.servicesControl.value) 
        this.route.navigate(['/service'])
      } else {
        this.utils.openErrorSnackBar(this.translate.instant('COMMON.PLEASE_SELECT_LOCATION'))
      }
    }
    // console.log(this.pincode)
    // if(this.pincode.length == 0  && (this.servicesControl.value === null || this.servicesControl.value === undefined || this.servicesControl.value === '')){
    //   this.utils.openErrorSnackBar('Lütfen bir yer seçin')
    //   this.setAttribute('pincodeDiv','Lütfen bir yer seçin')
    //   return
    // }

    // return
  }

  initMap() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      var southWest = new google.maps.LatLng(40.811404, 28.595554);
      var northEast = new google.maps.LatLng(41.199239, 29.428805);
      var istanbulBounds = new google.maps.LatLngBounds(southWest, northEast);
      // var bangaloreBounds = new google.maps.LatLngBounds(
      //   new google.maps.LatLng(41.671, 29.9588),
      //   new google.maps.LatLng(40.7377, 27.9708));
      var options = {
        strictBounds: true,
        bounds: istanbulBounds,
        componentRestrictions: { country: "TR" },
        types: ['administrative_area_level_4']
      };
      let autoComplete = new google.maps.places.Autocomplete(this.searchAddress?.nativeElement, options);
      // console.log(autoComplete);
      autoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autoComplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          if (place.address_components != undefined) {
            let addressDetails = place.address_components;
            // console.log(addressDetails);
            if (addressDetails.length > 0) {
              // console.log(addressDetails)
              addressDetails.forEach(element => {
                if (element.types[0].endsWith('postal_code')) {
                  this.pincode = element.long_name;
                }
                if (element.types[0].endsWith('administrative_area_level_4')) {
                  this.address = element.long_name
                }
                if (element.types[0].endsWith('administrative_area_level_2')) {
                  this.address = this.address + " " + element.long_name
                }
                if (element.types[0].endsWith('administrative_area_level_1')) {
                  this.address = this.address + ' - ' + element.long_name
                }
                localStorage.setItem("address", this.address);
              });
            }
          }
        })
      });
    })
  }
  setCurrentLocation() {
    this.clickCurrentLocation = true
    this.hideProvinceDropDown();
    let _this = this;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        _this.latitude = position.coords.latitude;
        _this.longitude = position.coords.longitude;
        _this.getAddress(position.coords.latitude, position.coords.longitude)
      }, function (err) {
        alert("Location not enabled");
      }, { enableHighAccuracy: true, timeout: 5000 });
    } else {
      alert("Location not enabled");
    }
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.pincodeControl.setValue([results[0].formatted_address])
          if (results[0].address_components != undefined) {
            let addressDetails = results[0].address_components;
            if (addressDetails.length > 0) {
              addressDetails.forEach((element: any) => {
                if (element.types[0].endsWith('postal_code')) {
                  this.pincode = element.long_name;
                }
                if (element.types[0].endsWith('street_number')) {
                  this.address = element.long_name
                }
                if (element.types[0].endsWith('route')) {
                  this.address = this.address + "," + element.long_name
                }
                if (element.types[0].endsWith('sublocality_level_2')) {
                  this.address = this.address + "," + element.long_name
                }
                if (element.types[0].endsWith('political')) {
                  this.address = this.address + "," + element.long_name
                }
                if (element.types[0].endsWith('locality')) {
                  this.address = this.address + "," + element.long_name
                }
                if (element.types[0].endsWith('administrative_area_level_1')) {
                  this.address = this.address + ' - ' + element.long_name
                }
              });
            }
          }
          // localStorage.setItem("zipcode",this.pincode);
          localStorage.setItem("address", this.address);
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  clearService(event: any){
    event.stopPropagation()
    this.searchText = ''
    this.selectedItem = {}
    this.servicesControl.setValue('')
  }
  clearProvince(event: any){
    event.stopPropagation();
    this.searchProvince = ''
    localStorage.removeItem('address')
      
  }
  setAttribute(id: string, content: string) {
    document.getElementById(id)?.setAttribute('data-tip', content)
  }

  removeAttribute(id: string) {
    document.getElementById(id)?.removeAttribute('data-tip')
  }
}
