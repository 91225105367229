<style>
  a.disabled {
    pointer-events: none;
    cursor: default;
  }
</style>
<div>
  <!-- <figure class="image is-128x128">
      <img src="assets/img/arabama.png">
    </figure> -->
  <app-signup-header></app-signup-header>

  <div class="login-card">
    <div class="has-text-weight-bold signin-title mb-4">
      {{ "COMMON.SIGN_IN" | translate }}
    </div>
    <div class="control mb-4">
      <input id="email" class="input check-text" [formControl]="userNameFormcontrol" type="email" (ngModelChange)="onInputChange($event)" (focus)="onFocus()" (blur)="onBlur()" 
        [ngModel]="inputText" pattern="^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$" [matAutocomplete]="auto"  [readOnly]="isOtpSend" autofocus required/>
        <label [ngClass]="userNameFormcontrol.invalid && userNameFormcontrol.dirty? 'floating-label-new' : 'floating-label'" [class.active]="isEmailActive" for="email">{{ 'COMMON.EMAIL' | translate }}</label>
        <p class="help is-danger" *ngIf="userNameFormcontrol.invalid && userNameFormcontrol.dirty">
          <!-- {{(userNameFormcontrol.errors?.['required']) ? 'ERROR_MESSAGE.error_valid_email' | translate : ''}}
          {{(userNameFormcontrol.errors?.['pattern']) ? ' ERROR_MESSAGE.error_valid_email' | translate : ''}} -->
          {{ 'ERROR_MESSAGE.error_valid_email' | translate }}
        </p>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of tempServiceList | filter : inputText" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div *ngIf="!isOtpSend" class="columns is-mobile mb-0">
      <div class="column is-8 is-flex is-align-items-start">
        <div>
          <input type="checkbox" class="mt-1" [(ngModel)]="isChecked"
            (change)="checkValue(isChecked ? 'checked' : 'unChecked')" />
        </div>
        <div class="is-flex is-align-items-center">
          <p class="pl-2 check-text">{{ "HOME.TEXT_72" | translate }}</p>
        </div>
      </div>
      <div class="column is-4 is-flex is-justify-content-right">
        <span class="check-text help-link has-text-weight-semibold" routerLink="/help" style="cursor: pointer">{{ "DASHBOARD.HELP" | translate }}</span>
      </div>
    </div>

    <!-- <div class="control has-icons-right">
                <input id="inputPass" [formControl]="passwordFormControl" class="input"
                       type="password" (ngModelChange)="onInputPasswordChange($event)" placeholder="{{ 'COMMON.PASSWORD' | translate}}">
                  <span class="icon is-small is-right" style="align-items: center;height: 100%;pointer-events: all;">
                    <i id="passEye" class="fas fa-eye-slash"></i>
                  </span>
              </div> -->
    <!-- <div class="is-pulled-right is-size-7 pb-3">
                <button class="button is-white" style="color: #666666;">{{ 'COMMON.FORGET_PASSWORD' | translate}}</button>
              </div> -->

    <div *ngIf="!isOtpSend" class="control pt-2">
      <button id="btnLogin" [disabled]="!btnDisabled" [ngClass]="btnDisabled ? 'is-success' : 'button-disabled'"
        class="button button-cta is-fullwidth submit-btn" (click)="sendOtp('')">
        {{ "COMMON.REQUEST_OTP" | translate }}
      </button>
    </div>
    <p *ngIf="isOtpSend" class="otp-text">{{"COMMON.PLEASE_ENTER_OTP" | translate}}</p>
    <div *ngIf="isOtpSend">
      <div class="columns is-one-quarter is-mobile mt-2 mx-2 otp-wrapper is-flex-direction-column">
        <div class="column is-12" *ngIf="expire !== '00:00'">
          <input #otp1 id="otp1" inputmode="numeric" (keydown.backspace)="backspace($event, 1)" (keypress)="numberOnly($event)"
            [(ngModel)]="otpDetails.otp1" class="otp-bg mx-3" type="text" (keyup)="tabChange(1)" maxlength="1"
            autocomplete="off" (input)="verifyOTP()"/>
          <input #otp2 id="otp2" inputmode="numeric" (keydown.backspace)="backspace($event, 2)" (keypress)="numberOnly($event)"
            [(ngModel)]="otpDetails.otp2" class="otp-bg mx-3" type="text" (keyup)="tabChange(2)" maxlength="1"
            autocomplete="off" (input)="verifyOTP()"/>
          <input #otp3 id="otp3" inputmode="numeric" (keydown.backspace)="backspace($event, 3)" (keypress)="numberOnly($event)"
            [(ngModel)]="otpDetails.otp3" class="otp-bg mx-3" type="text" (keyup)="tabChange(3)" maxlength="1"
            autocomplete="off" (input)="verifyOTP()"/>
          <input #otp4 id="otp4" inputmode="numeric" (keydown.backspace)="backspace($event, 4)" (keypress)="numberOnly($event)"
            [(ngModel)]="otpDetails.otp4" class="otp-bg mx-3" type="text" (keyup)="tabChange(4)" maxlength="1"
            autocomplete="off" (input)="verifyOTP()"/>
        </div>
      </div>
      <div class="control p-2 has-text-centered">
        <div class="has-text-weight-bold" *ngIf="expire !== '00:00'">
          {{ expire }}
        </div>
        <!-- <a class="has-text-weight-bold" (click)="sendOtp('Resend')" *ngIf="expire === '00:00'">Resend Otp</a> -->
        <!-- <a class="pt-2" *ngIf="expire !== '00:00'" style="color: grey; cursor: default;">Resend Otp</a> -->
        <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
      </div>
      <div class="control p-2">
        <button *ngIf="expire === '00:00'" id="btnVerify" (click)="expire !== '00:00' ? verifyOTP() : sendOtp('Resend')"
          style="color: white; font-weight: bold; border-radius: 12px" class="button is-success is-fullwidth is-size-6 is-clickable">
          <p class="submit-btn" *ngIf="expire !== '00:00'">
            {{ "COMMON.VERIFY_OTP" | translate }}
          </p>
          <p class="submit-btn" *ngIf="expire === '00:00'">
            {{ "COMMON.RESEND_OTP" | translate }}
          </p>
        </button>
      </div>
      <!-- <div class="control p-2">
            <button id="btnVerify" (click)="sendOtp('Resend')" *ngIf="expire === '00:00'"style="color: white;font-weight: bold;border-radius: 12px;"
              class="button is-success is-fullwidth">Resend Otp</button>
          </div> -->
    </div>
    <div *ngIf="!isOtpSend && !isFromEstimate && !isFromVehicle" class="has-text-centered pt-3">
      <span class="route-link has-text-weight-bold">
        {{ "COMMON.SIGNUP_WITH" | translate }} <span routerLink="/signup" class="signup-route">{{ "COMMON.SIGNUP_ROUTE" | translate }}</span>
      </span>
    </div>
    <!-- <div class="columns px-5 reverse-row-order">
                  <div class="column">
                    <a class="facebook_login" href="">
                      <div class="icon"><img src="assets/img/facebook.png"></div>
                       <div class="title">Sign in with Facebook</div>
                      </a>
                  </div>
                  <div class="column">
                    <a class="google_login" href="">
                      <div class="icon"><img src="assets/svg/google.svg"></div>
                      <div class="title">Sign in with Google</div>
                    </a>
                  </div>
              </div>    -->
    <div class="column has-text-centered is-hidden">
      <div class="column">
        <span class="icon-text">
          <button class="button social-btn is-white is-hidden" style="display: flex" (click)="loginWithFaceBook()">
            <span class="icon has-text-link mr-2">
              <!-- <i class="fab fa-facebook"></i> -->
              <img src="assets/svg/facebook.svg" />
            </span>
            <span class="is-size-7">{{ "COMMON.FACEBOOK" | translate }}</span>
          </button>
          <button class="button social-btn is-white is-hidden" style="display: flex" (click)="loginWithGmail()">
            <span class="icon has-text-danger ml-2 is-hidden">
              <!-- <i class="fab fa-google-plus-g"></i> -->
              <img src="assets/img/gmail.png" />
            </span>
            <span class="is-size-7 is-hidden">{{
              "COMMON.E_MAIL_TEXT" | translate
              }}</span>
          </button>
          <span class="icon ml-2 is-hidden">
            <img src="assets/img/cellphone.png" />
          </span>
          <span class="is-size-7 is-hidden">{{
            "COMMON.PHONE_TEXT" | translate
            }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
