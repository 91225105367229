import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-step-services-rotors-three',
  templateUrl: './step-services-rotors-three.component.html',
  styleUrls: ['./step-services-rotors-three.component.scss']
})
export class StepServicesRotorsThreeComponent implements OnInit {

  @Output() nextStep:EventEmitter<any>= new EventEmitter();
  @Output() previousStep:EventEmitter<any>= new EventEmitter();
  
  constructor(private sharedService: SharedServiceService,
    private route: Router, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  movePreviousStep(){
    this.previousStep.emit();
  }

  nextStepper() {
    this.sharedService.updateData('2');
    this.route.navigate(['/service/vehicle'])
  }

}
