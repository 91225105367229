import { Component, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { Utils } from 'src/app/common/utils';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewDetailsComponent implements OnInit {

  image: any = ''
  estimateItem: any ;
  quotation: any;
  amenitiesLength: number = 5;
  defaultAmenitiesCount: number = 5; 
  showQuote: boolean = false 
  constructor(public dialogRef: MatDialogRef<ViewDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private route: Router, private sharedService: SharedServiceService, private utils: Utils) { }

  ngOnInit(): void {
    if(this.utils.isMobile()){
      this.defaultAmenitiesCount = 2
      this.amenitiesLength = 2 
    }
    if(this.data != null){
      this.estimateItem = this.data.dataItem
      this.quotation = this.data.quotation
      if(this.data.dataItem.logo_url != null){
        this.image = this.data.dataItem.logo_url
      }else{
        this.image = 'assets/img/no-image.png';
      }
    }
  }
  nextStepper() {
    this.sharedService.updateData('4');
    // this.passDataService.updateEstimateData(item)
    sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_DETAILS,JSON.stringify(this.estimateItem));
    this.sharedService.setEstimateData(this.estimateItem)
    this.dialogRef.close()
    this.route.navigate(['/service/book'])
  }
  clickClose(){
    this.dialogRef.close()
    if(this.quotation){
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
              sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
              sessionStorage.removeItem(Constants.APP.QUOTE_ID)
              localStorage.removeItem(Constants.APP.SERVICE_LIST)
      this.route.navigate(['/dashboard'])

    }
  }

}
