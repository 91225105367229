import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
date: any;

constructor(public translate: TranslateService, private router: Router) { }

  ngOnInit(): void {
    this.date = moment(new Date()).format('YYYY');
  }
  openInNewTab(url: string) {
    // this.router.navigate(['/cookie-policy'],{queryParams : {policy : true} })
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/#/${url}`;  // Ensure the hash is correctly placed
    const newTab = window.open(fullUrl, '_blank');
    if (newTab) {
      newTab.opener = null;
    } else {
      console.error('Failed to open new tab');
    }
  }
}
