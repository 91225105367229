<div>
    <nav class="navbar" role="navigation" aria-label="main navigation" style="min-height: 48px;">
      <div
        class="navbar-brand is-pa-l-10" 
      >
        <img
          routerLink="/home"
          class="is-clickable"
          src="assets/img/arabama.png"
          style="display: block"/>
</div>
          <div class="navbar-menu pr-6">
            <div  class="navbar-end" style="margin-left: auto;">
            <div class="navbar-item">
                <a routerLink="/cookie-policy" class="navbar-item">
                  <span class="has-text-weight-bold">Cookies Policy</span>
                </a>
              </div>
              <div class="navbar-item">
                <a routerLink="/privacy-policy" class="navbar-item">
                  <span class="has-text-weight-bold color-arabama-green">Privacy Policy</span>
                </a>
              </div>
              <div class="navbar-item">
                <a routerLink="/terms-conditions" class="navbar-item">
                  <span class="has-text-weight-bold">Terms & Conditions</span>
                </a>
              </div>
          </div>
          </div>
        </nav>
</div>
<div class="content">
    <h1>Privacy Policy</h1>
    <div class="is-flex is-justify-content-center is-align-items-center " style="height: 50vh">
        <h3 style="color: #aad043;">Coming soon...</h3>
    </div>
</div>

