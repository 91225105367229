import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';
import { SideNotificationComponent } from '../side-notification/side-notification.component';

@Component({
  selector: 'app-signup-header',
  templateUrl: './signup-header.component.html',
  styleUrls: ['./signup-header.component.scss']
})
export class SignupHeaderComponent implements OnInit {

  userData: any;
  constructor(private router: Router, private sharedServices: SharedServiceService, private socketService: SocketService) { }
  @ViewChild(SideNotificationComponent) sideNotificationComponent: SideNotificationComponent | undefined;

  showBurgerMenu: boolean = false;
  show_notification: boolean = false;
  notification_count: number = 0;
  nameHover: boolean = false

  ngOnInit(): void {
    if (localStorage.getItem(Constants.APP.SESSION_USER_DATA)) {
      this.userData = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA) || '')
    }
  }

  serviceHistory() {
    this.router.navigate(['/profile/service-history'])
  }

  reviews() {
    this.router.navigate(['/profile/reviews'])
  }
  clickProfile(){
    // this.passServices.pageIndex = "profile"
    this.router.navigate(['/profile'])
  }
  homePage(){
      this.router.navigate(['/dashboard'])
  }

  carParking(){
    // this.passServices.pageIndex = "car_parking";
    this.router.navigate(['/profile/car-park'])
  }
  goToServices(){
    // if(localStorage.getItem("address") == undefined || localStorage.getItem("address") == ''){
    //   this.router.navigate(['/location'])
    // }else{
    //   this.router.navigate(['/service'])
    // }
    const fullUrl = `https://garagedev.nisissolutions.com/`; 
    const newTab = window.open(fullUrl, '_blank');
    if (newTab) {
      newTab.opener = null;
    } else {
      console.error('Failed to open new tab');
    }
  }

  logout() {
    localStorage.removeItem(Constants.APP.SERVICE_LIST)
    localStorage.removeItem("address")
    localStorage.removeItem("zipcode")
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
    localStorage.removeItem(Constants.APP.LOCATION_ARRAY)
    localStorage.removeItem(Constants.APP.SESSION_ID)
    localStorage.removeItem(Constants.APP.SESSION_USER_DATA)
    sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
    sessionStorage.removeItem(Constants.APP.QUOTE_ID)
    localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
    this.sharedServices.clearData()
    document.body.classList.remove('inner-stepper');
    this.router.navigate(['/home'])
    this.socketService.disconnect()
  }
  notification(type: string){
    this.show_notification = true
  }
  hideNotification(ev: boolean){    
    this.show_notification = ev;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const ClickedElement = event.target as HTMLElement
    const iconContainer = ClickedElement.closest('.icon-container');
    const notificationInside = ClickedElement.closest('.notify-content');
    if (!iconContainer && !notificationInside) {
      this.show_notification =  false;
    } 
  }
}
