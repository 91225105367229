import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Utils } from 'src/app/common/utils';
import { VehcileDeleteConfirmDialogComponent } from 'src/app/dialog/vehcile-delete-confirm-dialog/vehcile-delete-confirm-dialog.component';
import { VehcileEditConfirmDialogComponent } from 'src/app/dialog/vehcile-edit-confirm-dialog/vehcile-edit-confirm-dialog.component';

@Component({
  selector: 'app-existing-vehcile-line',
  templateUrl: './existing-vehcile-line.component.html',
  styleUrls: ['./existing-vehcile-line.component.scss']
})
export class ExistingVehcileLineComponent implements OnInit {

  @Input() vehcileItem:any;
  vehcileData: any;
  isDropDownOpen: boolean = false;
  estimateDate: any = [];
  completedData: any = []
  @Output() fetchVehcileEmit = new EventEmitter<string>();

  constructor(public dialog: MatDialog,
              public utils: Utils,
              public Router:Router) { }

  ngOnInit(): void {
    this.vehcileData = this.vehcileItem;
    this.vehcileData.date = moment(this.vehcileData.date).utc().format('YYYY-MM-DD');
    if(this.vehcileItem != undefined){
      this.vehcileData.img = this.vehcileItem.logo
      this.vehcileData.vehicleFullName = this.vehcileItem.start_production_year +' '+this.vehcileItem.make_name + ' '+this.vehcileItem.model_name + '-'+"("+this.vehcileItem.trim_name +")"
      if(this.vehcileItem.mileage == undefined || this.vehcileItem.mileage == null || this.vehcileItem.mileage == ''){
        this.vehcileData.mileage = '-'
      }
      if(this.vehcileItem.VIN == undefined || this.vehcileItem.VIN == null || this.vehcileItem.VIN == ''){
        this.vehcileData.VIN = '-'
      }
      if(this.vehcileItem.plate == undefined || this.vehcileItem.plate == null || this.vehcileItem.plate == ''){
        this.vehcileData.plate = '-'
      }
    }else{
      this.vehcileData.img = ''
    }
    this.initData()
  }


  initData(){
    if(this.vehcileData.quotes){
      this.estimateDate = []
      this.completedData = []
      this.vehcileData.quotes.forEach((element: any) => {
        if(element.status === 'estimate'){
          element.name = element.items[0].name
          element.quote_id = element.items[0].quote_id
          if(element.items.length > 1){
            element.name = element.name + ' (1+Daha Fazla)'
          }
          let days = this.daysRemaining(element.date_update)
          if(days == 0){
            element.daysRemaining = 'Süresi doldu'
          }else{
            element.daysRemaining = days + ' fgün içinde sona eriyor'
          }
          this.estimateDate.push(element)
        }else if(element.status === 'booked'){
          element.name = element.items[0].name
          element.quote_id = element.items[0].quote_id
          if(element.items.length > 1){
            element.name = element.name + ' (1+Daha Fazla)'
          }
          let days = this.daysRemaining(element.date_update)
          if(days == 0){
            element.daysRemaining = 'Süresi doldu'
          }else{
            element.daysRemaining = days + ' gün içinde sona eriyor'
          }
          this.completedData.push(element)
        }
      });
      // console.log(this.completedData)
    }
  }

  dropDownClick(){
    document.getElementById(this.vehcileData.vechicle_id)?.classList.add('drop-down-action')
    this.isDropDownOpen = true
  }
  dropDownRemoveClick(){
    document.getElementById(this.vehcileData.vechicle_id)?.classList.remove('drop-down-action')
  }
  clickDelete(){
    // this.dropDownRemoveClick()
    this.openViewDirection()
    this.isDropDownOpen = false
  }

  clickEdit(){
    // this.dropDownRemoveClick()
    this.openViewDirection1()
    this.isDropDownOpen = false
  }

  openViewDirection(){
    let width = '550px'
    let heigth = '230px'
    let maxWidth = '550px'
    if(this.utils.isMobile()){
      width= '90%'
      heigth = ''
      maxWidth = '100vw'
    }
    const viewDialogConfirm = this.dialog.open(VehcileDeleteConfirmDialogComponent,{
      height: heigth,
      width: width,
      maxWidth: maxWidth,
      panelClass: ['directionCustom'],
      data:{
        dataItem: this.vehcileData
      }
    })
    viewDialogConfirm.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      if(result == 1){
        this.fetchVehcileEmit.emit()
      }
    });
  }

  openViewDirection1(){
    let width = '50%'
    // let heigth = '95%'
    let maxWidth = '80vw'
    if(this.utils.isMobile()){
      width= '100%'
      // heigth = '100%'
      maxWidth = '100vw'
    }
    const viewDialogConfirm = this.dialog.open(VehcileEditConfirmDialogComponent,{
      // height: heigth,
      width: width,
      maxWidth: maxWidth,
      disableClose:true,
      panelClass: ['directionCustom'],
      data:{
        dataItem: this.vehcileData
      }
    })
    viewDialogConfirm.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
     
    
      // if(result.data != "cancel"){
        this.fetchVehcileEmit.emit()
        
      // }
    });
  }

  viewDetails(quote:any){
    this.Router.navigate(['/service/estimates'],{state:{ estimateViewItem: quote}})
  }

  daysRemaining(date:any) {
    var eventdate = moment(date).add(40,'days');
    var todaysdate = moment();
    return eventdate.diff(todaysdate, 'days');
  }
}
