<div class="global-search input-group has-icon is-flex">
  <div class="global-search__group">
    <div class="global-search__search" style="padding-left: 10px">
      <span class="icon search-icon-home is-flex is-align-items-center" style="width: 24px;">
        <img class="" src="../../../assets/svg/search-icon.svg" />
      </span>
      <input type="search" on-focusout="onFocusOut()" placeholder="{{ 'HOME.TEXT_6' | translate }}"
        [formControl]="servicesControl" [(ngModel)]="searchText" (ngModelChange)="onInputServiceChange($event)"
        class="form-control custom-input-new" style="width: 100%;"/>
        <span class="mt-1 mr-1 is-clickable" *ngIf="searchText" (click)="clearService($event)"><img class="close-icon" src="../../../assets/img/ham-close.png" alt=""></span>
    </div>
    <div class="global-search__zip" style="padding-right: 10px !important">
      <div>
        <span class="icon is-small location-icon-home is-flex is-align-items-center" style="color: #aad043;">
          <img src="assets/img/location.png"/>
        </span>
        <!-- <span (click)="setCurrentLocation()" class="icon current-location">
          <img src="assets/svg/current_location.svg"/>
        </span> -->
      </div>
      <input type="search" on-focus="onFocusProvince()" on-focusout="onFocusOutProvince()"
        placeholder="{{ 'HOME.TEXT_52' | translate }}" class="form-control custom-input-new"
        [formControl]="pincodeControl" [(ngModel)]="searchProvince" (ngModelChange)="onInputProvinceChange($event)" />
        <div class="mr-1 is-flex is-align-items-center">
          <img *ngIf="searchProvince" (click)="clearProvince($event)" class="is-clickable close-icon" src="../../../assets/img/ham-close.png"/>
        </div>
        <div class="is-flex is-align-items-center">
          <img  (click)="setCurrentLocation()" class="is-clickable current-icon" src="assets/svg/current_location.svg"/>
        </div>
    </div>
    <div class="search-submit">
      <button (click)="onClickGo()" class="button is-success has-text-weight-bold">
        {{ "COMMON.FIND" | translate }}
      </button>
    </div>
  </div>
</div>
<div id="dropDown" class="global-search-menu rounded-bottom shadow show-dropdown" style="position: relative">
  <div class="search-results list-group list-group-flush search-dropdown" [ngClass]="banner? 'banner' : ''" style="position: absolute; height: 150px;">
    <div id="btnServices" class="display_drop_down" style="overflow-y: auto">
      <div *ngFor="let item of tempServiceList">
        <button class="list-group-item list-group-item-action" (click)="onClickServices(item)">
          <span [innerHTML]="formatService(item.name)"></span>
        </button>
      </div>
    </div>
    <button id="btnAllServices" (click)="goToServices()" class="list-group-item display_drop_down allService">
      {{ "SERVICE.TEXT_32" | translate }}
      <span class="icon is-small" style="color: #aad043">
        <i class="fas fa-arrow-right"></i>
      </span>
    </button>
  </div>
</div>
<div id="dropDownProvince" class="global-search-menu rounded-bottom shadow show-dropdown-province"
  style="position: relative">
  <div class="search-results list-group list-group-flush search-dropdown" [ngClass]="banner? 'banner' : ''" style="position: absolute; height: 150px;">
    <div id="btnProvince" class="display_drop_down_province" style="overflow-y: auto">
      <div *ngFor="let item of pincodefil">
        <button class="list-group-item list-group-item-action" (click)="onClickProvince(item)">
          <span [innerHTML]="formatProvince(item.place)"></span>, <span [innerHTML]="formatProvince(item.province)"></span>
        </button>
      </div>
    </div>
  </div>
</div>
