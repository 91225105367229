import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Constants } from 'src/app/Constants/constants';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { CommonAlertDialogComponent } from 'src/app/dialog/common-alert-dialog/common-alert-dialog.component';
import { Utils } from 'src/app/common/utils';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-popular-services-right-nav-list',
  templateUrl: './popular-services-right-nav-list.component.html',
  styleUrls: ['./popular-services-right-nav-list.component.scss']
})
export class PopularServicesRightNavListComponent implements OnInit {
  @Input() jobDetails = false;
  @Input() stepperCount = 1;
  listType = 'disc';
  servicesList : any;
  address: any = '';
  vechileDetails: any = [];
  vechileName: string = '';
  popularService: any[6] | undefined;
  isShowingScreen: boolean = false;
  estimationDetails:any = null;
  estimateShowing:boolean = false;
  estimateTimeShowing:boolean = false;
  trashShowingCtrl:boolean = true;
  showAddService: boolean = false;
  estimateTime: string = '';
  estimateTimeList: any = [];
  serviceCount: number = 0;
  answerList : any = []
  private destroy$: Subject<void> = new Subject<void>();


  constructor(private translate: TranslateService,
              private sharedService: SharedServiceService,
              private bookService: BookingServiceService,
              private route: Router,
              private passDataService: PassDataService,
              public dialog: MatDialog,
              private utils: Utils) { }

  ngOnInit(): void {
    this.address = localStorage.getItem("address");
    this.updateVechile();
    this.getLocalServiceList();
    this.sharedService.getAddress().subscribe((res)=>{
      this.address = res
    })
    this.sharedService.getUpdatedServiceList().subscribe(res=>{
      this.getLocalServiceList();
      this.updateVechile();
    })
    this.passDataService.getVehicleData().subscribe(data => { 
      this.updateVechile()
    }) 
    
    this.sharedService.getEstimateTimeData().subscribe((data:any)=>{
      this.setEstimateTimeData(data)
    })

    

    if(sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)){
      this.estimationDetails = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
      this.estimationDetails = JSON.parse(this.estimationDetails)
      this.estimateShowing = true
    }   
    if(sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_TIME)){
      this.setEstimateTimeData(sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_TIME))
    }
    this.sharedService.getEstimateData().subscribe((data:any)=>{
      if(data != null){
        this.estimateShowing = true
        this.estimationDetails = data
      }
    })

    this.sharedService.getData().subscribe((data : string) => {
      this.stepperCount = parseInt(data)
      this.estimateShowing = (parseInt(data) >= 3) ? true : false
      this.isShowingScreen = (parseInt(data) === 2 || 5 ) ? false : true 
      this.estimateTimeShowing = (parseInt(data) >= 4) ? true : false 
      this.trashShowingCtrl = (parseInt(data) === 1 || parseInt(data) === 2) ? true : false
      if(parseInt(data) == 2){
          this.estimationDetails = null
      }
      if(parseInt(data) == 3){
        this.estimateTimeList = []
      }
      if(parseInt(data) == 4){
        this.estimateTimeList = []
      }
    })

    this.sharedService.getSubData().subscribe((data:string)=>{
      this.isShowingScreen = (data) === '1.1'  ? false : true
    })
    this.sharedService.showAddService$.pipe(takeUntil(this.destroy$)).subscribe((data:any)=>{
      this.showAddService = data
    })
  }

  getLocalServiceList(){
    var services = localStorage.getItem(Constants.APP.SERVICE_LIST);
    if(services!="" && services!='null' && services!=undefined){
      this.servicesList = JSON.parse(services);
      this.servicesList = this.servicesList?.filter((item:any)=>item.answer.id)
      this.servicesList?.forEach((item: any) => {
        if(item.comment?.length > 50){
          item.showMore = true
        }else{
          item.showMore = false
        }
        item.open = false
        if(typeof item?.selected_answer == 'string')
        item.selected_answer = JSON.parse(item.selected_answer)
      })
     }
     if(this.servicesList?.length > 0){
      const res = this.servicesList?.filter((i:any) => !i.question?.fromMaster)
      this.servicesList = res
     }
     this.serviceCount = this.servicesList?.length
  }

  getServicesList(){
    this.bookService.getServiceList({}).subscribe(
      (res)=> {
        this.popularService = res.data.filter((item:any,index:any,arr:any)=>{
          return index === arr.findIndex((ele:any)=>ele.answer === item.answer)
        })

        this.popularService.forEach((element:any) => {
          element.icon = element.icon != '' ? element.icon : 'per-main1.svg'
          element.ans_icon ='assets/svg/'+element.icon;
        });
        this.popularService = this.popularService.slice( 0, 6)
      }
    )
  }
  updateVechile(){
    this.vechileDetails = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
    
    if(this.vechileDetails != undefined){
      this.vechileDetails = JSON.parse(this.vechileDetails)
      if(this.vechileDetails.model_year != undefined && this.vechileDetails.vechile_make_name != undefined && this.vechileDetails.vechile_model_name != undefined){
      this.vechileName = this.vechileDetails.model_year +' '+this.vechileDetails.vechile_make_name + ' '+this.vechileDetails.vechile_model_name
      }
      else{
        this.vechileName = this.vechileDetails.start_production_year +' '+this.vechileDetails.make_name+' '+this.vechileDetails.model_name
      }
    }else{
      this.vechileName = ''
    }
  }

  clickService(element:any){
    this.sharedService.setPopularServiceData(element)
    this.passDataService.updateViewAlldata('')
    // this.route.navigate(['/service'])
  }

  clickViewAll(){
    this.sharedService.clearPopularData()
    this.passDataService.updateViewAlldata('')
  }

  setEstimateTimeData(data: any){
    let estimateTime = JSON.parse(data)
    // estimateTime = estimateTime[0]
    this.estimateTimeList = []
    estimateTime.forEach((element: any) => {
      let dateFormat = moment(element.exact_date,'dd/MM/yyyy').locale('tr').format(' MMM ')
      element.estimateTime =dateFormat +" " + element.date +", " +element.day +" "+ element.selectedTime
      this.estimateTimeList.push(element)
    });
    if(this.estimateTimeList.length != 0){
      this.estimateTimeShowing = true;
    }
  }

  deleteService(item:any){
    let width = '450px'
    let heigth = '170px'
    let maxWidth = '450px'
    if(this.utils.isMobile()){
      width= '90%'
      heigth = ''
      maxWidth = '100vw'
    }
    const viewDialogConfirm = this.dialog.open(CommonAlertDialogComponent,{
      height: heigth,
      width: width,
      maxWidth:maxWidth,
      data:{
        dataItem: item  
      }
    })
    viewDialogConfirm.afterClosed().subscribe(result => {
      if(result == 1){
        var services = localStorage.getItem(Constants.APP.SERVICE_LIST);
        if(services!="" && services!='null' && services!=undefined){
          let serviceData = JSON.parse(services);
          const res = serviceData.filter((i:any) => i.answer.id != item.answer.id && i.question.id != item.question.id)
          if(res.length > 0){
            this.servicesList = res.filter((i:any) => !i.question.fromMaster)
           }
          // this.servicesList = res
          this.servicesList?.forEach((item: any) => {
            if(item.comment.length > 50){
              item.showMore = true
            }else{
              item.showMore = false
            }
            item.open = false
            if(typeof item?.selected_answer == 'string')
            item.selected_answer = JSON.parse(item.selected_answer)
          })

          localStorage.setItem(Constants.APP.SERVICE_LIST,JSON.stringify(res));
          this.serviceCount = res.length
          if((this.stepperCount == 2 && this.serviceCount === 0) || this.stepperCount == 1){
            this.passDataService.updateViewAlldata('');
            this.sharedService.clearData();
            this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.route.navigate(['service/services'])
            });
          }
        }
      }
    });
  }

  editService(data:any){
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate(['service/services'],{state : { editService : data }})
    });
  }
  addService(){
    this.sharedService.updateData('1');
    this.sharedService.setEndServiceData(null)
    this.sharedService.setProblemData(null)
    this.sharedService.setPopularServiceData(null)
    // this.passDataService.setBackPressed('1.1')
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate(['service/services'])
    });
    // this.route.navigate(['/service/services'])
  }

  deleteAppointment(data:any){
    this.estimateTimeList.pop()
    sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_TIME,JSON.stringify(this.estimateTimeList))
    this.sharedService.deleteAppointment()
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
