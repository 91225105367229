import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Utils } from 'src/app/common/utils';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
declare const gapi: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @ViewChild('otp1', { static: false }) otp1: any = ElementRef;
  @ViewChild('otp2', { static: false }) otp2: any = ElementRef;
  @ViewChild('otp3', { static: false }) otp3: any = ElementRef;
  @ViewChild('otp4', { static: false }) otp4: any = ElementRef;


expire!: any;
isOtpSend: boolean = false
userDetails: any = {}
userLogin: any = []
isFromEstimate: boolean = false
isFromEstimateBook: boolean = false
isFromVehicle: boolean = false
isChecked: any;
inputText: string = '';
isFromMessage : boolean = false;


isLoading: boolean = false;
firstNameFormControl = new FormControl();
lastNameFormControl = new FormControl();
emailIdFormControl = new FormControl();
phoFormControl = new FormControl();
pwdFormControl = new FormControl();
cityFormControl = new FormControl();
districtFormControl = new FormControl();
memberApprovalFormControl = new FormControl();
contactConfirmFormControl = new FormControl();
commercialMsgFormControl = new FormControl();
checkStatus : boolean = false
isSignInBtnEnabled: boolean = false
ph: any;

//   //validation
isFirstNameValid:boolean = true
isLastNameValid:boolean = true
isEmailValid:boolean = true
isCityValid:boolean = true
isDistictValid:boolean = true
isPhnValid:boolean = true
isPwdValid:boolean = true
btnDisabled:boolean = false
showMember_approval: boolean = false
showContact_confirm: boolean = false
showCommercial_msg: boolean = false
checkMember_approval : boolean = false
checkCommercial_msg : boolean = false

isFromSignupSaveEstimate:boolean =false;
isFirstNameActive:boolean = false;
isLastNameActive:boolean = false;
isEmailActive:boolean = false;
isPhoneActive:boolean = false;

optionCity: string [] = []
optionDistrict: string [] = []
cityList:any;
districtList:any;
disableDistrict: boolean = true


constructor(
  private Utils: Utils,
  private route: Router,
  private router : ActivatedRoute,
  private translate : TranslateService,
  private BookService:BookingServiceService) {
  this.otpDetails = {} as OTP
  // console.log(this.route.getCurrentNavigation()?.extras)
  if (this.route.getCurrentNavigation()?.extras.state != undefined) {
    let estimateVaue = this.route.getCurrentNavigation()?.extras.state;
    if (estimateVaue != undefined) {
      if (estimateVaue['isFromEstimateValue'] != undefined) {
        this.isFromEstimate = estimateVaue['isFromEstimateValue']
      } else if (estimateVaue['isFromEstimateBooked'] != undefined) {
        this.isFromEstimateBook = true
      }
    }
  }
}
ngOnInit(): void {
  document.body.classList.remove('inner-stepper');
  document.body.classList.add('sign-in');
  this.router.queryParams.subscribe(params => {
    this.isFromSignupSaveEstimate = params['isFromSignupSaveEstimate'];
  });
  this.fetchCity()
}

onFocus(field: string) {  
  if(field === 'firstname'){
    this.isFirstNameActive = true;
  }
  else if(field === 'lastname'){
    this.isLastNameActive = true;
  }
  else if(field === 'email'){
    this.isEmailActive = true;
  }
  else if(field === 'phone'){
    this.isPhoneActive = true;
  }
}

onBlur(field: string) {  
  if(field === 'firstname' && !this.firstNameFormControl.value){
    this.isFirstNameActive = false;
  }
  else if(field === 'lastname'&& !this.lastNameFormControl.value){
    this.isLastNameActive = false;
  }
  else if(field === 'email'&& !this.emailIdFormControl.value){
    this.isEmailActive = false;
  }
  else if(field === 'phone'&& !this.phoFormControl.value){
    this.isPhoneActive = false;
  }
}
  onInputPasswordChange(value:string){
    if(value.length == 0){
      this.isPwdValid = false
    }else{
      this.isPwdValid = true
    }
  }
  onInputFirstNameChange(value: string){
    if(value.length == 0){
      this.isFirstNameValid = false
    }else{    
      this.isFirstNameValid = true
    }
    this.btnValidate()
  }
  onInputLastNameChange(value: string){
    if(value.length == 0){
      this.isLastNameValid = false    
    }else{
      this.isLastNameValid = true
    }
    this.btnValidate()
  }
  onInputEmailChange(value: string){
    this.isEmailValid = this.Utils.validateEmail(value)
    this.btnValidate()
  }
  
  onInputPhChange(event:any) {
    this.isPhnValid = false
    var newVal = event.replace(/\D/g, '');
      if(newVal.length == 0){
      this.isPhnValid = false
      } else{
      if (newVal.length == 0) {
        newVal = '';
      }else if (newVal.length <= 1) {
        if(newVal !=0){
          newVal = '0' + newVal
        }
        newVal =newVal.replace(/^(\d{0,1})/, '$1');
     } 
      else if (newVal.length <= 4) {
         newVal =newVal.replace(/^(\d{0,1})(\d{0,3})/, '$1 ($2)');
      } else if (newVal.length <= 7) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '$1 ($2) $3');
   
      } else if (newVal.length <= 11) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 ($2) $3 $4 $5');
      } else {
        newVal =newVal.substring(0, 11);
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4 $5');
      }
        this.ph = newVal   
    }
    if(newVal.replace(/\D/g, '').length == 11){
      this.isPhnValid = true
    }
   this.btnValidate()
}

  btnValidate(){
    if(this.firstNameFormControl.value != null && this.firstNameFormControl.value != '' && this.lastNameFormControl.value != null && this.lastNameFormControl.value != '' && this.isEmailValid && this.phoFormControl.value != null && this.phoFormControl.value != '' && this.phoFormControl.value.length > 16
    && this.memberApprovalFormControl.value == true && this.contactConfirmFormControl.value == true && this.cityFormControl.value != null && this.cityFormControl.value != '' && this.districtFormControl.value != null && this.districtFormControl.value != '')
    {
      this.btnDisabled = true
    }else{
      this.btnDisabled = false
    }
  }

  onClikShowPassword(){
    const x = document.getElementById("inputPass") as HTMLInputElement;
    const icon = document.getElementById("passEye")
    // console.log(x.type)
    if(x.type === 'password'){
      x.type = 'text'
      icon?.classList.add('fa-eye')
      icon?.classList.remove('fa-eye-slash')
    }else{
      x.type = 'password'
      icon?.classList.add('fa-eye-slash')
      icon?.classList.remove('fa-eye')
    }
  }

  getCheckboxValue(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkStatus = checkbox.checked;
    this.btnValidate()
  }
  memberCheckbox(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkMember_approval = checkbox.checked;
    this.btnValidate()
  }
  messageCheckbox(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkCommercial_msg = checkbox.checked;
    
  }

  removeLoader(){
    const button = document.getElementById('btnSignUp');
    button?.classList.remove('is-loading');
  }
  addLoader(){
    const button = document.getElementById('btnSignUp');
    button?.classList.add('is-loading');
  }

  // loginService(){
  //   this.addLoader()
  //   let payload = {
  //     "first_name": this.firstNameFormControl.value,
  //     "last_name": this.lastNameFormControl.value,
  //     "email": this.emailIdFormControl.value,
  //     "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
  //     "notification": this.checkStatus ? 1 : 0,
  //   }
  //   this.BookService.saveUserRegistration(payload).subscribe(
  //     (res)=>{
  //       if(res.statusCode ===1){
  //         let user_id = {
  //           user_id : res.user_id,
  //           first_name:this.firstNameFormControl.value,
  //           last_name:this.lastNameFormControl.value,
  //           email:this.emailIdFormControl.value,
  //           phone_no:this.phoFormControl.value.replace(/[(\s)]/gi, ''),
  //         }
  //         this.firstNameFormControl.setValue('')
  //         this.lastNameFormControl.setValue('')
  //         this.emailIdFormControl.setValue('')
  //         this.phoFormControl.setValue('')
  //         this.pwdFormControl.setValue('')
  //         let notification_data = {
  //             types : "New Driver",
  //             reference_id : user_id.user_id,
  //             title : `${user_id.first_name} ${user_id.last_name}`,
  //             content : `${user_id.first_name} ${user_id.last_name} newly registered on Arabama`
  //         }
  //         this.isLoading = false
  //         this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
  //         localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(user_id))
  //         this.route.navigate(['/dashboard'], {state: { notification_data }})
  //         this.removeLoader()

  //       }
  //     },(err =>{
  //       console.log(err)
  //       this.removeLoader()
  //       this.Utils.openErrorSnackBar(this.translate.instant(err.error.message))
  //     }))
  // }

otpDetails: OTP;
onInputChange(value: any) {
  this.isOtpSend = false
  this.otpDetails.otp1 = ''
  this.otpDetails.otp2 = ''
  this.otpDetails.otp3 = ''
  this.otpDetails.otp4 = ''
}



numberOnly(event: any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

backspace(event: any, otpTab: number) {
  if (event.key == "Backspace") {
    if (otpTab === 4) {
      if (this.otpDetails.otp4 == '') {
        this.otp3.nativeElement.focus()
      }
    } else if (otpTab === 3) {
      if (this.otpDetails.otp3 == '') {
        this.otp2.nativeElement.focus()
      }
    } else if (otpTab === 2) {
      if (this.otpDetails.otp2 == '') {
        this.otp1.nativeElement.focus()
      }
    }
  }
}

tabChange(val: number) {
  let current_id = 'otp' + val
  val = val + 1
  let id = 'otp' + val
  let ele = document.getElementById(id) as HTMLInputElement;
  let current_ele = document.getElementById(current_id) as HTMLInputElement;
  if (current_ele.value != '') {
    ele?.focus()
  } else {
    current_ele?.focus()
  }
}

sendOtp(a: string) {
  if (a === 'Resend') {
    // this.isLoading = true;
  }
  this.addLoader()
  document.getElementById('btnLogin')?.classList.add('is-loading')
        this.otpDetails.otp1 = '';
        this.otpDetails.otp2 = '';
        this.otpDetails.otp3 = '';
        this.otpDetails.otp4 = '';
        this.otptimer()
        let payload = {
          "first_name": this.firstNameFormControl.value,
          "last_name": this.lastNameFormControl.value,
          "email": this.emailIdFormControl.value,
          "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
          "notification": this.checkStatus ? 1 : 0,
          "member_approval": this.checkMember_approval ? 1 : 0,
          "commercial_message": this.checkCommercial_msg ? 1 : 0,
        }
        this.BookService.saveUserRegistration(payload).subscribe(
          (res)=>{
            if(res.statusCode ===1){
              this.isOtpSend = true
              this.removeLoader()
            }
          },(err =>{
            // console.log(err)
            this.removeLoader()
            this.Utils.openErrorSnackBar(this.translate.instant(err.error.message))
          }))
}



verifyOTP() {
  if(this.otpDetails.otp1 && this.otpDetails.otp2 && this.otpDetails.otp3 && this.otpDetails.otp4){
  this.addLoader()
  let userOTP = this.otpDetails.otp1 + this.otpDetails.otp2 + this.otpDetails.otp3 + this.otpDetails.otp4

  let payload = {
    "first_name": this.firstNameFormControl.value,
    "last_name": this.lastNameFormControl.value,
    "email": this.emailIdFormControl.value,
    "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
    "city": this.cityFormControl.value,
    "province" : this.districtFormControl.value,
    "notification": this.checkStatus ? 1 : 0,
    "member_approval": this.checkMember_approval ? 1 : 0,
    "commercial_message": this.checkCommercial_msg ? 1 : 0,
    "otp" : userOTP
  }
  this.BookService.authUserRegistration(payload).subscribe(
    (res)=>{
      if(res.statusCode ===1){
        let user_data = res.data
        this.firstNameFormControl.setValue('')
        this.lastNameFormControl.setValue('')
        this.emailIdFormControl.setValue('')
        this.phoFormControl.setValue('')
        this.pwdFormControl.setValue('')
        this.cityFormControl.setValue('')
        this.districtFormControl.setValue('')
        let notification_data = {
            types : "New Driver",
            reference_id : user_data.user_id,
            title : `${user_data.first_name} ${user_data.last_name}`,
            content : `${user_data.first_name} ${user_data.last_name} newly registered on Arabama`
        }
        this.isLoading = false
    
        this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
        localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(res.data))
        localStorage.setItem(Constants.APP.SESSION_ID, JSON.stringify(res.data.session_id))
        this.removeLoader()
        if(this.isFromSignupSaveEstimate){
          this.route.navigate(['/service/estimates'], {state : {isFromSignupSaveEstimate : true}})
        }else{
          this.route.navigate(['/dashboard'], {state: { notification_data }})
        }

      }
    },(err =>{
      // console.log(err)
      this.removeLoader()
      this.Utils.openErrorSnackBar(this.translate.instant(err.error.message))
    }))

  }
}


otptimer() {

  var m = "03";
  var s = "00";

  const timer = () => {

    // document.getElementById('timer')!.innerHTML = m + ':' + s;
    this.expire = m + ':' + s;
    if (parseInt(s) == 0) {
      s = "59";
    } else {
      s = sec(parseInt(s));
    }
    if (parseInt(m) >= 0 && parseInt(s) == 59) {
      m = sec(parseInt(m))
    }
    // console.log(parseInt(m))
    if (parseInt(m) >= 0) {
      setTimeout(function () {
        timer();
      }, 1000);
      return;
    }
  }

  timer();

}

fetchCity(){
  this.BookService.fetchCity().subscribe({
    next: (res) => {
      if(res.statusCode == 1){
        this.cityList = res.data
        this.optionCity = res.data.map((item: any) => item.city_name).sort((a: string, b:string) => 
          a.localeCompare(b, 'tr', { sensitivity: 'variant' })
        );
        if(this.cityFormControl.value){
          let city = this.cityList?.find((item: any) => item.city_name?.toLowerCase() == this.cityFormControl.value?.toLowerCase())
          this.fetchDistrict(city.city_id)
          this.disableDistrict = false
        }
      }
    },
    error: (err) => {
      console.log(err)
    }
  })
}

fetchDistrict(cityId: number){
  let data = { cityId }
  this.BookService.fetchDistrict(data).subscribe({
    next: (res) => {
      if(res.statusCode == 1){
        this.optionDistrict = res.data.map((item: any) => item.district_name).sort((a: string, b:string) => 
          a.localeCompare(b, 'tr', { sensitivity: 'variant' })
        );
      }
    },
    error: (err) => {
      console.log(err)
    }
  })
}

selectCity(data:string){
  this.cityFormControl.setValue(data)
  this.districtFormControl.setValue('')
  if(data == '' || data == undefined){
    this.disableDistrict = true
    this.optionDistrict = []
    this.isCityValid = false
  } else{
    let city = this.cityList.find((item: any) => item.city_name?.toLowerCase() == data?.toLowerCase())
    this.fetchDistrict(city.city_id)
    this.disableDistrict = false
    this.isCityValid = true
  }
  this.btnValidate()
}
selectProvince(data:any){
  if(data){
    this.districtFormControl.setValue(data)
    this.isDistictValid = true
  }else{
    this.districtFormControl.setValue('')
    this.isDistictValid = false
  }
  this.btnValidate()
}
}
function sec(a: number): string {

if ((a - 1) < 10 && (a - 1) >= 0) return "0" + (a - 1) + "";
return (a - 1) + ""
}

interface OTP {
otp1: string,
otp2: string,
otp3: string,
otp4: string
}