import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  currentStep!: number

  faq: any =[
    
    {
      question: "What is Arabama?",
      answer: "arabamaservis.com, It is a platform that will allow you to get the best service your car needs in four simple steps, at the location you want."
    },
    {
      question: "Why should I choose Arabama?",
      answer: "Arabama offers the best car services and solutions at fair and flexible prices. You end up saving cost compared to what is charged at Authorised Service Centres and Multi-brand workshops"
    },
    {
      question: "Where can I book a car service with arabama?",
      answer: "You can book a Arabama car service directly from our website or by downloading the exclusive Android App. Want a more human experience? call or WhatsApp on 08504272727."
    },
    {
      question: "How to book a car service with Arabama?",
      answer: "We have made booking a car service as easy as 1-2-3-4. Just select your location, select the type of car service you require, select vehicle, Choose your preferred time slot And your Appointment will be created! We offer estimation based on your information"
      },
]
  constructor(private route: Router) { }

  ngOnInit(): void {
  }
  setStep(step: any) {
    this.currentStep = step;
  }
  help(){
    this.route.navigate(['/help'])
  }

}
