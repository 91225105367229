<!-- <nav class="navbar" style="border: 1px solid blue;">
    <div class="navbar-brand is-pa-l-10">
      <img class="is-clickable" src="assets/img/arabama.png" width="150" height="40" routerLink="/home"/>
    </div>
    <div class="stepper-nav">
        <div class="navbar-item">
          <app-stepper style="min-width:100%;" [activeStepper]="stepperCount"></app-stepper>
        </div>
    </div>
    <div class="navbar-end">
      <a class="navbar-item mr-6">
        <button class="button is-success" (click)="onClickClose()">
          <span class="icon is-small">
            <i class="fas fa-close"></i>
          </span>
        </button>
      </a>
    </div>
  </nav> -->
  <div class="quote-content" [@slideInOut]>
    <div class="columns ps-relative m-0 is-centered pb-2">
        <div class="column p-0">
          <div class="is-flex is-align-items-center px-3 py-2">
            <button class="button back_button" (click)="backPressed()">
              <span class="icon is-small is-left" style="color: #fff; ">
                <i class="fas fa-arrow-left"></i>
              </span>
          </button>
            <h3 class="pl-3 is-size-6-mobile my-0">{{"SERVICE.TEXT_45" | translate}}</h3>
          </div>
            <div class="px-3 job-details">
              <app-popular-services-right-nav-list [jobDetails]="true" [stepperCount]="stepperCount"></app-popular-services-right-nav-list>
          </div>
        </div>
      </div>
</div>