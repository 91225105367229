<div class="columns">
  <div class="column">
      <div class="is-horizontal">
        <p class="has-icons-left has-icons-right">
        <input type="text"
        class="input is-medium custom-input" 
              :placeholder="{{ 'HOME.TEXT_52' | translate }}"
              aria-label="Number"
              on-focus="onFocusProvince()" 
              on-focusout = "onFocusOutProvince()"
              [formControl]="pincodeControl"
              [(ngModel)]="searchProvince"
              (ngModelChange)="onInputProvinceChange($event)">
        <span class="icon is-small is-left is-align-items-center">
          <img class="location-icon-inner" src="assets/img/location.png" />
        </span>
        <span *ngIf="searchProvince" class="close-icon is-clickable" (click)="clearLocation()">
          <img class="close-icon-img" src="assets/img/ham-close.png" />
        </span>
        </p>
        
      </div>
      
  </div>
</div>
<div id="dropDownProvince" class="global-search-menu rounded-bottom shadow show-dropdown-province" style="position: relative;">
  <div class="search-results list-group list-group-flush" style="position: absolute; width: 100%; height: 300px;">
     
      <div  id="btnProvince" class="display_drop_down_province" style="overflow-y: auto;">
          <div *ngFor="let item of pincodefil">
              <button class="list-group-item list-group-item-action" (mousedown)="onClickProvince(item)">
                <span [innerHTML]="formatProvince(item.place)"></span>, <span [innerHTML]="formatProvince(item.province)"></span>
              </button>
          </div>
      </div>
  </div>  
</div> 

