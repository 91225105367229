import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews-line-item',
  templateUrl: './reviews-line-item.component.html',
  styleUrls: ['./reviews-line-item.component.scss']
})
export class ReviewsLineItemComponent implements OnInit {

  @Input() reviewItem: any;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.reviewItem)
  }

}
