import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() headerPage = new EventEmitter<any>();
  @Output() howWorksPage = new EventEmitter<any>();

  constructor(public translate: TranslateService) {
    // translate.setDefaultLang('en');
   }

  ngOnInit(): void {    
    
  }
  openindex(event: any){
    this.headerPage.emit(event)   
  }
  openHowWorks(event: any){
    this.howWorksPage.emit(event)   
  }

}
