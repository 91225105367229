import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  private siblingMsg = new Subject<string>();
  private grandMsg = new Subject<string>();
  private updateServicesList = new Subject<any>();
  private popularServiceData : any ;
  private problemData: any;
  private serviceData:any;
  private endServiceData:any;
  private estimateDetails =  new Subject<any>();
  private estimateTimeDetails = new Subject<any>();
  private addressdata = new Subject<any>()
  private scrollPosition: Subject<any> = new Subject<any>();
  public position$: Observable<any> = this.scrollPosition.asObservable();
  private triggerSaveEstimation = new Subject<void>();
  public saveEstimation$ = this.triggerSaveEstimation.asObservable();
  private triggerMessagePopup = new Subject<void>();
  public openMessage$ = this.triggerMessagePopup.asObservable();
  private bookedPopup = new BehaviorSubject<boolean>(false);
  public bookedPopup$ = this.bookedPopup.asObservable();
  private showAddService = new BehaviorSubject<boolean>(false);
  public showAddService$ = this.showAddService.asObservable();
  private triggerInComments = new Subject<void>();
  public isCommentsPage$ = this.triggerInComments.asObservable();
  private triggerForwardBtnSubject = new Subject<void>();
  public triggerForwardBtn$ = this.triggerForwardBtnSubject.asObservable();
  private deleteAppointmentsubject = new Subject<void>();
  public deleteAppointment$ = this.deleteAppointmentsubject.asObservable();
  private estimateScroll = new Subject<boolean>();
  public estimateScroll$ = this.estimateScroll.asObservable();
  private triggerPopupSubject = new Subject<string>();
  public triggerPopup$ = this.triggerPopupSubject.asObservable();
  private popupResponseSubject = new Subject<string>();
  public popupResponse$ = this.popupResponseSubject.asObservable();
  private messagePopupDataSubject = new Subject<any>();
  public messagePopupData$ = this.messagePopupDataSubject.asObservable();

  private updateProfile = new Subject<boolean>();
  public updateProfile$ = this.updateProfile.asObservable();
  constructor() { }

  public getData(): Observable<string> {
    return this.siblingMsg.asObservable();
  }

  public updateData( data: string): void {
    this.siblingMsg.next(data);
  }

  //add service
  // public getShowingAddService(): Observable<boolean> {
  //   return this.showAddService.asObservable();
  // }

  public updateShowingAddService( data: boolean): void {
    this.showAddService.next(data);
  }

  public getSubData(): Observable<string> {
    return this.grandMsg.asObservable();
  }

  public updateSubData( data: string): void {
    this.grandMsg.next(data);
  }

  public getUpdatedServiceList():Observable<any> {
    return this.updateServicesList.asObservable();
  }

  public updateServiceList(data: string): void {
    return this.updateServicesList.next(data);
  }

  public setPopularServiceData(popularServiceData: any){
    this.popularServiceData =popularServiceData;
  }
  public getPopularServiceData(){
    let temp = this.popularServiceData;
    return temp;
  }

  //problem data
  public setProblemData(problemData: any){
    this.problemData= problemData
  }
  public getProblemData(){
    return this.problemData
  }

  //get end service data
  public setEndServiceData(endServiceData: any){
    this.endServiceData = endServiceData;
  }
  public getendServiceData(){
    return this.endServiceData
  }

  //get end service data
  public setServiceData(serviceData: any){
    this.serviceData = serviceData;
  }
  public getServiceData(){
    return this.serviceData
  }

  //get estimate data
  public setEstimateData(estimateData: any){
   return this.estimateDetails.next(estimateData)
  }
  public getEstimateData(){
    return this.estimateDetails.asObservable()
  }

   //get estimate time data
   public setEstimateTimeData(estimateTimeData: any){
    return this.estimateTimeDetails.next(estimateTimeData)
   }
   public getEstimateTimeData(){
     return this.estimateTimeDetails.asObservable()
   }

   //update address
   public setAddress(address: any){
    return this.addressdata.next(address)
   }
   public getAddress(){
     return this.addressdata.asObservable()
   }

   //save Estimation
   saveEstimation() {
    this.triggerSaveEstimation.next();
  }

  //open message Popup
  openMessagePopup(){
    this.triggerMessagePopup.next()
  }
  // set booked popup value

  setBookedPopup(data: boolean){
    this.bookedPopup.next(data)
  }
  // Trigger when service comments page
  triggerComments(){
      this.triggerInComments.next()
  }
  // Trigger when forward btn click on comments page
  triggerForwardBtn(){
    this.triggerForwardBtnSubject.next()
  }

  //remove appointment
  deleteAppointment(){
    this.deleteAppointmentsubject.next()
  }

  // set estimate scrolling
  setEstimateScrolling(data: boolean){
    this.estimateScroll.next(data)
  }
  // trigger popup inside left column
  triggerPopup(data: string){
    this.triggerPopupSubject.next(data)
  }
  setPopupResponse(data: string){
    this.popupResponseSubject.next(data)
  }

  setMessagePopupData(data: any){
    this.messagePopupDataSubject.next(data)
  }

   setPosition(position: any) {
    this.scrollPosition.next(position);
  }

  updateUserProfile(data: boolean){
    this.updateProfile.next(data)
  }

  clearData(){
    this.popularServiceData = undefined;
    this.problemData = undefined;
    this.serviceData = undefined;
    this.endServiceData = undefined;
  }

  clearPopularData(){
    this.popularServiceData = undefined
  }
}
