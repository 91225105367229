import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  @ViewChild('guaranteeSection') guaranteeSection!: ElementRef;
  @ViewChild('howWorksSection') howWorksSection!: ElementRef;

  logInuser : boolean = false
  userdata: any
  constructor(private Router: Router,
              private PassService: PassDataService,private sharedService : SharedServiceService) { }
  private unsubscriber: Subject<void> = new Subject<void>();
  ngOnInit(): void {
    document.body.classList.remove('inner-class');
    this.userdata = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA) || '{}')    
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }
  clickOutside(){
    this.PassService.setOutsideClickListener()
  }
  openguarantee(event: any){
    if(event=='guarantee'){
      let yOffset
      if(this.userdata.user_id){
         yOffset = -40;
      }
      else{
         yOffset = -80;
      }    
      const yPosition = this.guaranteeSection.nativeElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
  }
  openHowWorks(event: any){
    if(event=='how-works'){
      let y1Offset
      if(this.userdata.user_id){
        y1Offset = -60;
     }
     else{
        y1Offset = -90;
     }   
      const yPosition = this.howWorksSection.nativeElement.getBoundingClientRect().top + window.pageYOffset + y1Offset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
  }
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
