<div class="is-flex is-justify-content-center is-align-items-center" style="min-height: 100%;">
    <div class="p-1 has-text-centered">
        <!-- <h1 mat-dialog-title class="has-text-weight-bold mb-2">{{'ERROR_MESSAGE.error_alert' | translate}}</h1> -->
        <div class="has-text-weight-bold pb-3" mat-dialog-content>{{displayInfo}}</div>
        <div class="mt-3 buttons is-flex is-justify-content-center">
            <div class="button btn_cancel has-text-weight-semibold is-small mx-4" (click)="cancelDialog()">{{'COMMON.CLOSE' | translate}}</div>
            <div class="button has-text-weight-semibold is-small mx-4 is-success" (click)="okDialog()">{{'COMMON.OK' | translate}}</div>
        </div>
    </div>
</div>
