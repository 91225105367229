<div>
  
    <!-- <img class="is-hidden-desktop" src="assets/img/banner3.png" style="width: 100%; position: block;"> -->
  <div class="desktop-card is-hidden-mobile">
    <div class="columns is-full" >
      <div class="column is-4-tablet is-two-fifths-desktop  min-height">
        <div class="is-hidden-mobile home-banner">
          <img src="./assets/img/banner-main.jpg">
        </div>
      </div> 
      <div class="column min-height"> 
          <h1 class="header-text"> 
            {{ 'HOME.TEXT_1' | translate }}&nbsp;<span id="changeText" [innerHTML]="changingText" style="color:#AAD043" class="{animated : zero, bounce : zero} pr-2"> </span>{{'HOME.TEXT_1_1' | translate}}<br>{{'HOME.TEXT_1_2' | translate}}
            </h1> 
            <h2 class="slide-text"> 
              {{ 'HOME.TEXT_58' | translate }}
            </h2> 
            <div class="columns">
              <div class="column is-two-thirds-desktop is-three-tablets">
                <form class="example-form">
              <app-new-location-search [banner]="true"></app-new-location-search>
            <!-- <div class="field has-addons home-search">  -->
              <!-- <p class="control has-icons-left" style="width:250px; margin-right: 20px;">
                <input type="text"
                class="input is-medium"
                style="border-radius: 10px;font-size: 14px; background-color: #FAF7F7; border: none;padding-left: 47px !important;"
                      :placeholder="{{ 'HOME.TEXT_6' | translate }}"
                       matInput
                       [formControl]="servicesControl"
                       [matAutocomplete]="templateServiceSource">
                <mat-autocomplete autoActiveFirstOption 
                                  [displayWith]="displayServices"
                                  #templateServiceSource="matAutocomplete">
                  <mat-option *ngFor="let option of servicesOptions | async" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
                <span class="icon is-small is-left" style="height: 60px; color: #AAD043;">
                 <i class="fas fa-search"></i>
               </span>
              </p> -->
              <!-- <p class="control has-icons-left"  style="width: 250px;">
                <app-location class="desktop-input" (locationInputChange)="onInputPinChange($event)"></app-location>
                <app-current-location></app-current-location> -->
                <!-- <input type="text"
                class="input is-medium" 
                       :placeholder="{{ 'HOME.TEXT_52' | translate }}"
                       aria-label="Number"
                       matInput
                       [formControl]="pincodeControl"
                       (ngModelChange)="onInputPinChange($event)"
                       [matAutocomplete]="pin"
                       (keydown.enter)="$event.preventDefault()"
                       style="font-size: 14px;  background-color: #FAF7F7; border: none; border-radius: 10px;"
                       autocorrect="off" autocapitalize="off" spellcheck="off" #search required> -->
                <!-- <mat-autocomplete autoActiveFirstOption #pin="matAutocomplete">
                  <mat-option *ngFor="let option of pincodeOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete> -->
                <!-- <span class="icon is-small is-left" style="height: 60px; color: #AAD043; ">
                  <i class="fas fa-map-marker-alt"></i>
                </span> -->
              <!-- </p>
              <p class="control">
                <a (click)="onClickGo()" routerLinkActive="active" [ngClass]="isGoEnabled ? 'is-success':'disabled'"  class="button is-medium"  style="height: 60px; margin-top: -1.5px; width:150px; border-radius:10px; margin-left: 20px; background-color:  #aad043;">
                <span class="text-size-16 heading-font px-6">  {{ 'COMMON.FIND' | translate }} </span>
                </a>
              </p>
            </div> -->
                </form>
              </div>
            </div>
          
            <!-- <div class="card is-width-300 is-pa-50 mr-6 is-ma-l-150 is-hidden-mobile">
              <div>
                <div>
                  <form class="example-form">
                      <input type="text"
                      class="no-border"
                      style="height: 52px;width: 100%;"
                            :placeholder="{{ 'HOME.TEXT_6' | translate }}"
                             matInput
                             [formControl]="location"
                             [matAutocomplete]="auto" 
                             >
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of locationsOptions | async" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                    <hr>  
                      <input type="text"
                      style="height: 52px;width: 100%;"
                             :placeholder="{{ 'HOME.TEXT_52' | translate }}"
                             aria-label="Number"
                             matInput
                             class="no-border"
                             [formControl]="pincode"
                             [matAutocomplete]="pin">
                      <mat-autocomplete autoActiveFirstOption #pin="matAutocomplete">
                        <mat-option *ngFor="let option of pincodeOptions | async" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                  </form> 
              </div>
              </div>
              <div class="has-text-centered">
              <button class="button is-rounded is-success mt-6">
                <a routerLink="/service" class="text-dec-none has-text-white">{{ 'HOME.TEXT_51' | translate }}</a>
              </button>
              </div>
            </div> -->
          <!-- <h1 class="is-size-3 has-text-white has-text-weight-bold">{{ 'HOME.TEXT_1' | translate }}</h1> -->
          <!-- <h5 class="is-size-6 has-text-white ">{{ 'HOME.TEXT_2' | translate }}</h5> -->
          <!-- <button class="button is-success mt-3">Find Garage</button> -->
        </div> 
    </div>
  </div>
  <div class="is-hidden-desktop is-hidden-tablet mobile-banner" style="position: relative;">
    <div class="banner-img">
      <img class="is-hidden-desktop is-hidden-tablet" src="assets/img/banner4.jpg">
    </div> 
    <div class="banner-wrapper">
    <h1 class="py-3 has-text-centered slide-text">
      {{ 'HOME.TEXT_1' | translate }}&nbsp;<span id="changeText" [innerHTML]="changingText" style="color:#AAD043" class="{animated : zero, bounce : zero} pr-1"></span>
      <br>{{'HOME.TEXT_1_1' | translate}}<br>{{'HOME.TEXT_1_2' | translate}}
      </h1>
      <h2 class="is-pa-15 has-text-centered slide-text"> 
        {{ 'HOME.TEXT_58' | translate }}
      </h2> 
      <div class="">
          <div class="has-text-centered is-pa-10" style="max-width: 100%;">
            <div>
              <form class="example-form">
                <div class="field ">
                  <div class="control has-icons-left">
                    <span class="icon" style="width: 24px;">
                        <img class="location-icon-home ml-5 mt-3" src="../../../assets/svg/search-icon.svg" /> 
                    </span>
                    <input type="text"
                    class="input is-medium service-input"
                    style="border-radius: 10px; margin-bottom: 8px;font-size: 15px !important;padding-left: 46px !important;"
                          :placeholder="{{ 'HOME.TEXT_6' | translate }}"
                          [(ngModel)]="searchService"
                       [formControl]="servicesControl"
                       [matAutocomplete]="templateServiceSource">
                    <span *ngIf="searchService" (click)="clearService()" class="service-close-icon is-flex">
                        <img  class="close-icon-img" src="../../../assets/img/ham-close.png" /> 
                    </span>
                    <!-- <div > -->
                      <mat-autocomplete autoActiveFirstOption 
                                        [displayWith]="displayServices"
                                        #templateServiceSource="matAutocomplete">
                        <div [ngStyle]="servicesControl.value.length > 0 ? {}: {'display': 'none'}">
                        <mat-option  *ngFor="let option of servicesOptions | async" [value]="option">
                          <span [innerHTML]="formatService(option.name)"></span>
                          <!-- {{option.name}} -->
                        </mat-option>
                        </div>
                      </mat-autocomplete>
                    <!-- </div> -->
                      
                  </div>
                 
                  <p class="control has-icons-left">
                    <!-- <app-location (locationInputChange)="onInputPinChange($event)"></app-location> -->
                    <!-- <app-current-location></app-current-location> -->
                    <input type="text"
                    class="input is-relative pl-6 province-input"
                    style=" border-radius: 10px;"
                           :placeholder="{{ 'HOME.TEXT_52' | translate }}"
                           aria-label="Number"
                           [(ngModel)]="searchProvince"
                           [formControl]="pincodeControl"
                           [matAutocomplete]="pin"
                          #inputField>
                          <!-- <span class="province-close-icon">
                            <img class="close-icon-img" src="../../../assets/img/ham-close.png" /> 
                        </span> -->
                    <mat-autocomplete autoActiveFirstOption
                    [displayWith]="displayProvince"
                    #pin>
                    <div [ngStyle]="pincodeControl.value.length > 0 ? {}: {'display': 'none'}">
                      <mat-option *ngFor="let option of pincodeOptions | async" [value]="option" (click)="setLocation()">
                        <span [innerHTML]="formatProvince(option.place)"></span>, <span [innerHTML]="formatProvince(option.province)"></span>
                      </mat-option>
                    </div>
                    </mat-autocomplete>
                    <span class="icon is-small location-icon-home-mobile" style="color: #AAD043; padding: 3px; width: 24px !important;">
                      <img class="" src="assets/img/location.png" />
                    </span>
                    <span class="current-location is-flex is-align-items-center">
                      <img *ngIf="searchProvince" (click)="clearProvince()" class="mr-1" src="assets/img/ham-close.png" />
                      <img (click)="setCurrentLocation()"  class="current-icon" src="assets/svg/current_location.svg" />
                    </span>
                  </p>
                
                </div> 
                <p class="control">
                  <a (click)="onClickGo()" routerLinkActive="active" [ngClass]="isGoEnabled ? 'is-success':'disabled'" class="button is-medium" 
                  style="margin-bottom:10px; border-radius:10px;">
                  <span class="text-size-16 heading-font">  {{ 'COMMON.FIND' | translate }} </span>
                  </a>
                </p>
                </form>
          </div>
      </div>
  </div>
  </div>
</div>