import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { ChatService } from 'src/app/services/chat.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-side-notification',
  templateUrl: './side-notification.component.html',
  styleUrls: ['./side-notification.component.scss']
})
export class SideNotificationComponent implements OnInit {
  @Input() showNotification: boolean = false;
  @Output() hideNotification = new EventEmitter<any>();
  @Output() notificationCount = new EventEmitter<number>();
  userDetails: any;
  notification_list: any = [];

  constructor(private chatService: ChatService, private socketService: SocketService, private sharedService: SharedServiceService, private router: Router, private passServices: PassDataService) { }

  ngOnInit(): void {
     this.userDetails = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA) || '{}');
     this.fetchAllNotification()
     this.setupSocketListeners()
  }

  setupSocketListeners() {
    this.socketService
      .listen('driverNotificationById')
      .subscribe((data) => {
        const currentDate = new Date();
        const newData = { ...data, date_create: currentDate };
        const existingIndex = this.notification_list?.findIndex((item: { id: number }) => item.id === newData.id);
        if (existingIndex !== -1) {
          this.notification_list?.splice(existingIndex, 1);
          this.notification_list?.unshift(newData);
        }else{
          this.notification_list.unshift(newData)
        }
        this.notificationCount.emit(this.notification_list.length) 
      });
  }
  
  clickBack(){
    this.hideNotification.emit(false);
  }
  fetchAllNotification(){
    this.chatService.fetchAllNotification(this.userDetails.user_id).subscribe({
      next: (res) => {
        this.notification_list = res.data
        this.notificationCount.emit(this.notification_list.length)
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
  setReadNotification(notification: any){
    this.chatService.setReadNotification(notification, this.userDetails.user_id).subscribe({
      next: (res) => {
        if(res.statusCode === 1){
          let data = {
            job_id: notification.reference_id,
            notification_type : notification.types,
            notification_title: notification.title
          }          
          if(notification.title === 'Job Completed'){
            this.passServices.pageIndex = "service_history";
            this.router.navigate(['/profile/service-history'])
          }else if(data.notification_title === 'Job Confirmed') {
            this.router.navigate(['/dashboard'])
            this.sharedService.setPosition(data)
            
          }
          this.removeNotification(notification.id)
        }
      },
      error: (err) => {
        console.log(err); 
      }
    })
  }
  removeNotification(id : number){
    this.clickBack()
    this.notification_list = this.notification_list?.filter((item: { id: any; }) => item.id !== id);
    this.notificationCount.emit(this.notification_list.length)
  }
}
