<div>
    <ul>
        <li class="list-style mt-2">{{'COMMON.YES' | translate}}
            <i class="fa fa-chevron-right is-pulled-right pt-1"></i>
        </li>
        <li class="list-style mt-2">{{'COMMON.NO' | translate}}
            <i class="fa fa-chevron-right is-pulled-right pt-1"></i>
        </li>
    </ul>
    <textarea style="resize: none;" rows="10" class="textarea mt-3 text-align-left" 
    :placeholder="{{'SERVICE.TEXT_3' | translate}}"></textarea>

    
    <h4 class="is-ma-t-40 has-text-success">{{'SERVICE.TEXT_4' | translate}}</h4>
    <p class="subheading-color-1 text-size-14">
        {{'SERVICE.TEXT_3' | translate}}</p>
    <button class="button is-pulled-right is-success mt-3 mr-6" (click)="moveNextStep()">{{'COMMON.CONTINUE' | translate}}</button>
</div>