import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { BookingServiceService } from 'src/app/services/booking-service.service'
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Utils } from 'src/app/common/utils';
@Component({
  selector: 'app-card-one-services-list',
  templateUrl: './card-one-services-list.component.html',
  styleUrls: ['./card-one-services-list.component.scss']
})
export class CardOneServicesListComponent implements OnInit {
  popularService : any = [];
  problemList : any = [];
    
  constructor(public translate: TranslateService,
              public bookService: BookingServiceService,
              private route: Router,
              private sharedService: SharedServiceService,
              private utilsClass: Utils) { }

  ngOnInit(): void {
    this.getServicesList()
    this.getProblemList()
  }

  getServicesList(){
    this.bookService.getServiceList({}).subscribe(
      (res)=> {
        if(res.statusCode == 1){
          this.popularService = res.data.filter((item:any,index:any,arr:any)=>{
            return index === arr.findIndex((ele:any)=>ele.answer === item.answer)
          });
          let order_list = [{service_id : 22,answer : "Periyodik bakım"},{service_id:4,answer: "Fren balatası değişimi"},{service_id:23,answer: "Yağ / Yağ Filtre değişimi"},{service_id:24,answer: "Triger seti değişimi"},{service_id:12,answer : "Akü"},{service_id:52,answer: "Şanzıman yağı değişimi"},{service_id:57,answer: "Buji değişimi"}]
          
          let final_data:any = []
          order_list.forEach((item:any)=>{
            let data = this.popularService.find((ele:any)=> ele.service_id == item.service_id)
            final_data.push(data)
          })
          this.popularService = final_data
          this.popularService.forEach((element:any) => {
            element.icon = element.icon != '' ? element.icon : 'not_working.svg'
            element.ans_icon ='assets/svg/'+element.icon;
          });
        }
      }
    )
  }

  getProblemList(){
    let payload = {
      isMainScreenFrom: 1
    }
    this.bookService.getProblemList(payload).subscribe(
      (res)=> {
        if(res.statusCode == 1){
          this.problemList = res.data
          let last_data = this.problemList.pop()
          this.problemList = this.problemList.slice(0,6)
          this.problemList.push(last_data)
          this.problemList.forEach((element:any) => {
            element.icon = (element.icon != '' && element.icon != undefined) ? element.icon : 'spanner.svg'
            element.ans_icon ='assets/svg/'+element.icon;
          });
        }
      }
    )
  }

  routeServices(element:any){
    this.sharedService.setPopularServiceData(element)
    if(localStorage.getItem("address") == undefined || localStorage.getItem("address") == '' || localStorage.getItem("address") == null){
      // this.utilsClass.openErrorSnackBar('Lütfen bir yer seçin')
      this.route.navigate(['/location'])
    }else{
      this.route.navigate(['/service'])
    } 
    
  }
  routeProblem(element: any){
    this.sharedService.setProblemData(element)
    if(localStorage.getItem("address") == undefined || localStorage.getItem("address") == '' || localStorage.getItem("address") == null){
      // this.utilsClass.openErrorSnackBar('Lütfen bir yer seçin')
      this.route.navigate(['/location'])
    }else{
      this.route.navigate(['/service'])
    } 
  }
  goToServices(){
    if(localStorage.getItem("address") == undefined || localStorage.getItem("address") == ''){
      this.route.navigate(['/location'])
      // this.utilsClass.openErrorSnackBar('Lütfen bir yer seçin')
    }else{
      this.route.navigate(['/service'])
    }
  }
}
