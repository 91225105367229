import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss']
})
export class ServiceRequestComponent implements OnInit {
  isStep = 1;  stepperCount = 1;
  constructor(private sharedService: SharedServiceService,
    private translate: TranslateService) { }

  ngOnInit(): void { }

  ngAfterViewInit(){ setTimeout(()=>{ this.sharedService.updateData('1'); },500) }

  nextStep(){ this.isStep++; }

  previousStep(){ this.isStep--; }

}
