<div class="columns" style="position: absolute;top: 190px;width: 100%;">
    <div class="column"></div>
    <div class="column is-four-fifths">
      <div class="has-text-centered has-background-success is-border-30">
      <div class="columns">
        <div class="column">
          <div class="select is-small is-rounded">
            <select class="bg-tran">
              <option>Where do you live?</option>
              <option>Chennai</option>
              <option>Madurai</option>
              <option>Trichy</option>
            </select>
          </div>
        </div>
        <div class="column">
          <div class="select is-small is-rounded">
            <select class="bg-tran">
              <option>Where do you drive?</option>
              <option>Chennai</option>
              <option>Madurai</option>
              <option>Trichy</option>
            </select>
          </div>
        </div>
        <div class="column">
          <button class="button is-small is-rounded">
            <a href="/service">Find Garage</a></button>
        </div>
      </div>
      </div>
    </div>
    <div class="column"></div>
  </div>