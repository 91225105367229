<div class="pt-3 pb-2 estimate-box mb-2">
  <!-- <div class="is-hidden-desktop">
    <span class="close-wrapper text-size-14">
      <span *ngIf="estimate_item.openStatus" style="color:green">{{ 'COMMON.OPEN' | translate }} - </span>
      <span class="closed" *ngIf="!estimate_item.openStatus">{{ 'COMMON.CLOSED' | translate }}</span>
      <span class="ml-1" *ngIf="estimate_item.openStatus">{{estimate_item.closeTime}} {{ 'COMMON.CLOSE' | translate
        }}</span>
    </span>
  </div> -->
  <div class="columns mb-0">
    <div class="column is-8 pb-0">
      <!-- <div class=" is-flex is-align-items-center">
        <div class="is-clickable is-flex is-align-items-center" (click)="openGarageDetails()">
          <div class="garage-icon is-flex is-justify-content-center is-align-items-center">
            <img src="{{estimate_item.logo_url}}">
          </div>
          <div class="text-size-17 has-text-weight-bold pl-2" >{{ estimate_item.name }} </div>
        </div>
        <div class="msg-icon is-clickable" (click)="messagePopup()">
          <img class="pl-3 icon is-medium" src="../../../assets/svg/msg.svg" alt="">
          <div *ngIf="estimate_item.msg_notification_count > 0" class="field-button is-flex">
            <div class="notification py-1 px-0 is-flex is-justify-content-center is-align-items-center">
              <div class="has-text-weight-bold is-size-7">{{estimate_item.msg_notification_count < 100 ? estimate_item.msg_notification_count : 99}}
              </div>
            </div>
          </div>
        </div>

      </div> -->
      <!-- <div class="is-flex is-justify-content-end is-hidden-tablet">
        <span class="close-wrapper text-size-14">
          <span *ngIf="estimate_item.openStatus" style="color:green">{{ 'COMMON.OPEN' | translate }} - </span>
          <span class="closed" *ngIf="!estimate_item.openStatus">{{ 'COMMON.CLOSED' | translate }}</span>
          <span class="ml-1" *ngIf="estimate_item.openStatus">{{estimate_item.closeTime}} {{ 'COMMON.CLOSE' | translate }}</span>
        </span>
      </div> -->
      <div class="columns is-mobile">
        <div class="column is-narrow px-0">
          <div class="profile-field is-clickable pb-2 pr-3" (click)="openGarageDetails()">
            <div class="profile-img">
              <img src="{{estimate_item.logo_url}}">
            </div> 
          </div>
        </div>
        <div class="column is-fluid px-0">
          <div class="">
            <div class="is-flex is-justify-content-end is-hidden-tablet">
              <span class="close-wrapper text-size-14">
                <span *ngIf="estimate_item.openStatus" style="color:green">{{ 'COMMON.OPEN' | translate }} - </span>
                <span class="closed" *ngIf="!estimate_item.openStatus">{{ 'COMMON.CLOSED' | translate }}</span>
                <span class="ml-1" *ngIf="estimate_item.openStatus">{{estimate_item.closeTime}} {{ 'COMMON.CLOSE' | translate }}</span>
              </span>
            </div>
            <div class="is-flex" style="margin-bottom: -15px;">
              <span class="text-size-17 has-text-weight-bold is-clickable" (click)="openGarageDetails()">{{ estimate_item.name }} </span>
              <div class="msg-icon">
                <img class="pl-3 icon is-medium is-clickable" src="../../../assets/svg/msg.svg" alt="" (click)="messagePopup()">
                <div *ngIf="estimate_item.msg_notification_count > 0" class="field-button is-flex">
                  <div class="notification py-1 px-0 is-flex is-justify-content-center is-align-items-center">
                    <div class="has-text-weight-bold is-size-7">{{estimate_item.msg_notification_count < 100 ? estimate_item.msg_notification_count : 99}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="star-rating">
              <star-rating [value]="estimate_item.rating.cum_rating" [totalstars]="5" checkedcolor="#AAD043"
                uncheckedcolor="#c7c6c6" size="19px" [readonly]="true"></star-rating>
              <span *ngIf="estimate_item.rating.totalRating" class="rating-count text-size-13">( {{estimate_item.rating.cum_rating | number: '1.0-1'}}/{{estimate_item.rating.totalRating}} )</span>
            </div>
            <p class="text-size-14 pb-1"><span class="brand-color has-text-weight-bold"> {{estimate_item.distance | number :
              '1.2-2'}} Km</span> - {{estimate_item.state}}</p>
              <div class="columns is-multiline is-flex m-0 is-hidden-tablet ">
                  <img *ngIf="estimate_item.network_name === 'Bosch Car Service'" class="ml-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 40px; height: 35px;">  
                  <img *ngIf="estimate_item.tse_quality_checked === '1'" class="ml-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 37px;">
                  <img *ngIf="estimate_item.exhaust_emission_service === 'yes'" class="ml-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 85px; width: 85px; height: 36px;">
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4 is-hidden-mobile">
      <span class="close-wrapper text-size-14">
        <span *ngIf="estimate_item.openStatus" style="color:green">{{ 'COMMON.OPEN' | translate }} - </span>
        <span class="closed" *ngIf="!estimate_item.openStatus">{{ 'COMMON.CLOSED' | translate }}</span>
        <span class="ml-1" *ngIf="estimate_item.openStatus">{{estimate_item.closeTime}} {{ 'COMMON.CLOSE' | translate
          }}</span>
      </span>
        <div class="columns is-multiline is-flex is-justify-content-end m-0">
          <div *ngIf="estimate_item.network_name === 'Bosch Car Service'" class="column is-3-desktop is-4-tablet p-0">
            <img class="ml-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 40px; height: 35px;">  
            <!-- <img class="ml-1 my-1" src="../../../assets/img/bosch_service.png" alt="" style="width: 40px; height: 35px;">   -->

          </div>
          <div *ngIf="estimate_item.tse_quality_checked === '1'" class="column is-3-desktop is-4-tablet p-0">
            <img class="ml-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 37px;">
          </div>
          <div *ngIf="estimate_item.exhaust_emission_service === 'yes'" class="column is-narrow-desktop is-4-tablet p-0">
            <!-- <img class="ml-1 my-1" src="../../../assets/img/emission.png" alt="" style="width: 40px; height: 35px;"> -->
            <img class="ml-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 85px; width: 85px; height: 36px;">

          </div>
        </div>
    </div>
  </div>
  <div class="features-wrapper columns mb-0 pb-1 is-multiline pl-3">
    <div class="features-inner pt-2" *ngFor="let item of estimate_item?.amenities?.slice(0, amenitiesLength) index as i;">
      <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center">
        <span class="icon column p-0 is-narrow">
          <img class="image is-24x24 garage-facility" src="{{item.logo_url}}" />
        </span>
        <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
      </div>
    </div>
    <div class="is-clickable more pt-3" *ngIf="estimate_item?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimate_item?.amenities?.length" (click)="amenitiesLength = estimate_item?.amenities?.length">+{{estimate_item?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
    <div class="is-clickable more pt-3" *ngIf="estimate_item?.amenities?.length === amenitiesLength && estimate_item?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}..</div>
  </div>

  <div class="features-wrapper columns mb-0 pb-1 is-multiline pl-3 pt-2">
    <div class="features-inner pt-2" *ngFor="let item of estimate_item?.specialization.slice(0, specializationLength) index as i;">
      <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
        <!-- <span class="icon column p-0 is-narrow">
          <img class="image is-24x24 garage-facility" src="{{item.logo_url}}" />
        </span> -->
        <span class="text-size-12 px-3 py-1 m-0 pt-2" style="line-height: 1.2;">{{ item.name }}</span>
      </div>
    </div>
    <div class="is-clickable more pt-3" *ngIf="estimate_item?.specialization.length > defaultSpecializationCount && specializationLength !== estimate_item?.specialization?.length" (click)="specializationLength = estimate_item?.specialization?.length">+{{estimate_item?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
    <div class="is-clickable more pt-3" *ngIf="estimate_item?.specialization?.length === specializationLength && estimate_item?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}..</div>
  </div>

  <div class="pt-1">
    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
      <div class="total-price">
        <p class="text-size-18 has-text-weight-bold color-arabama-green pr-2">{{"COMMON.TOTAL_PRICE" | translate }}</p>
        <p class="has-text-weight-medium text-size-14 mr-3 mt-1 diagonal-strikethrough" *ngIf="estimate_item.invoice_quote.total !== estimate_item.original_price"> {{estimate_item.original_price}}</p>
        <p class="text-size-18 has-text-weight-bold">{{estimate_item.invoice_quote.total}}</p>

      </div>
      <div class="buttons">
          <button class="button button_padding has-text-weight-semibold" (click)="openViewDetails()">{{ 'COMMON.VIEW_DETAILS' | translate
            }}</button>
          <button class="button is-success button_padding has-text-weight-semibold" (click)="nextStepper(estimate_item)">{{
            'COMMON.BOOK_APPOINTMENT' | translate }}</button>
          <!-- <button class="button button_padding" (click)="openViewDirection()">{{ 'COMMON.DIRECTION' | translate
            }}</button> -->
      </div>
    </div>
  </div>
</div>
<!-- <app-message *ngIf="show_message" [estimation]="estimate_item" [quoteId]="quoteId" (hideMessage)="hideMessagePopup($event)"></app-message> -->
<!-- <div *ngIf="show_popup" class="modal fade is-active">
  <div class="modal-card">
      <div class="modal-card-body py-5">
        <div class="has-text-centered pb-4 has-text-weight-bold">
          {{"DASHBOARD.WOULD_YOU_LIKE_TO_CHAT_WITH_GARAGE" | translate}} <br> {{"DASHBOARD.PLEASE_SAVE_THE_ESTIMATION" | translate}}
        </div>
        <div class="has-text-centered">
          <div class="button btn_cancel has-text-weight-semibold m-2" (click)="show_popup = false">
            {{'DASHBOARD.CANCEL' | translate}}
          </div>
          <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="saveEstimate()">
            {{'DASHBOARD.SAVE' | translate}}
          </div>
        </div>
      </div>
  </div>
</div> -->


