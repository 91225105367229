<div class="content block-padding is-fullwidth mt-3 pt-6">
    <!-- <p class="has-text-centered text-size-14 mb-0" style="color: #FFFFFFC9; letter-spacing: 1px;">YALNIZCA 4 KOLAY ADIMDA HİZMETİNİZİ TAMAMLAYIN</p> -->
    <h3 class="mt-0 is-flex is-justify-content-center is-size-3-desktop is-size-4-mobile is-size-4-tablet has-text-left pa-5 ml-5 mr-5 mb-1">
      {{ "HOME.TEXT_7" | translate }}.com{{"HOME.TEXT_7_1" | translate }}
    </h3>
    <div class="columns my-6" >
      <div class="column px-5 is-3 has-text-centered">
        <img src="../../../assets/svg/how_driver_1.svg" alt="" style="width: 65px;">
        <p class="pt-5 has-text-weight-bold text-size-22">1. Talebini ve araç bilgilerini paylaş</p>
        <p class="text-divider"></p>
        <p class="step-content">Uygun teklifler için; ihtiyacın olan servis hizmeti, arabanın bilgileri ve servis aradığın bölgeyi belirterek talebini oluştur.</p>
      </div>
      <div class="column px-5 is-3 has-text-centered">
        <img src="../../../assets/svg/how_driver_2.svg" alt="" style="width: 90px; height: 75px;">
        <p class="pt-2 has-text-weight-bold text-size-22">2. Teklifleri karşılaştır ve servisi seç</p>
        <p class="text-divider"></p>
        <p class="step-content">Teklifler arasından; fiyat, hizmet kalitesi ve müşteri yorumlarını karşılaştırarak servisini seç.</p>
      </div>
      <div class="column px-5 is-3 has-text-centered">
        <img src="../../../assets/svg/how_driver_3.svg" alt="" style="width: 53px; height: 70px;">
        <p class="pt-3 has-text-weight-bold text-size-22">3. Randevunu oluştur ve takipte kal</p>
        <p class="text-divider"></p>
        <p class="step-content">Seçtiğin servisten sana en uygun zaman için online randevunu oluştur.</p>
      </div>
      <div class="column px-5 is-3 has-text-centered">
        <img src="../../../assets/svg/how_driver_4.svg" alt="" style="width: 68px; height: 70px;">
        <p class="pt-3 has-text-weight-bold text-size-22">4. Garantili hizmetini al ve fikirlerini paylaş</p>
        <p class="text-divider"></p>
        <p class="step-content">Servis hizmetini arabamaservis.com güvencesi ile al, ödemeyi servise yap. Memnuyetine göre yorum bırak.</p>
      </div>
    </div>
    <div class="has-text-centered pt-4 pb-6">
      <button class="white-btn py-2">
        <p class="has-text-weight-bold">Kampanyalara gözat</p>
      </button>
      <button class="green-btn py-2 is-clickable" (click)="goToServices()" >
        <p class="has-text-weight-bold">Hemen teklif iste</p>
      </button>
    </div>
</div>
