<div class="columns m-0 is-mobile">
    <div class="column is-8 px-0" style="align-items: center;display: flex;">
        <button class="button back_button" (click)="onBackPressed()">
          <span class="icon is-small is-left"  style="height: 60px; color: #fff;">
            <i class="fas fa-arrow-left"></i>
          </span>
        </button>
        <span class="has-text-weight-bold ml-4 is-size-6-mobile	" style="font-size: 1.3rem;">
            {{'PROFILE.VEHICLE' | translate}}
        </span>
      </div>
      <div class="column is-4 px-0">
        <div class="add-vehicle" style="display: flex;justify-content: end;">
            <button *ngIf="!showAddVechile && vehcileList?.length > 0" (click)="clickAddVechile()" class="button is-normal is-success has-text-weight-bold pl-1" >
                <span class="icon is-medium mx-2">
                    <i class="fas fa-plus"></i>
                  </span>
                {{'SERVICE.TEXT_38' | translate}}
            </button>
            <button *ngIf="showAddVechile" (click)="clickAddVechile()" class="button is-normal is-success has-text-weight-bold" >
                {{'PROFILE.CANCEL' | translate}}
            </button>
    </div>
      </div>
</div>
<div class="container-wrapper px-0 pb-4">
    <div class="exist-veh mt-0 w-bg-with-border-radius is-justify-content-space-around pb-0">
        <div class="m-5 ml-3" *ngIf="!vehcileList && !isDataLoading && !showAddVechile">
            <!-- <p style="color: #838383 !important;">{{'PROFILE.NO_VEHICLES_FOUND' | translate}}</p> -->
            <div class="colums">
                <div class="column is-12 has-text-centered p-0">
                    <div class="no-envents is-flex is-flex-direction-column is-justify-content-center is-align-content-space-between">
                        <div class="mb-2 pt-2 is-flex-tablet is-justify-content-space-between is-align-items-center px-3" >
                            <div class="is-flex is-align-items-center">
                                <div class="appoiment-img is-flex is-justify-content-center">
                                    <img class="image" src="../../../assets/svg/vehicle.svg">
                                </div>
                                <div>
                                    <p class="text-size-20 my-3 mx-6 content" style="color:#a3a3a3">{{'PROFILE.NO_VEHICLES_FOUND' | translate}}</p>
                                </div>
                            </div>
                           
                            <!-- <img class="" src="../../../assets/img/estimate.png" > -->
                            <button (click)="clickAddVechile()" class="button is-normal is-success has-text-weight-bold pl-1">
                                <span class="icon is-medium mx-2">
                                    <i class="fas fa-plus"></i>
                                  </span>
                                {{'SERVICE.TEXT_38' | translate}}
                            </button>
                        </div>
                       
                        <div>
                            
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 435px;">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div *ngIf="!showAddVechile && vehcileList?.length != 0" class=" padding-20">
            <div class="estimates mt-0 vehcile-box" *ngFor="let item of vehcileList;index as i">
                <app-existing-vehcile-line [vehcileItem]="item" (fetchVehcileEmit)="receiveFetchVehcile()"></app-existing-vehcile-line>
            </div>
        </div>
        <div *ngIf="showAddVechile">
                <app-service-request-vehicle (sendMsgToProfile)="getStatusFromProfile($event)" [isFromProfile]="true"></app-service-request-vehicle>
        </div>
    </div>
</div>
