import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-map-estimate',
  templateUrl: './map-estimate.component.html',
  styleUrls: ['./map-estimate.component.scss'],
})
export class MapEstimateComponent implements OnInit {
  @Output() sendViewDetailsChild = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickViewDetails(){
    this.sendViewDetailsChild.emit()
  }
}
