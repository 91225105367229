import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(40%)' }), // Start invisible and off-screen
        animate('0.7s ease-in-out', style({ opacity: 1, transform: 'translateX(0%)' })), // Slide in and become visible
      ]),
      transition(':leave', [
        animate('0.7s ease-in-out', style({ opacity: 0, transform: 'translateX(100%)' })), // Slide out and become invisible
      ]),
    ])
  ],
})
export class JobDetailsComponent implements OnInit {
  @Input() stepperCount: any;
  @Output() closePopup = new EventEmitter<boolean>();

  constructor(private sharedService: SharedServiceService, private route: Router, private passService: PassDataService) { }

  ngOnInit(): void {
    // if (history.state != undefined) {
    //   if (history.state.stepperCount) {
    //       this.stepperCount = history.state.stepperCount
    //   }
    // }
  }
  // onClickClose() {
  //   localStorage.removeItem(Constants.APP.SERVICE_LIST)
  //   localStorage.removeItem("address")
  //   localStorage.removeItem("zipcode")
  //   sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
  //   sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
  //   sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
  //   sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
  //   sessionStorage.removeItem(Constants.APP.QUOTE_ID)
  //   this.sharedService.clearData()
  //   document.body.classList.remove('inner-stepper');
  //   let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
  //   if (userdata != undefined) {
  //     this.route.navigate(['/dashboard'])
  //   } else {
  //     this.route.navigate(['/home'])
  //   }
  // }
  backPressed(){
    this.closePopup.emit(false)
    // if(this.stepperCount === 1){
    //   this.route.navigate(['service/services'], {state:{fromJobDetails : true}})
    // } else if (this.stepperCount === 2){
    //   this.route.navigate(['service/vehicle'])
    // } else if (this.stepperCount === 3){
    //   this.route.navigate(['service/estimates'])
    // } else if (this.stepperCount === 4){
    //   this.route.navigate(['service/book'])
    // } else if (this.stepperCount === 5){
    //   this.route.navigate(['service/confirm'])
    // } else {
    //   this.route.navigate(['dashboard'])
    // }
  }
}
