import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'turkeyCurrency'
})
export class TurkeyCurrencyPipe implements PipeTransform {

  transform(value: number, locale: string = 'tr'): string {
    let input: string = '';
    if (typeof value !== 'string') {
      input = value.toString();
    }else{
      input = value
    }
    let [integer, decimal] = input.split('.');
    let containComma = input.includes('.')
    // Remove all dots from integer part before adding thousand separators
    integer = integer.replace(/\./g, '');
    // Add dots as thousand separators
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Return formatted value with comma as decimal separator
    return `${integer}${containComma? ',': ''}${decimal ? decimal.slice(0,2) : ''}`;
  }
}
