import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-quote-view',
  templateUrl: './map-quote-view.component.html',
  styleUrls: ['./map-quote-view.component.scss']
})
export class MapQuoteViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
