import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comments-line-item',
  templateUrl: './comments-line-item.component.html',
  styleUrls: ['./comments-line-item.component.scss']
})
export class CommentsLineItemComponent implements OnInit {
  public form: FormGroup;
  constructor(private fb: FormBuilder, private translate: TranslateService){
    
    this.form = this.fb.group({
      rating: ['3.5', Validators.required],
      rating2: [3]
    })
  }

  ngOnInit(): void {
  }

}
