import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { Utils } from 'src/app/common/utils';
import { Router } from '@angular/router';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Constants } from 'src/app/Constants/constants';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  requestTypes = ["Login", "Training", "Change Request", "Others"]
  help_data:any;
  help = new FormGroup({
    email : new FormControl("",[Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]),
    request_type : new FormControl("Others"),
    remarks : new FormControl("",Validators.required)
  });
  submitted : boolean = false;
  userData: any;
  isEmailActive:boolean = false;
  isRemarksActive:boolean = false;


  constructor(private bookingService : BookingServiceService, private utils : Utils, private route: Router, private translate :TranslateService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA) || '{}')
    if (this.userData.user_id !== undefined) {
      this.help.get('email')?.setValue(this.userData.email || ''); 
      this.isEmailActive = true;      
    }
    else{   
      this.isEmailActive = false;
    }
  }
  onFocus(field: string) {      
    if(field === 'email'){
      this.isEmailActive = true;
    }
    else if(field === 'remarks'){
      this.isRemarksActive = true;
    }

  }
  
  onBlur(field: string) {      
    if(field === 'email'&& !this.help.get('email')?.value){
      this.isEmailActive = false;
    }
    else if(field === 'remarks'&& !this.help.get('remarks')?.value){
      this.isRemarksActive = false;
    }
  }
  helpFunction(){
    this.submitted = true
    if(this.help.valid && this.help.get('remarks')?.value.trim().length !=0){
      document.getElementById('btnVerify')?.classList.add('is-loading')
      let user_data = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA)|| "{}");
      if(user_data.user_id){
      this.help_data = { ...this.help.value, status: "Pending",created_by: user_data.user_id}
    }else{
      this.help_data = { ...this.help.value, status: "Pending"}
    }
    this.bookingService.addTickets(this.help_data).subscribe({
      next: (res:any)=>{
        document.getElementById('btnVerify')?.classList.remove('is-loading')
        this.utils.openSuccessSnackBar(this.translate.instant(res.message))
        user_data.user_id? this.route.navigate(['/dashboard']):this.route.navigate(['/auth'])
      }, error:(err:any)=>{
        console.log(err)
        document.getElementById('btnVerify')?.classList.remove('is-loading')
      }
    }) 
  }
  
  }
  faq(){
    this.route.navigate(['/faq'])
  }
}


