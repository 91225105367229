import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-estimate-tab',
  templateUrl: './estimate-tab.component.html',
  styleUrls: ['./estimate-tab.component.scss']
})
export class EstimateTabComponent implements OnInit {

  @Input() estimate_item: any = {}
  @Input() dialog: any;
  @Input() quotation: any;
  @Input() fromMap: boolean = false;
  itemList: any = [];
  estimationList: any = [];
  finalPrice: any;
  finalPrice_currency: any;
  currentStep: number = -1;
  currentId: any;
  servicesList: any = [];
  checked:boolean = true;
  total_meta_data: any = {
    pre_subTotal: '0',
    pre_total: '0',
    pre_discountSubTotal: '0',
    eco_subTotal: '0',
    eco_total: '0',
    eco_discountSubTotal: '0'
  }
  total_price: number = 0;
  show_message : boolean = false; 
  show_popup: boolean = false; 
  quoteId: any;
  userDetails: any;
  total:any;
  discountedValue: any;
  discountedType :any;

  
  constructor(private sharedService: SharedServiceService,
    private route: Router) { }

  ngOnInit(): void { 
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userDetails = JSON.parse(userdata)
    }
    // if(Number(this.quotation?.total_amount) > 0){     
    //   this.total_price = this.quotation.total_amount
    // } else {  
    //   this.total_price = this.estimate_item?.invoice_quote.total
    // }
    let TotalBaseValue = 0
    this.estimate_item.invoice_quote.items.forEach((price: any) => {
      TotalBaseValue += price.base_price
    })
    if(this.estimate_item?.quote){
      this.estimationList = this.estimate_item
      this.itemList = this.estimate_item.invoice_quote.items
      if(this.estimate_item.quote.meta == undefined){
        this.estimate_item.quote.meta= {
          pre_subTotal: Number(this.estimate_item.quote.pre_subtotal).toLocaleString('tr',{style: 'currency', currency: 'TRY'}) ,
          pre_discountSubTotal: Number(this.estimate_item.quote.pre_totalDiscount).toLocaleString('tr',{style: 'currency', currency: 'TRY'}) ,
          eco_subTotal: Number(this.estimate_item.quote.eco_subtotal).toLocaleString('tr',{style: 'currency', currency: 'TRY'}) ,
          eco_discountSubTotal: Number(this.estimate_item.quote.eco_totalDiscount).toLocaleString('tr',{style: 'currency', currency: 'TRY'})
          
        }
      }
      this.total_meta_data.pre_total =this.estimate_item.quote.pre_total
      this.total_meta_data.pre_subTotal =this.estimate_item.quote.meta.pre_subTotal
      this.total_meta_data.pre_discountSubTotal =this.estimate_item.quote.meta.pre_discountSubTotal
      this.total_meta_data.eco_total =this.estimate_item.quote.eco_total
      this.total_meta_data.eco_subTotal =this.estimate_item.quote.meta.eco_subTotal
      this.total_meta_data.eco_discountSubTotal =this.estimate_item.quote.meta.eco_discountSubTotal

      this.finalPrice = 0
      if(this.itemList?.length > 0){
        this.itemList.forEach((element: any) => {
          if(this.quotation){
            if(element.estimation_type == "Premium"){
             
              this.finalPrice = this.finalPrice + element.pre_item_total;
              element.premium_checked = true;
              element.economic_checked = false;
            }
            else if(element.estimation_type == "Economy"){
             
              this.finalPrice = this.finalPrice + element.eco_item_total;
              element.premium_checked = false;
              element.economic_checked = true;
            }
          }
          else{
            this.finalPrice = this.finalPrice + element.pre_item_total;
          element.premium_checked = true;
          element.economic_checked = false;
          }
          element.original_price = element.base_price
          // if(element.discount_unit != null){
          //   if(element.discount_unit === 'amount'){
          //     element.base_price -= element.discount_value
          //   } else if (element.discount_unit === 'percentage'){
          //     element.base_price -= (element.base_price / 100) * element.discount_value
          //   }
          // }else{
          //   if(element.discounts[0]?.unit === 'amount'){
          //   element.base_price -= element.discounts[0]?.discount_value
          //   } else if (element.discounts[0]?.unit === 'percentage'){
          //     element.base_price -= (element.base_price / 100) * element.discounts[0]?.discount_value
          //   }
          // }
          if(element.discount_value != null){
            this.discountedValue = element.discount_value
            this.discountedType = element.discount_type
          }
          else if(element.discount_value == null){
            this.discountedValue = this.estimate_item.invoice_quote.bestDiscountPrice
            this.discountedType = this.estimate_item.invoice_quote.bestDiscountType
          }
      
          this.total_price = TotalBaseValue - this.discountedValue          
          this.total_price = this.total_price > 0 ? this.total_price : 0
          this.total = this.total_price .toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
   
          // if(this.quotation){
          //   if(element.discount_unit === 'amount'){
          //     element.base_price -= element.discount_value
          //   } else if (element.discount_unit === 'percentage'){
          //     element.base_price -= (element.base_price / 100) * element.discount_value
          //   }
          // } else {
          //   if(element.discounts[0]?.unit === 'amount'){
          //     element.base_price -= element.discounts[0]?.discount_value
          //   } else if (element.discounts[0]?.unit === 'percentage'){
          //     element.base_price -= (element.base_price / 100) * element.discounts[0]?.discount_value
          //   }
            
          // }
          

          if(element.meta == undefined){
            let pre_discount_price = 0
            let eco_discount_price = 0
            if(element.discount > 0){
              pre_discount_price = Number(element.pre_base_price) - Number(element.pre_item_total)
              eco_discount_price = Number(element.eco_base_price) - Number(element.eco_item_total)
            }
            element.meta = {
              pre_base_price_currency : Number(element.base_price) ,
              pre_extended_price_currency: Number(element.pre_extended_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}),
              pre_discount_price_currency: Number(pre_discount_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}),
              eco_base_price_currency : Number(element.eco_base_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}) ,
              eco_extended_price_currency: Number(element.eco_extended_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}),
              eco_discount_price_currency: Number(eco_discount_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'})
            }
          }
         
          this.getLocalServiceList(element.service_id,element)
        });
        
      }

      this.finalPrice_currency = Number(this.finalPrice).toLocaleString('tr',{style: 'currency', currency: 'TRY'})

      this.estimationList.quote.total = this.finalPrice_currency;
     this.estimationList.quote.finalPrice = this.finalPrice
    //  this.estimationList.quote.estimation_type = "Premium" 
    }
  }
  saveEstimate(){
    this.route.navigate(['/auth'], { state: { isFromEstimateValue: true,message:true } })
    // this.sharedService.saveEstimation()
  }
  getLocalServiceList(id:number,elementItem:any){
    var services = localStorage.getItem(Constants.APP.SERVICE_LIST);
    if(services!="" && services!='null' && services!=undefined){
      let localData = JSON.parse(services);
      localData.forEach((element: any) => {
        if(Number(id) === Number(element.answer.id)){
          elementItem.service_item = element.question.answer +'-'+ element.answer.answer
          this.servicesList.push(element)
        }
      });
     }
  }

  nextStepper(item:any) {
    // console.log(item);
    this.sharedService.updateData('4');
    // this.passDataService.updateEstimateData(item)
    sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_DETAILS,JSON.stringify(item));
    this.sharedService.setEstimateData(item)
    // this.dialog.close()
    this.route.navigate(['/service/book'])
  }

  priceChange(estimation_type:any,data:any){
    let premium = data.pre_item_total
    let economy = data.eco_item_total
   
    if(estimation_type == "premium" && data.premium_checked == false){
      data.premium_checked = true;
      data.economic_checked = false;
      this.finalPrice = this.finalPrice + premium - economy;
    }
    else if(estimation_type == "economy" && data.economic_checked == false){
      data.premium_checked = false;
      data.economic_checked = true;
     
      this.finalPrice = this.finalPrice + economy - premium;
    }
    this.finalPrice_currency = Number(this.finalPrice).toLocaleString('tr',{style: 'currency', currency: 'TRY'})  
    
    this.estimationList.quote.total = this.finalPrice_currency;
    this.estimationList.quote.finalPrice = this.finalPrice

  
    // console.log(this.finalPrice);
   
  }

  setStep(step:any){
    this.currentStep = step;
    }

    checkboxChange(check:any,data:any){
      let premium = data.pre_item_total
      let economy = data.eco_item_total
      // console.log(data);
      if(check == 'premium_checkbox'){
          if(data.premium_checked ==  true){
            data.premium_checked = false;
            data.economic_checked = true; 
            this.finalPrice = this.finalPrice + economy - premium;  
            this.estimationList.quote.estimation_type = "Economy"
            if(this.itemList?.length > 0){
              this.itemList.forEach((element: any) => {
                if(element.service_id == data.service_id){
                  element.estimation_type = "Economy"
                }
              })
            }

          }
          else{
            data.premium_checked = true;
            data.economic_checked = false;   
            this.finalPrice = this.finalPrice + premium - economy;
            this.estimationList.quote.estimation_type = "Premium"
            if(this.itemList?.length > 0){
              this.itemList.forEach((element: any) => {
                if(element.service_id == data.service_id){
                  element.estimation_type = "Premium"
                }
              })
            }

          }
      }
      else{
        if(data.economic_checked ==  true){
          data.premium_checked = true;
          data.economic_checked = false; 
          this.finalPrice = this.finalPrice + premium - economy; 
          this.estimationList.quote.estimation_type = "Premium"   
          if(this.itemList?.length > 0){
            this.itemList.forEach((element: any) => {
              if(element.service_id == data.service_id){
                element.estimation_type = "Premium"
              }
            })
          }
   
        }
        else{
          data.premium_checked = false;
          data.economic_checked = true;   
          this.finalPrice = this.finalPrice + economy - premium; 
          this.estimationList.quote.estimation_type = "Economy" 
          if(this.itemList?.length > 0){
            this.itemList.forEach((element: any) => {
              if(element.service_id == data.service_id){
                element.estimation_type = "Economy"
              }
            })
          }

        }

      }
      this.finalPrice_currency = Number(this.finalPrice).toLocaleString('tr',{style: 'currency', currency: 'TRY'}) 
     
      
      this.estimationList.quote.total = this.finalPrice_currency;
      this.estimationList.quote.finalPrice = this.finalPrice

    }
    messagePopup(){
      this.quoteId = this.estimate_item.quote_id
      if(this.userDetails){
        this.show_message = true
      } else {
        this.show_popup = true
      }
    }
    hideMessagePopup(ev: any){
      this.show_message = ev;
      this.estimate_item.msg_notification_count = 0
    }

}


interface Estimate {
  latitude?: string;
  longitude?: string;
  locationUrl?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
  timings?: [{
    id?: number,
    day_of_week?: number | 0,
    start_time?: string,
    end_time?: string,
    time?: string,
    day?: string
  }];
  quote?:{
    items ?: [Item] | [],
    pre_totalDiscount?: number | 0,
    pre_subtotal?: number | 0,
    pre_total?: number | 0,
    eco_totalDiscount?: number | 0,
    eco_subtotal?: number | 0,
    eco_total?: number | 0,
    total?: string
    meta?:{
      pre_total?: string,
      pre_subTotal?: string,
      pre_discountSubTotal?: string,
      eco_total?: string,
      eco_subTotal?: string,
      eco_discountSubTotal?: string
    }
  }
}
interface Item{
  service_id?: string,
  garage_id?: string,
  vechicle_id?: string,
  pre_base_price?: string,
  pre_extended_price?: string,
  pre_discount?: string,
  eco_base_price?: string,
  eco_extended_price?: string,
  eco_discount?: string
  qty?: number | 0,
  item_pre_total?: number | 0,
  item_eco_total?: number | 0
  meta ?:{
    pre_base_price_currency?: number,
    pre_extended_price_currency?: string,
    pre_discount_price_currency?: string,
    eco_base_price_currency?: string,
    eco_extended_price_currency?: string,
    eco_discount_price_currency?: string
  }

}
