 
<div class="container-wrapper pt-6"> 
    <div class="mt-3 w-bg-with-border-radius is-justify-content-space-around"> 
        <div class="colums">
            <div class="column is-3">
                <button class="button-cta">  
                    Add Payment Method
                </button> 
            </div>
        </div>
       
    </div>
</div>