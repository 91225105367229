<div class="column relative is-flex is-justify-content-center p-0">
  <img class="profile-image" *ngIf="profile_img" src="{{ profile_img }}" alt="" />
    <img class="profile-image" *ngIf="!profile_img" src="../../../assets/svg/profile_avatar.png" alt="" />
    <div class="change-profile">
      <div (click)="viewProfile()">
        <img class="image is-24x24" src="../../../assets/svg/camera.svg" />
      </div>
    </div>
  </div>
  <div [ngClass]="view_profile ? 'modal is-active' : 'is-hidden'">
    <div class="modal-card">
      <div class="modal-card-body">
        <div class="columns is-flex m-0">
          <div class="column is-flex is-align-items-center pt-1">
            <p class="is-size-5 has-text-weight-semibold">{{'PROFILE.SELECT_A_PHOTO' | translate}}</p>
          </div>
          <div> 
            <button (click)="hideProfile()"
              class="delete is-large column pt-1 is-size-3 is-clickable is-flex is-justify-content-end">
            </button>
          </div>
        </div>
          <div class="columns m-0 pt-3 is-flex is-justify-content-center">
            <div class="column is-6 is-8-mobile profile-field p-0">
              <img *ngIf="profile_img" src="{{ profile_img }}" alt="" />
              <img *ngIf="!profile_img" src="../../../assets/svg/profile_avatar.png" alt="" />
            </div>
          </div>
        <div class="mt-4">
          <form #myForm="ngForm" (ngSubmit)="uploadGarageImage()">
            <div class="file has-name column is-flex is-justify-content-center">
              <label class="file-label">
                <input type="file" class="file-input" accept=".png,.jpg,.jpeg" name="myImage"
                  (change)="onImageSelected($event)">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">
                    {{'PROFILE.CHOOSE_A_FILE' | translate}}
                  </span>
                </span>
                <span class="file-name-1">
                  <input type="text" *ngIf="imgname" class="input is-small" placeholder=" {{imgname}}" readonly>
                </span>
              </label>
            </div>
            <div class="column is-flex is-justify-content-end p-0">
              <button class="button is-medium has-text-weight-semibold is-size-6" [disabled]="!selectedImage" [ngClass]="selectedImage ? 'is-success' : 'button-disabled'" >{{'PROFILE.UPLOAD' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>