<nav class="navbar">
    <div class="navbar-brand is-pa-l-10">
      <img class="is-clickable" src="assets/img/arabama.png" width="150" height="40"  routerLink="/home"/>
    </div>
    <div class="stepper-nav">
      <!-- <div class="container"> -->
        <div class="navbar-item">
          <app-stepper style="min-width:100%;" [activeStepper]="stepperCount"></app-stepper>
        </div>
      <!-- </div> -->
    </div>
    <div class="navbar-end">
      <a class="navbar-item mr-6">
        <button class="button is-success" (click)="onClickClose()">
          <span class="icon is-small">
            <i class="fas fa-close"></i>
          </span>
        </button>
      </a>
    </div>
  </nav>
<div class="quote-content" [@slideInOut]>
    <div class="columns ps-relative m-0 is-centered pb-2">
        <div class="column is-12-mobile is-12-tablet is-10-desktop p-0">
          <div class="">
            <div class="content is-flex is-align-items-center px-3 pt-2">
                <button class="button back_button" (click)="backPressed()">
                    <span class="icon is-small is-left" style="height: 60px; color: #fff; ">
                      <i class="fas fa-arrow-left"></i>
                    </span>
                </button>
              <h3 class="pl-3 is-size-6-mobile my-0">{{"COMMON.QUOTE_DETAILS" | translate}}</h3>
            </div>
          </div>
        </div>
      </div>  
      <div class="columns m-0 is-centered is-multiline mx-3">
          <div class="column is-12-mobile is-12-tablet is-3-desktop" style="background-color: #ffffff; border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
            <div class="" style="background-color: #eeeeee; border-radius: 5px; height: 100%;">
              <div class="content p-3 pt-0">
                <div class="is-flex-mobile is-flex-tablet-only">
                  <div class="profile-img">
                    <img [src]="image" class="image">
                  </div> 
                  <div class="pl-2">
                    <div class="is-size-6-mobile is-size-5-tablet has-text-weight-semibold">{{estimateItem.name}}</div>
                  <div class="star-rating">
                    <star-rating [value]=estimateItem.rating.cum_rating [totalstars]="5"
                      checkedcolor="#AAD043" uncheckedcolor="#C7C6C6" size="23px" [readonly]="true"></star-rating>
                    <span *ngIf="estimateItem.rating.cum_rating" class="rating-count text-size-13 mt-7 text-size-13">({{estimateItem.rating.cum_rating | number:
                      '1.0-1'}} /{{estimateItem.rating.totalRating}} )</span>
                  </div>
                  <div class="is-size-6 pt-1 pb-1" style="color: #4a4a4a;"><span> <strong>{{estimateItem.distance | number :
                        '1.2-2'}} km</strong></span> - {{estimateItem.state}}</div>
                  </div>
                  
                </div>
                <div class="features-wrapper columns is-multiline pl-3 pt-1 my-0">
                  <div class="features-inner pb-1"
                    *ngFor="let item of estimateItem?.amenities?.slice(0, amenitiesLength) index as i;">
                    <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                        <img class="image is-24x24" src="{{item.logo_url}}" />
                      <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateItem?.amenities?.length" (click)="amenitiesLength = estimateItem?.amenities?.length">+{{estimateItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
                  <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length === amenitiesLength && estimateItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
                </div>
                <div class="features-wrapper columns mb-0 pb-1 is-multiline pl-3 pt-2">
                  <div class="features-inner pt-2" *ngFor="let item of estimateItem?.specialization?.slice(0, specializationLength) index as i;">
                    <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                      <span class="text-size-12 px-3 py-1 m-0 pt-2" style="line-height: 1.2;">{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length > defaultSpecializationCount && specializationLength !== estimateItem?.specialization?.length" (click)="specializationLength = estimateItem?.specialization?.length">+{{estimateItem?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
                  <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length === specializationLength && estimateItem?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}..</div>
                </div>
              </div>
            </div>
          </div>
          <div class=" column is-12-mobile is-7-desktop p-0" style="background-color: #ffffff; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
            <app-estimate-tab [estimate_item]="estimateItem" [quotation]="quotation"></app-estimate-tab>
          </div>
      </div>
</div>