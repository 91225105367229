import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket} from 'socket.io-client';
import * as io from 'socket.io-client'
import { Constants } from '../Constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket | undefined;
  private isConnected: boolean = false;
  userId!: string;

  constructor() {
    
  }

  connect(userId: string) {
    const userData = localStorage.getItem(Constants.APP.SESSION_USER_DATA);
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      this.userId = parsedUserData.user_id;
    }
    if (!this.isConnected && userId && userId != 'undefined') {
      // this.socket = io.connect(`http://localhost:5000?userId=${userId}`);
      // this.socket = io.connect(`https://adminnodedev.nisissolutions.com?userId=${userId}`);
      this.socket = io.connect(`https://assocket.nisissolutions.com?userId=${userId}`);
      this.isConnected = true;
    }
  }

  listen(eventname: string): Observable<any> {
    this.connect(this.userId); // Ensure connection before listening
    return new Observable((subscribe) => {
      this.socket?.on(eventname, (data: any) => {
        subscribe.next(data);
      });
    });
  }

  emit(eventname: string, data: any) {
    this.connect(this.userId); // Ensure connection before emitting
    this.socket?.emit(eventname, data);
  }

  ngOnDestroy() {
    this.disconnect(); // Disconnect when the service is destroyed
  }

   disconnect() {
    if (this.isConnected) {
      this.socket?.disconnect();
      this.isConnected = false;
      this.userId = ''
    }
  }
}
