import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { Router } from '@angular/router';
import { PassDataService } from 'src/app/services/pass-data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit,OnChanges {

  constructor(private Router:Router,private passServices: PassDataService) { }
  name:string = ''
  firstName:string = '';
  lastName: string = ''
  index: number = 0;
  ngOnInit(): void {
    document.body.classList.add('inner-class');
    document.body.classList.remove('inner-stepper'); 
    // document.body.classList.remove('sign-in');
    this.updateProfile()
    if(this.passServices.pageIndex == "service_history"){
      this.index = 3;
    }
    else if(this.passServices.pageIndex == "reviews"){
      this.index = 2;
    }
    else if(this.passServices.pageIndex == "car_parking"){
      this.index = 1;
    }
    else{
      this.index = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
  updateProfileInfo(){
    this.updateProfile()
  }
  onBackPressed(){
    this.Router.navigate(['/dashboard'])
  }

  clickOutside(){
    this.passServices.setOutsideClickListener();
}

  updateProfile(){
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if(userdata == undefined || userdata == null){
      this.Router.navigate(['/auth'])
    }else{
    if(userdata != undefined){
      let userDetails = JSON.parse(userdata)
      this.name = userDetails.first_name + " "+ userDetails.last_name
      this.firstName = userDetails.first_name;
      this.lastName = userDetails.last_name;
    }
    } 
  }

}
