import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-common-alert-dialog',
  templateUrl: './common-alert-dialog.component.html',
  styleUrls: ['./common-alert-dialog.component.scss']
})
export class CommonAlertDialogComponent implements OnInit {

  constructor(private Translate: TranslateService,public dialogRef: MatDialogRef<CommonAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  displayInfo: string = ''
  ngOnInit(): void {
    if (this.data != null) {
      let dataItem
      if(!this.data.dataItem.answer.answer){
        dataItem = this.data.dataItem.question.answer  
      }
      else{
        dataItem = this.data.dataItem.question.answer
      }
      this.Translate.get('ERROR_MESSAGE.error_delete_msg', { service_name: dataItem}).subscribe((res: string) => {
        this.displayInfo = res
      });
    }
  }

  cancelDialog() {
    this.dialogRef.close(0)
  }
  okDialog(){
    this.dialogRef.close(1)
  }

}
