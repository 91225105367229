<div class="pb-3 is-flex is-justify-content-space-between is-flex-direction-column" style="background-color: #ffffff; border-radius: 10px;" [ngClass]="fromMap ? '' : 'fromMap'">
    <!-- <h2 class="mb-2 mt-4 is-size-5">{{'COMMON.SERVICES' | translate}}</h2>
    <div *ngIf="servicesList.length > 0" class="pl-5 mr-3 service-list">
        <ul style="list-style: inside !important;" *ngFor="let item of servicesList; index as i">
            <li>{{item.question.answer}} - {{item.answer.answer}}</li>
        </ul>
    </div> -->
    <!-- <div *ngIf="servicesList.length == 0" class="pl-5 mr-3 service-list">
        <span>{{'ERROR_MESSAGE.error_no_services' | translate}}</span>
    </div> -->
    <!--
    <div *ngFor="let item of itemList;index as i">
        <div class="detail-line-items">
            <div class="columns m-0">
                <div class="column p-0 is-12-mobile is-6-desktop">
                    <span class="pt-1 pb-2 is-size-6" style="font-weight: bold;">{{item.service_item}}</span>
                    <p class="mb-2 pt-2" style="font-size: 1rem">{{'COMMON.NOTES' | translate}}:</p>
                    <p style="font-size: 1rem; color: rgb(64, 64, 64)"></p>
                </div>
                <div class="column is-12-mobile is-6-desktop">
                    <ul class="mb-2">
                        <li class="line-pricing">
                            <span class="line-item-label">{{'COMMON.TYPE' | translate}}:</span>
                            <span class="line-item-pricing">
                                <span class="">{{'COMMON.RETAIL_PRICE' | translate}}</span>
                            </span>
                        </li>
                        <li class="line-pricing">
                            <span class="line-item-label">{{'COMMON.QUANTITY' | translate}}:</span><span
                                class="line-item-pricing has-text-weight-bold">{{item.qty}}</span>
                        </li>
                        <li class="line-pricing">
                            <span class="line-item-label">{{'COMMON.PRICE' | translate}}:</span><span class="line-item-pricing">
                                &nbsp; <span class="has-text-weight-bold"> {{item.meta.base_price_currency}} </span>
                            </span>
                        </li>
                        <li class="line-pricing">
                            <span class="line-item-label">{{'COMMON.EXTENDED' | translate}}:</span><span class="line-item-pricing"><span
                                    class=""></span><span class="has-text-weight-bold"> {{item.meta.extended_price_currency}} </span></span>
                        </li>
                        <li class="line-pricing">
                            <span class="line-item-label">{{'COMMON.DISCOUNT_PRICE' | translate}}:</span><span class="line-item-pricing"><span
                                    class=""></span><span class="has-text-weight-bold"> {{item.meta.discount_price_currency}} </span></span>
                        </li>
                    </ul>
                </div>
            </div> 
        </div>
    </div>
    <div class="total">
        <div class="columns m-0">
            <div class="column">
                <span class="has-text-weight-bold">{{'COMMON.SUBTOTAL' | translate}}:</span>
                <span class="has-text-right has-text-weight-bold" style="float: right;" >{{total_meta_data.subTotal}}</span>
            </div> 
        </div>
        <div class="columns is-hidden m-0">
            <div class="column">
                <p>Taxes:</p>
            </div>
            <div class="column">
                <p class="has-text-right has-text-weight-bold">$1.30</p>
            </div>
        </div>
        <div class="columns m-0"> 
            <div class="column">
                <span class="has-text-left has-text-weight-bold">{{'COMMON.TOTAL_PRICE' | translate}}: </span>
                <span class="has-text-right"><span class="is-size-5" style="color: #aad043; float: right;">{{total_meta_data.total}}</span></span>
            </div>
        </div>
        <div class="columns m-0">
            <div class="column">
                <p style="font-size: 0.787rem;"> *{{'COMMON.ESTIMATE_TEXT' | translate}}</p>
            </div>
        </div>
    </div>
    -->
    <!-- <div class="columns is-hidden-mobile px-6">
        <div class="column is-6 py-0">
        </div>
        <div class="column py-0 pl-0" *ngIf="estimate_item.invoice_quote.estimation_type == 'Premium'"> 
            <h4 class="has-text-centered catagory-title pb-3 is-size-5" style="position: relative; left: -22px;">Premium</h4> 
        </div>
        <div class="column py-0 pl-0" *ngIf="estimate_item.invoice_quote.estimation_type == 'Economy'">
            <h4 class="has-text-centered catagory-title pb-3 is-size-5" style="position: relative; left: -22px;">Economy</h4>
            <p class="text-size-12 has-text-right mb-3 mr-5">Currency in Turkish Lira</p>
        </div>
    </div>   -->
    <div>
        <h2 class="is-size-5-tablet ml-6 pl-4 pt-3">{{'COMMON.ITEMIZED_ESTIMATE' | translate}}</h2>

        <div class="columns m-0 pb-5 itemized_estimate active pt-0 is-hidden-mobile" *ngFor="let item of itemList; let i = index">
            <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="w-100">
                <mat-expansion-panel-header class="pb-0">
                    <mat-panel-title class="is-justify-content-space-between"> 
                            <div class="column is-5 is-offset-1 text-size-16">
                                <p class="servicename"><span class="pr-2">{{i+1}}.</span>{{item.service_name}}</p>
                            </div>
                            <!-- <div [ngClass]="{active:item.premium_checked}"> -->
                            <div class="column is-6 px-1" *ngIf="estimate_item.invoice_quote.estimation_type == 'Premium'"> 
                                    <div [ngClass]="item.premium_checked?'catagory-price is-flex is-justify-content-end is-align-items-center active':'catagory-price is-flex is-justify-content-end is-align-items-center'">
                                        <p class="has-text-weight-medium text-size-14 mr-3 diagonal-strikethrough" *ngIf="item.meta.pre_base_price_currency !== item.original_price"> ₺&nbsp;{{item.original_price |  number:'1.2-2':'tr'}}</p>
                                        <p class="has-text-weight-bold text-size-16">₺&nbsp;{{(item.meta.pre_base_price_currency > 0? item.meta.pre_base_price_currency : 0) |  number:'1.2-2':'tr' }}</p>
                                        <!-- <p class="has-text-weight-bold text-size-20 mr-3">{{item.base_price}}</p> -->
                                        <!-- <label class="checkbox">
                                            <input type="checkbox" [checked]="item.premium_checked" (change)="checkboxChange('premium_checkbox',item)" [disabled]="quotation">
                                        </label>  -->
                                    </div> 
                            </div>
                        <!-- </div> -->
                            <div class="column is-6" *ngIf="estimate_item.invoice_quote.estimation_type == 'Economy'">
                                <div class="eco-wrap">
                                    <div [ngClass]="item.economic_checked?'catagory-price is-flex is-justify-content-space-between is-align-items-center active':'catagory-price is-flex is-justify-content-space-between is-align-items-center'">
                                        <p class="has-text-weight-bold text-size-20"> {{item.meta.eco_base_price_currency}}</p>
                                        <!-- <label class="checkbox">
                                            <input type="checkbox" [checked]="item.economic_checked" (change)="checkboxChange('economic_checkbox',item)" [disabled]="quotation">
                                        </label> -->
                                    </div>
                                </div>
                            </div> 
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="columns">
                    <div class="column is-6 mt-3 p-0">
                        <div class="pre-desc-wrapper">
                            <div class="description-wrapper">
                                <table class="table is-narrow w-100">
                                    <tbody>
                                        <tr *ngIf="item.spare_price">
                                            <td>{{'COMMON.SPARE_PARTS' | translate}}</td>
                                        </tr>
                                        <tr *ngIf="item.lubricant_price">
                                            <td>{{'COMMON.OILS_AND_LIQUIDS' | translate}}</td>
                                        </tr>
                                        <tr *ngFor="let add_item of item.offer_item">
                                            <td *ngIf="add_item">{{add_item.item_name}}</td>
                                        </tr>
                                        <tr *ngIf="item.labour_price">
                                            <td>{{'COMMON.WORKMANSHIP' | translate}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>{{'COMMON.OTHER' | translate}}</td>
                                        </tr> -->
                                        
                                        <tr>
                                            <td>{{'COMMON.DISCOUNT' | translate}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div [ngClass]="{active:item.premium_checked}"> -->
                    <div class="column is-6 pl-0" (click)=" !quotation? priceChange('premium',item): '' " *ngIf="estimate_item.invoice_quote.estimation_type == 'Premium'">
                        <div class="pre-desc-wrapper">
                            <div [ngClass]="item.premium_checked?'catagory-price-wrapper active':'catagory-price-wrapper'">
                                <!-- <div class="premium-price is-flex is-justify-content-space-between mt-4 is-align-items-center">
                                    <p class="has-text-weight-bold text-size-24">{{item.pre_total}}</p>
                                    <label class="checkbox">
                                        <input type="checkbox">
                                    </label>   
                                </div> -->
                                <table class="table is-narrow w-100">
                                    <tbody>
                                        <tr *ngIf="item.spare_price">
                                            <td>{{item.spare_brand | titlecase}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.spare_price | turkeyCurrency}}</td>
                                        </tr>
                                        <tr *ngIf="item.lubricant_price">
                                            <td>{{item.lubricant_brand | titlecase}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.lubricant_price | turkeyCurrency}}</td>
                                        </tr>
                                        <tr *ngFor="let add_item of item.offer_item">
                                            <td>{{add_item.item_brand}} </td>
                                            <td class="has-text-right has-text-weight-bold">{{add_item.item_price | turkeyCurrency}}</td>
                                        </tr>
                                        <tr *ngIf="item.labour_price">
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.labour_price | turkeyCurrency}}</td>
                                        </tr>

                                        <tr>

                                            <td> </td>
                                            <td *ngIf="item.discount_unit == null" class="has-text-right has-text-weight-bold">
                                                <span *ngIf="item.discounts && item.discounts[0]?.unit === 'amount'">-&nbsp;{{(item.discounts[0] ? item.discounts[0]?.discount_value : 0) | turkeyCurrency}} </span> 
                                               <span *ngIf="item.discounts && item.discounts[0]?.unit === 'percentage'">{{item.discounts[0] ? item.discounts[0]?.discount_value : 0}}&nbsp;%</span> 
                                               <span *ngIf="!item.discounts[0]?.unit">-</span>
                                            </td>
                                            <td *ngIf="item.discount_unit != null" class="has-text-right has-text-weight-bold">
                                                <span *ngIf="item.discount_unit === 'amount'">-&nbsp;{{(item.discount_value ? item.discount_value : 0) | turkeyCurrency}}</span> 
                                               <span *ngIf="item.discount_unit === 'percentage'">{{item.discount_value ? item.discount_value : 0}}&nbsp;%</span> 
                                               <span *ngIf="!item.discounts[0]?.unit && !item.discount_unit">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                <!-- </div> --> 
                    <div class="column is-6 pl-0" (click)=" !quotation? priceChange('economy',item) : '' " *ngIf="estimate_item.invoice_quote.estimation_type == 'Economy'">
                        <div class="eco-desc-wrapper">
                            <!-- <h4 class="has-text-centered eco-title pb-3 text-size-20">{{item.estimation_type2}}</h4> -->
                            <div [ngClass]="item.economic_checked?'catagory-price-wrapper active':'catagory-price-wrapper'">    
                                <!-- <div class="eco-price is-flex is-justify-content-space-between mt-4 is-align-items-center">
                                    <p class="has-text-weight-bold text-size-24"> {{item.eco_total}}</p>
                                    <label class="checkbox">
                                        <input type="checkbox">
                                    </label>
                                </div> -->
                                <table class="table is-narrow  w-100">
                                    <tbody>
                                        <tr>
                                            <td>{{item.eco_spare_name}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_spare_price}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{item.eco_lubricants_name}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_lubricants_price}}</td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_labour_price}}</td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_discount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> 
            </mat-expansion-panel> 
        </div>
        <!-- Mobile layout --> 
        <div class="is-hidden-desktop is-hidden-tablet" >
        <!-- <div class="columns" *ngIf="estimate_item.invoice_quote.estimation_type == 'Premium'">
            <div class="column is-4 pb-0">
            
            </div>         
            <div class="column is-4 pb-0"> 
                <h4 class="has-text-centered catagory-title pb-3 issize-6">Premium</h4> 
            </div>
        </div> -->
        <div *ngIf="estimate_item.invoice_quote.estimation_type == 'Premium'">
            <div class="columns m-0 pb-5 itemized_estimate active pt-0 estimate-quote-mobile" *ngFor="let item of itemList; let i = index">
                <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="width-95">
                    <mat-expansion-panel-header class="pb-0">
                        <mat-panel-title class="is-justify-content-space-between">
                            <div class="columns is-flex width-95 is-align-items-center mobile-panel-head">
                                <div class="column min-width-50">
                                    <p class="service-name service-name"><span class="pr-2">{{i+1}}.</span>{{item.service_name}}</p>
                                </div>
                                <div class="column min-width-50"> 
                                    <div [ngClass]="item.premium_checked?'catagory-price is-flex is-justify-content-end is-align-items-center active':'catagory-price is-flex is-justify-content-end is-align-items-center'">
                                        <p class="has-text-weight-medium text-size-12 mr-3 diagonal-strikethrough" *ngIf="item.meta.pre_base_price_currency !== item.original_price">₺&nbsp;{{item.original_price |  number:'1.2-2':'tr'}}</p>
                                        <p class="has-text-weight-bold text-size-20 mr-3">₺&nbsp;{{(item.meta.pre_base_price_currency > 0? item.meta.pre_base_price_currency: 0)|  number:'1.2-2':'tr'}}</p>
                                        <!-- <label class="checkbox">
                                            <input type="checkbox" [checked]="item.premium_checked" (change)="checkboxChange('premium_checkbox',item)" [disabled]="quotation">
                                        </label>  -->
                                    </div> 
                                </div>
                            
                            </div> 
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="columns is-flex">
                        <div class="column">
                            <div class="description-wrapper">
                                <table class="table is-narrow w-100">
                                    <tbody>
                                        <tr *ngIf="item.spare_price">
                                            <td>{{'COMMON.SPARE_PARTS' | translate}}</td>
                                        </tr>
                                        <tr *ngIf="item.lubricant_price">
                                            <td>{{'COMMON.OILS_AND_LIQUIDS' | translate}}</td>
                                        </tr>
                                        <tr *ngFor="let add_item of item.offer_item">
                                            <td *ngIf="add_item">{{add_item.item_name}}</td>
                                        </tr>
                                        <tr *ngIf="item.labour_price">
                                            <td>{{'COMMON.WORKMANSHIP' | translate}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>{{'COMMON.OTHER' | translate}}</td>
                                        </tr> -->                                       
                                        <tr>
                                            <td>{{'COMMON.DISCOUNT' | translate}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                       
                        <div class="column" (click)=" !quotation? priceChange('premium',item) : '' " style="padding: 0.75rem 0px;">
                            <div [ngClass]="item.premium_checked?'catagory-price-wrapper active':'catagory-price-wrapper'">
                                <table class="table is-narrow w-100">
                        
                                    <tbody>
                                        <tr *ngIf="item.spare_price">
                                            <td>{{item.spare_brand | titlecase}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.spare_price}}</td>
                                        </tr>
                                        <tr *ngIf="item.lubricant_price">
                                            <td>{{item.lubricant_brand | titlecase}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.lubricant_price}}</td>
                                        </tr>
                                        <tr *ngFor="let add_item of item.offer_item">
                                            <td>{{add_item.item_brand}} </td>
                                            <td class="has-text-right has-text-weight-bold">{{add_item.item_price | turkeyCurrency}}</td>
                                        </tr>
                                        <tr *ngIf="item.labour_price">
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.labour_price}}</td>
                                        </tr>     
                                        <tr>
                                            <td> </td>
                                            <td *ngIf="item.discount_unit == null" class="has-text-right has-text-weight-bold">
                                                <span *ngIf="item.discounts && item.discounts[0]?.unit === 'amount'">-&nbsp;{{(item.discounts[0] ? item.discounts[0]?.discount_value : 0) | turkeyCurrency}} </span>                                              
                                               <span *ngIf="item.discounts && item.discounts[0]?.unit === 'percentage'">{{item.discounts[0] ? item.discounts[0]?.discount_value : 0}}&nbsp;%</span> 
                                               <span *ngIf="!item.discounts[0]?.unit">-</span>
                                            </td>
                                            <td *ngIf="item.discount_unit != null" class="has-text-right has-text-weight-bold">
                                                <span *ngIf="item.discount_unit === 'amount'">-&nbsp;{{(item.discount_value ? item.discount_value : 0) | turkeyCurrency}}</span> 
                                                <span *ngIf="item.discount_unit === 'percentage'">{{item.discount_value ? item.discount_value : 0}}&nbsp;%</span> 
                                                <span *ngIf="!item.discounts[0]?.unit && !item.discount_unit">-</span>
                                            </td>
                                        </tr>                              
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                    </div> 
                </mat-expansion-panel> 
            </div>
        </div>
        
        <!-- <div class="columns" *ngIf="estimate_item.invoice_quote.estimation_type == 'Economy'">
            <div class="column is-4 pb-0">
            
            </div>
            <div class="column is-4 pb-0">
                <h4 class="has-text-centered catagory-title pb-3 is-size-6">Economy</h4>
                <p class="text-size-12 has-text-right mb-3 mr-5">Currency in Turkish Lira</p>
            </div>
        </div> -->
        <div *ngIf="estimate_item.invoice_quote.estimation_type == 'Economy'">
            <div class="columns m-0 itemized_estimate active pt-0" *ngFor="let item of itemList; let i = index">
                <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="width-95">
                    <mat-expansion-panel-header class="pb-0">
                        <mat-panel-title class="is-justify-content-space-between">
                            <div class="columns width-95 is-flex is-align-items-center mobile-panel-head">
                                <div class="column min-width-50">
                                    <p class="servicename"><span class="pr-2">{{i+1}}.</span>{{item.service_name}}</p>
                                </div>
                            
                                <div class="column min-width-50">
                                        <div [ngClass]="item.economic_checked?'catagory-price is-flex is-justify-content-space-between is-align-items-center active':'catagory-price is-flex is-justify-content-space-between is-align-items-center'">
                                            <p class="has-text-weight-bold text-size-20"> {{item.meta.eco_base_price_currency |  number:'1.2-2':'tr'}}</p>
                                            <!-- <label class="checkbox">
                                                <input type="checkbox" [checked]="item.economic_checked" (change)="checkboxChange('economic_checkbox',item)" [disabled]="quotation">
                                            </label>  -->
                                    </div>
                                </div>
                            </div> 
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="columns is-flex">
                        <div class="column mt-5">
                            <div class="description-wrapper">
                                <table class="table is-narrow w-100">
                                    <tbody>
                                        <tr>
                                            <td>Yedek Parça</td>
                                        </tr>
                                        <tr>
                                            <td>Yağlar ve Sıvılar</td>
                                        </tr>
                                        <tr>
                                            <td>İşçilik</td>
                                        </tr>
                                        <tr>
                                            <td>Diğer</td>
                                        </tr>
                                        <tr>
                                            <td>İndirim</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                        <div class="column" (click)=" !quotation? priceChange('economy',item): '' " style="padding: 0.75rem 0px;">  
                            <div [ngClass]="item.economic_checked?'catagory-price-wrapper active':'catagory-price-wrapper'">   
                                <table class="table is-narrow w-100"> 
                                    <tbody>
                                        <tr>
                                            <td>{{item.eco_spare_name}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_spare_price}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{item.eco_lubricants_name}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_lubricants_price}}</td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_labour_price}}</td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.eco_discount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                </mat-expansion-panel>  
            </div>
        </div>
        </div> 
    </div>

    <!-- Mobile layout -->
    <div>
        <div class="columns m-0 itemized_estimate" style="border-top: 3px solid #BBBBBB; border-bottom: 3px solid #BBBBBB;">
            <div class="column is-6 is-flex is-align-items-center" *ngIf="!fromMap">
                <p class="price-text">{{"COMMON.PRICE_TEXT" | translate }}
                </p>
            </div>
            <div class="column is-flex is-align-items-center is-justify-content-end">
                <div class="">
                    <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green mr-5">{{"COMMON.TOTAL_PRICE" | translate }}</p>
                </div>
                <div class="estimation-total">
                    <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green estimate-total">₺ {{total_price | number:'1.2-2':'tr' }}</p>
                </div>
            </div>
        </div>
        <div class="columns m-0" *ngIf="!quotation">
            <div class="column pb-0 is-flex is-flex-wrap-wrap is-justify-content-end">
                <!-- <div class="pr-2">
                    <button class="button button-common mb-2 py-5 px-5">
                        <img class="garage-facility pr-2" src="assets/svg/chat.svg" />
                        {{"COMMON.ASK_QUESTION" | translate }}
                    </button>
                </div>
                <div class="">
                    <button class="button button-common is-success py-5 px-5" (click)="nextStepper(estimationList)"> {{"COMMON.CREATE_AN_APPOINTMENT" | translate }}</button>
                </div> -->
                <div class="button-field is-flex is-flex-wrap-wrap" *ngIf="!fromMap">
                    <button class="button has-text-weight-semibold ask-btn" (click)="messagePopup()">
                        <img class="garage-facility pr-2" src="assets/svg/chat.svg" />
                        {{"COMMON.ASK_QUESTION" | translate }}
                    </button>
                    <button class="button is-success  has-text-weight-semibold ask-btn" (click)="nextStepper(estimationList)">
                        {{"COMMON.CREATE_AN_APPOINTMENT" | translate }}
                     </button>
                </div>
            </div>          
        </div>
    </div>

</div>
<app-message *ngIf="show_message" [estimation]="estimate_item" [quoteId]="quoteId" (hideMessage)="hideMessagePopup($event)"></app-message>
<div *ngIf="show_popup" class="modal fade is-active">
    <div class="modal-card">
        <div class="modal-card-body py-5">
          <div class="has-text-centered pb-4 has-text-weight-semibold">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_CHAT_WITH_GARAGE' | translate}} <br> {{'DASHBOARD.PLEASE_SAVE_THE_ESTIMATION' | translate}}
          </div>
          <div class="is-flex is-justify-content-center">
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="show_popup = false">
              {{'DASHBOARD.CANCEL' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="saveEstimate()">
              {{'DASHBOARD.SAVE' | translate}}
            </div>
          </div>
        </div>
    </div>
  </div>