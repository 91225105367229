import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SlideInOutAnimation } from 'src/app/animation/SlideInOutAnimation';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({transform: 'translateY(-100%)'}),
    animate('200ms ease-in', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
  ])
]);
@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  animations: [SlideInOutAnimation]
})
export class MapViewComponent implements OnInit, OnChanges {
  @Output() sendViewDetails = new EventEmitter<string>();
  zoom: number = 14;
  // initial center position for the map
  lat: number = 41.015137;
  lng: number = 28.979530;
  markers: any[] = []
  userLocationMarkerAnimation: string = '';
  icon: any = {
    url: ('/assets/img/marker_green.png'),
    labelOrigin: { x: 48, y: 17 },
    scaledSize: {
      height: 50,
      width: 100
    }
  };
  constructor(private passService: PassDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  ngOnInit(): void {
    let locationList = sessionStorage.getItem(Constants.APP.LOCATION_ARRAY)
    if (locationList != null) {
      let locationList_arr = JSON.parse(locationList)
      locationList_arr.forEach((item: any) => {
        this.markers.push({
          lat: Number(item.lat),
          lng: Number(item.lng),
          garage_name: item.garage_name,
          garage_id: item.garage_id,
          distance: item.distance,
          // label: String(2),
          labelOption: {
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            paddingBottom: '20px',
            text: item.amount.total
          },
          draggable: true
        })
      })
      this.lat = Number(this.markers[0].lat)
      this.lng = Number(this.markers[0].lng)
    }
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
  }

  mapReading() {
    this.userLocationMarkerAnimation = 'BOUNCE';
  }
  triggerEscapeKey(): void {
    const escEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      keyCode: 27, // Standard key code for Escape
      code: 'Escape',
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.dispatchEvent(escEvent);
  }

  markerClicked(event: Event) {
    setTimeout(() => {
      var x = document.getElementsByClassName('gm-ui-hover-effect')[0]?.remove();
    }, 1);
    this.sendViewDetails.emit()
    this.passService.setGarageValue(event)
    this.triggerEscapeKey()
  }
  onMouseOver(infoWindow: any) {
    infoWindow.open();
    setTimeout(() => {
      var x = document.getElementsByClassName('gm-ui-hover-effect')[0]?.remove();
    }, 5);
  }

  onMouseOut(infoWindow: any) {
    infoWindow.close();
  }

}
// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

