 
<div class="container-wrapper pt-6"> 
    <div class="mt-3 w-bg-with-border-radius is-justify-content-space-around"> 
        <div class="colums "> 
            <div class="column is-12-mobile is-6-desktop">
              <div class="field">
                <div class="control is-medium mb-5">
                  <input class="input is-medium" type="password" placeholder="Current Password">
                </div>
              </div> 
              <div class="field">
                <div class="control is-medium mb-5">
                  <input class="input is-medium" type="password" placeholder="New Password">
                </div>
              </div>  
            </div>
        </div> 
        <div class="colums">
            <div class="column is-12-mobile is-6-desktop">
                <button class="button-cta">  
                    Update Password
                </button> 
            </div>
        </div>
    </div>
</div>