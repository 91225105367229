import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../Constants/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendChatMsg(message:string,jobId:number, sender:number, receiver:number): Observable<any> {
    return this.http.post(Constants.BASE_URL + Constants.API.SEND_MSG, {
      message,
      jobId,
      sender,
      receiver
    });
  }
  getChatMsg( jobId: number, garageId: number): Observable<any> {
    return this.http.post(Constants.BASE_URL + Constants.API.GET_MSG, {
      jobId,
      garageId
    });
  }
  setReadMsg( jobId: number, garageId: number): Observable<any> {
    return this.http.post(Constants.BASE_URL + Constants.API.SET_READ_MSG, {
      jobId,
      garageId
    });
  }
  fetchAllNotification(userId: number):Observable<any> {
    return this.http.post(Constants.BASE_URL + Constants.API.FETCH_ALL_NOTIFICATION, {userId})
  }
  setReadNotification(notification: any, userId: number ): Observable<any>{
    return this.http.post(Constants.BASE_URL + Constants.API.SET_READ_NOTIFICATION, {notification, userId})
  }
  getNotificationCount(userId: number ): Observable<any>{
    return this.http.post(Constants.BASE_URL + Constants.API.GET_NOTIFICATION_COUNT, {userId})
  }
}
