
<div class="side-notification">
    <div class="notification-header is-flex is-align-items-center px-3 is-clickable" (click)="clickBack()">
        <img src="../../../assets/svg/notify-back.svg" alt="" class="pr-4" >
        <div class="pt-1 has-text-weight-bold">{{'DASHBOARD.NOTIFICATION' | translate}}</div>
    </div>
    <div class="notification-container p-3">
        <div class="notification-card mb-3" *ngFor="let notification of notification_list; let i = index">
            <div class="new-job-notify" *ngIf="notification.types === 'Job Status'">
                <div class="columns m-0 is-multiline">
                    <div class="column is-12 pb-0">
                        <div class="is-flex-tablet is-justify-content-space-between is-align-items-center">
                            <div class="has-text-weight-bold">{{notification.title}} </div>
                            <div class="date-time">{{notification.date_create | date : "dd-MM-yyyy" }} - {{notification.date_create | date : "hh:mm" }}</div>
                        </div>
                    </div>
                    <div class="column is-fluid pt-1 is-flex is-align-items-center">
                        <div>{{notification.content}}</div>
                    </div>
                    <div class="column is-narrow is-flex is-align-items-center is-justify-content-center pt-1">
                            <button class="button" (click)="setReadNotification(notification)" style="width: 110px;">
                                {{'DASHBOARD.VIEW_JOB' | translate}}
                            </button>
                    </div>   
                </div>
            </div>
        </div>
        <div class="placeholder-container is-flex is-justify-content-center is-align-items-center" *ngIf="notification_list?.length === 0">
            {{'DASHBOARD.NO_NEW_NOTIFICATIONS' | translate}}
        </div>
    </div>
</div>