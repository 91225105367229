<div class="block-padding">
    <div class="content">
    <h3 class="is-size-3-desktop is-size-4-mobile is-size-4-tablet pt-1">{{ 'HOME.TEXT_32' | translate }}</h3>
    <div class="columns">
        <div class="column is-4 " *ngFor="let item of [1,2,3]">
            <app-comments-line-item></app-comments-line-item>
        </div>
    </div>
</div>
</div>
