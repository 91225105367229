<div class="location-bg">
    <app-service-request-layout [FromLocation]="true"></app-service-request-layout>
    <div class="">
        <div class="level-item">
            <div class="location-box" style="padding-bottom: 0.1rem;">
            <div class="column is-7 pl-0" style="align-items: center;display: flex;">
                <button class="button back_button" (click)="backPressed()">
                  <span class="icon is-small is-left" style="height: 60px; color: #fff; ">
                    <i class="fas fa-arrow-left"></i>
                  </span>
                </button>
                <span class="has-text-weight-bold ml-4 is-size-6-mobile	" style="font-size: 1.3rem;">
                    {{"HOME.TEXT_67" | translate}}
                </span>
              </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="level-item">
            <div class="box location-box pb-2" >
                <div class="content mr-4 is-hidden">
                    <div class="sec-title-wrapper1 mt-3 mb-5 ">
                        <!-- <img class="back_img1" src="assets/svg/arrow-left-circle.svg" />  -->
                        <h4 class="has-text-weight-bold pt-1" style="color: #9E9E9E; background:red">
                            {{"HOME.TEXT_67" | translate}}
                        </h4>
                    </div> 
                </div>
                <div class="columns is-centered is-vcentered">
                    <div class="column is-half-desktop pb-0">
                        <div class="field new-location-page"> 
                            <div class="control has-icons-left" style="position: relative;">
                                <app-location [FromLocation]="true"></app-location>
                                <app-current-location></app-current-location>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>
