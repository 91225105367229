<div>
    <h5 class="size-h6 is-size-6 is-hidden has-text-weight-bold mt-5">{{ 'SERVICE.TEXT_21' | translate}}
        <img  class="is-hidden" (click)="previousStep()" src="assets/svg/arrow-left-circle.svg" style="float: right;" />
    </h5>
    <div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 300px;"><mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div *ngIf="!isDataLoading">
        <div *ngIf="isFromDashBoardViewDetails == true || isFromLoginViewDetails == true"> 
            <div *ngFor="let item of appointment; index as i" class="pt-3">
                <app-service-request-calender-line-item (onClickViewCalendar)="clickViewCalendar(item)" (timeClickEvent)="getDateTime($event)" (monthClickEvent)="clickMonthevent($event)"  [step]="i+1" [name]="item">
                </app-service-request-calender-line-item>
            </div>
        </div> 
            <div *ngIf="isAlternateButton" class="mt-3" style="display: flex;justify-content: end;">
                <button class="button is-white alternateID" (click)="clickAlternateDay()">
                    <span class="icon-text">
                        <span class="icon" style="color: #77736c;">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span style="color: #77736c;">{{'SERVICE.TEXT_39' | translate}}</span>
                    </span>
                </button>  
            </div>
            <div class="mt-3">
                <span>{{'COMMON.TIME_SLOT_TEXT' | translate}}</span>
            </div>
            <div class="column px-0">
                <div class="is-flex button-field">
                    <button id="btncontinue" class="button btn-continue has-text-weight-semibold is-success " (click)="nextStepper()">{{ 'COMMON.CONTINUE' | translate}}</button>
                </div>
                <div class="is-hidden" *ngFor="let item of selectedDateList; index as i">
                    <div *ngIf="isShowingSelected" [ngClass]="i == 0 ? 'selected-time-bg1' : 'selected-time-bg2'" class="columns">
                        <div class="column"><span
                                style="display: flex;align-items: center;height: 100%;color: #99bb3c;font-weight: 600;">{{item}}</span></div>
                        <div class="column is-justify-content-end" style="display: flex;">
                            <button class="button" style="border-radius: 10px;" (click)="clickChangeTime()">{{'COMMON.CHANGE_TIME' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
<div *ngIf="isShowingCalendar" class="column mt-6" style="display: flex;">
    <div class="demo-inline-calendar-card">
        <div class="columns">
            <div class="column is-one-fifth" (click)="previousClicked()">
                <span class="icon">
                    <i class="fas fa-angle-left"></i>
                </span>
            </div>
            <div class="column">
                <span>{{cal1Header}}</span>
            </div>
        </div>
        <mat-card>
            <mat-calendar #calendar1 [selected]="selectedDate" (selectedChange)="onSelect($event)"
                [dateFilter]="currentDateFilter"></mat-calendar>
        </mat-card>
    </div>
    <div class="demo-inline-calendar-card ml-4">
        <div class="columns">
            <div class="column" style="display: flex;justify-content: center;">
                <span>{{cal2Header}}</span>
            </div>
            <div class="column is-one-fifth" (click)="nextClicked()">
                <span class="icon">
                    <i class="fas fa-angle-right"></i>
                </span>
            </div>
        </div>
        <mat-card>
            <mat-calendar #calendar2 [dateFilter]="currentDateFilter" (selectedChange)="onSelect($event)"
                [selected]="selectedDate" [startAt]="selectedMonth"> </mat-calendar>
        </mat-card>
    </div>
</div>