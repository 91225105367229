<div class="field is-horizontal mt-3" (click)="onClickCurrentLocation()">
    <div class="field-body" style="display: flex;">
        <div class="field location-cta-wrapper">
            <button *ngIf="isCurrentLocationEnabled" class="button location-cta"><span><img
                        src="assets/svg/current_location.svg" />
                </span>
            </button>
            <button *ngIf="!isCurrentLocationEnabled" class="button location-cta"><span><img
                        src="assets/svg/current_location.svg" />
                </span>
            </button>
        </div>
    </div>
</div>

<!-- <div class="field location-cta-wrapper"> 
    <button *ngIf="isCurrentLocationEnabled" class="button location-cta"><span><img src="assets/svg/current_location.svg" />
    
    </span>{{ 'HOME.TEXT_68' | translate}}

</button>
    <button *ngIf="!isCurrentLocationEnabled" class="button location-cta"><span><img src="assets/svg/current_location.svg" />
    
    </span>{{ 'HOME.TEXT_69' | translate}}
</button>
</div> -->