import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs';
import { Constants } from 'src/app/Constants/constants';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent implements OnInit, OnDestroy {

  question: any = [];
  services: any = [];
  hasSubData: boolean = true;
  mainQuestion: any = {};
  comment: string = '';
  serviceDataQus: any = {};
  is_from_all_service: boolean = true;
  isDataLoading: boolean = false;
  alive: boolean = true
  staticDoYouNeed: string = 'What service do you need?'
  selected_service : any;
  service_name:any;
  browseAllCategory: any = []
  problemsList: any = []
  selectedServiceList:any
  isShowCategoryList: boolean = true
  isShowProblemsList: boolean = false;
  selected_answer : any = [];
  subDataIndex: number = 1;
  subQuestionData: any = []
  subStage!: number;
  show_popup: boolean = false; 

  constructor(private router: Router,
    private route: ActivatedRoute,
    private bookService: BookingServiceService,
    private sharedService: SharedServiceService,
    private passDataService: PassDataService,
    private translateWord: TranslateService,
    private scroll: ViewportScroller) { }

  ngOnInit() {
    document.body.classList.add('inner-class');
    if(localStorage.getItem(Constants.APP.SERVICE_LIST)){
       this.selectedServiceList =  JSON.parse(localStorage.getItem(Constants.APP.SERVICE_LIST) || '{}')
       this.selectedServiceList = this.selectedServiceList.filter((item:any)=>item.answer.service_id)
    }
   
    if (this.sharedService.getProblemData() != null) {
      this.is_from_all_service = true;
      this.getAllProblems(this.sharedService.getProblemData().id, 0)
    } else if (this.sharedService.getServiceData() != null && this.sharedService.getServiceData() != undefined) {
      this.is_from_all_service = true;
      this.getSelectServicesList(this.sharedService.getServiceData().id)
    }
    else if (this.sharedService.getendServiceData() != null) {
      this.is_from_all_service = true;
      this.getServicesList({ id: this.sharedService.getendServiceData().service_id })
    } else {
      this.is_from_all_service = false;
      if(history.state.editService){
        let data1 = history.state.editService
        this.getServicesList({id : data1.question.service_id});
      }else{
        this.getServicesList({}) 
      }
    }
    this.passDataService.getViewAllData().pipe(takeWhile(() => this.alive)).subscribe((data: any) => {
      this.updateUI(data)
    })
    this.passDataService.getBackPressed().pipe(takeWhile(() => this.alive)).subscribe((data: any) => {
      if (data === '1.2') {
        if(this.subQuestionData[0]?.question !== undefined && this.subQuestionData[0]?.question === ''){
          // this.show_popup = true
          this.sharedService.triggerPopup('saveService')
        } else {
          this.resetQuestion()
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['service/services'])
          });
        }
      } else if(data !== '1.1' && data !== '1.2'){
        if(!this.hasSubData){
          // this.show_popup = true
          this.sharedService.triggerPopup('saveService')
        }
        this.subDataIndex = (parseInt(data.split('.')[1]))
        this.subStage = parseFloat((parseFloat(data) - 0.1).toFixed(2))
        this.subDataIndex -= 2
        this.question = [];
        this.question.push(this.subQuestionData[this.subDataIndex - 1]);
        this.selected_answer.pop()
      }
    })
    this.sharedService.triggerForwardBtn$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.updateComment()
    })
    this.sharedService.popupResponse$.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if(data == 'skipService'){
        this.skipService()
      }else if(data == 'addAnotherService'){
        this.anotherService()
      }
    })
    this.getBrowseByCategory()
    this.getAllProblems(0, 1)

    this.translateWord.get('SERVICE.TEXT_36').toPromise()
      .then(text => this.staticDoYouNeed = text)
      .catch(e => console.error(`Error is thrown ${e}`));
  }
  showSavePopup(){
    this.sharedService.triggerPopup('saveService')
  }
  onBackPressed(){
    if(this.subDataIndex === 1){
      this.question = [];
      this.subQuestionData = []
    }else{
      this.subDataIndex--
      this.question = [];
      this.question.push(this.subQuestionData[this.subDataIndex - 1]);
      this.selected_answer = this.selected_answer.splice(this.subDataIndex + 1)
    }
  }
  getServicesList(data: any) {
    this.isDataLoading = true;
    let id = {
      service_id: data.id ? data.id : 0
    }
    this.bookService.getServiceList(id).subscribe(
      (res) => {
        res.data.forEach((element: any) => {
          element.from_all_service = false;
        })

        this.services = res.data.filter((item: any, index: any, arr: any) => {
          return index === arr.findIndex((t: any) => t.answer === item.answer);
        })
        let periodicIndex = this.services?.findIndex((service: any) => service.answer === 'Periyodik bakım')
        if (periodicIndex !== -1) {
          [this.services[0], this.services[periodicIndex]] = [this.services[periodicIndex], this.services[0]];
        }
        if(data.id){
          this.sharedService.updateSubData('1.2')
          this.subStage = 1.2
        }else{
          this.sharedService.updateSubData('1.1')
          this.subStage = 1.1
        }
        this.subQuestionData = []
        this.services?.forEach((element: any) => {
          element.icon = element.icon != '' ? element.icon : 'per-main1.svg'
          element.ans_icon = 'assets/svg/' + element.icon;
          if (this.sharedService.getPopularServiceData() != null) {
            if (this.sharedService.getPopularServiceData().id == element.id) {
              this.pushQuestion(element)
            }
          }
          this.isDataLoading = false;
        });

        this.services?.forEach((ele:any)=>{
          this.selectedServiceList?.some((item:any)=>{
            if(ele.answer == item.question.answer){
              ele.selected = true
            }
          })
          if(history.state?.editService){
            let data = history.state.editService
            if(data.question.service_id == ele.service_id){
              this.comment = data.comment
              this.pushQuestion(ele)
            }
          }
        })
        if (this.services?.length == 1) {
          this.pushQuestion(this.services[0])
        }
      }, (err) => {
        this.isDataLoading = false;
      }
    )
  }

  pushQuestion(data: any) {
    this.subQuestionData = []
    this.selected_answer=[]
    this.subQuestionData?.push(data)
    if(!data.fromMaster)
    this.selected_answer?.push({relevant_answer : data.relevant_answer}) 
    if (data.from_all_service == false) {
      this.is_from_all_service = false;
    }
    this.sharedService.updateSubData('1.2')
    this.subStage = 1.2
    this.question = [];
    this.mainQuestion = data
    if(data.subdata || data.subData){
      this.hasSubData = true
    } else {
      this.sharedService.triggerComments()
      this.hasSubData = false
    }
    // this.hasSubData = data.subdata || data.subData ? true : false
    if (data.subData) {
      data.subdata = data.subData
      this.question?.push(data);
      this.pushToLocal(data.answer);
    } else {
      this.question?.push(data);
      this.pushToLocal(data);
    }
  }

  selectSubQuestion(data: any) {
    this.subQuestionData?.push(data)
    if (data.category_id) {
      this.getServicesList({ id: data.id })
    }else{
      if(this.selected_answer[this.selected_answer?.length - 1])
      this.selected_answer[this.selected_answer?.length-1].selected_answer = data.answer
      if(data?.subData)
      this.selected_answer?.push({relevant_answer : data.relevant_answer}) 
    }
    this.sharedService.updateSubData((this.subStage + 0.1).toFixed(1))
    this.subStage = parseFloat((this.subStage + 0.1).toFixed(1))
    if (data.subData) {
      this.question = [];
      data.subdata = data.subData
      this.question?.push(data);
      this.hasSubData = true
    } else {
      if(this.selected_answer){
        data.selected_answer = this.selected_answer
      }
      this.sharedService.triggerComments()
      this.hasSubData = false
      this.pushToLocal(data);
      this.question.push('note')
    }
    this.subDataIndex++
  }

   pushToLocal(data: any) {
    if(!data.service_id){
      this.service_name = data
    }else{
      this.selected_service = data
    }
    var existingData = localStorage.getItem(Constants.APP.SERVICE_LIST);
    var array = [];
    if (existingData != "" && existingData != 'null' && existingData != undefined) {
      var parseData =  JSON.parse(existingData);
      var existingObject = {
        question: this.mainQuestion,
        answer: data,
        comment: this.comment,
        is_from_all_service: this.is_from_all_service
      }
      
      var existingValue = false
      parseData?.forEach((element: any, index: number) => {
        if (element.answer.id === existingObject.answer.id && element.question.id === existingObject.question.id) {
          existingValue = true
        } else if (element.answer.id == undefined && element.answer === element.question.answer) {
          existingValue = true
          parseData[index] = existingObject
        }
      });
      if (!existingValue) {
        parseData?.push(existingObject);
      }
      parseData = parseData?.filter(function (item: any, index: any, inputArray: any) {
        return inputArray?.indexOf(item) == index;
      });
      parseData = parseData?.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => (v2.answer.service_id == v.answer.service_id)) == i)
      parseData?.forEach((ele:any)=>{
        if(ele.answer.service_id == data.service_id && ele.answer.selected_answer){
          ele.selected_answer = data.selected_answer
        }
      })
      localStorage.setItem(Constants.APP.SERVICE_LIST, JSON.stringify(parseData));
    } else {
      array?.push({
        question: this.mainQuestion,
        answer: data,
        comment: this.comment,
        is_from_all_service: this.is_from_all_service,
        selected_answer : data.selected_answer
      })
      localStorage.setItem(Constants.APP.SERVICE_LIST, JSON.stringify(array));
    }
    if(!this.hasSubData){
      this.sharedService.updateServiceList("update");
    }
  }

  resetQuestion() {
    this.sharedService.clearData()
    this.hasSubData = true
    this.question = [];
    this.comment = ""
    this.subDataIndex = 1
    this.getServicesList({});
  }

  resetHome() {
    history.back();
  }

  // goServices() {
    // this.updateComment()
    // this.show_popup= true
    // this.router.navigate(['/service']);
    // history.go();
  // }
  anotherService(){
    this.updateComment()
    this.resetQuestion()
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['service/services'])
    });
  }
  skipService(){
    let serviceData = JSON.parse(localStorage.getItem(Constants.APP.SERVICE_LIST) || '[]')
    serviceData.pop()
    localStorage.setItem(Constants.APP.SERVICE_LIST, JSON.stringify(serviceData))
    this.resetQuestion()
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['service/services'])
    });
  }
  goContinue() {
    this.updateComment()
    this.router.navigate(['/service/vehicle']);
  }

  updateComment() {
    var existingData = localStorage.getItem(Constants.APP.SERVICE_LIST);
    if (existingData != "" && existingData != 'null' && existingData != undefined) {
      var parseData = JSON.parse(existingData);
      parseData?.forEach((item:any)=>{
        if(item.question.answer == this.service_name){          
          item.comment = this.comment
        }else if(item.question.answer == this.selected_service.answer){
          item.comment = this.comment
        }
      })

      localStorage.setItem(Constants.APP.SERVICE_LIST, JSON.stringify(parseData));
    }
    this.sharedService.updateServiceList("update");
  }

  getSelectServicesList(id: string) {
    this.isDataLoading = true;
    this.bookService.getServiceByCategory(id).subscribe(
      (res) => {
        if (res.statusCode === 1) {
          this.serviceDataQus = {
            id: res.data[0].id,
            service_id: res.data[0].category_id,
            question: this.staticDoYouNeed,
            answer: res.data[0].category_name,
            subData: res.data,
            fromMaster: true
          }
          this.pushQuestion(this.serviceDataQus)
        }
        this.isDataLoading = false;
      }, (err) => {
        this.isDataLoading = false;
      }
    )
  }

  clickAllCategory(item: any) {
    this.is_from_all_service = true;
    this.getSelectServicesList(item.id)
    this.scroll.scrollToPosition([0, 0])
  }

  clickProblems(item: any) {
    this.is_from_all_service = true;
    this.getAllProblems(item.id, 0)
    // this.pushQuestion(item)
    this.scroll.scrollToPosition([0, 0])
  }

  clickCategoryTab() {
    document.getElementById('problemsTab')?.classList.remove('is-active')
    document.getElementById('categoryTab')?.classList.add('is-active')
    this.isShowCategoryList = true
    this.isShowProblemsList = false
  }

  clickProblemsTab() {
    document.getElementById('problemsTab')?.classList.add('is-active')
    document.getElementById('categoryTab')?.classList.remove('is-active')
    this.isShowCategoryList = false
    this.isShowProblemsList = true
  }


  updateUI(data: any) {
    this.isDataLoading = true;
    this.question = [];
    this.mainQuestion = [];
    this.hasSubData = true;
    this.getServicesList({});
  }

  onClickClose() {
    localStorage.removeItem(Constants.APP.SERVICE_LIST)
    localStorage.removeItem("address")
    localStorage.removeItem("zipcode")
    // sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
    this.sharedService.clearData()
    document.body.classList.remove('inner-stepper');
    this.router.navigate(['/home'])
  }

  getBrowseByCategory() {
    this.bookService.getAllServiceList().subscribe({
      next: (res) => {
        if (res.statusCode == 1) {
          this.browseAllCategory = []
          this.browseAllCategory = res.data
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  getAllProblems(problemId: number, isMainScreenFrom: number) {
    let payload: ProblemPayload = {
      isMainScreenFrom: isMainScreenFrom
    }
    if (problemId != 0) {
      this.isDataLoading = true;
      payload = {
        isMainScreenFrom: isMainScreenFrom,
        id: problemId,
      }
    }
    this.bookService.getProblemList(payload).subscribe({
      next: (res) => {
        if (res.statusCode == 1) {
          if (isMainScreenFrom) {
            this.problemsList = []
            this.problemsList = res.data
          }
          if (problemId != 0) {
            this.serviceDataQus = res.data[0]
            if(localStorage.getItem(Constants.APP.SERVICE_LIST)){
              let existingData = JSON.parse(localStorage.getItem(Constants.APP.SERVICE_LIST) || '');
            if(existingData){
              existingData.forEach((item:any)=>{
                if(item.question.service_id == this.serviceDataQus.service_id){
                  this.comment = item.comment
                }
              })
            }
            }   
            this.pushQuestion(this.serviceDataQus)
            this.isDataLoading = false;
          }
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  ngOnDestroy(): void {
    this.alive = false
  }
}
type ProblemPayload = {
  isMainScreenFrom: number,
  [key: string]: string | number;
}
