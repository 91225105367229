import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
  } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})

export class Utils{
    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        private _snackBar: MatSnackBar,
        private translate: TranslateService
    ){}
    openErrorSnackBar(content:string){
        this.translate.get('COMMON.OK').subscribe((res: string) => {
          this.translate.get(content).subscribe((contentText: string) => {
          this._snackBar.open(contentText, res, {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 1500,
              panelClass: ['error-snackbar']
          });
        })
      });
    }
    openSuccessSnackBar(content: string){
        // this._snackBar.open(content, 'tamam', {
        //     horizontalPosition: this.horizontalPosition,
        //     verticalPosition: this.verticalPosition,
        //     duration : 1500,
        //     panelClass: ['success-snackbar']
        //   });
          this.translate.get('COMMON.OK').subscribe((res: string) => {
            this.translate.get(content).subscribe((contentText: string) => {
            this._snackBar.open(contentText, res, {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 1500,
                panelClass: ['success-snackbar']
            });
          })
        });
    }

    getCurrentLanguage(){
        return this.translate.getDefaultLang() !=='en' ? 'TR' : 'EN';
    }

    setCurrentLanguage(language:string){
        this.translate.setDefaultLang(language)
    }

    getMaxEstimateDays(){
        return 15;
    }

    getMonthList(
        locales?: string | string[],
        format: "long" | "short" = "long"
      ): string[] {
        const year = new Date().getFullYear();
        const monthList = [...Array(12).keys()];
        const formatter = new Intl.DateTimeFormat(locales, {
          month: format
        });
      
        const getMonthName = (monthIndex: number) =>
          formatter.format(new Date(year, monthIndex));
      
        return monthList.map(getMonthName);
    }
    getWeekList(): string[] {
      let weekday = ["Sun","Mon","Tues","Wed","Thu","Fri","Sat"]
        if(this.getCurrentLanguage().toLowerCase() == 'tr'){
          weekday = ["pazar","pazartesi","salı","çarşamba","perşembe","cumā","cumartesi"]
        }    
      return weekday
  }
  isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  }
  validateEmail(email : string) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if(!email.match(reg)) {
      return false;
    }
    return true;
  }
}