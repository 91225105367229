<div class="content-inner columns m-0">
  <div class="column is-12-mobile is-9-desktop  is-9-tablet is-flex p-0">
      <div class="column is-9-mobile py-0 is-full is-size-4 has-text-weight-semibold">{{ 'COMMON.VEHICLE_DETAILS' | translate }}</div>
      <div class="column is-4 is-3-mobile py-0 pr-0 is-flex is-justify-content-end">
        <button class="delete is-large" (click)="clickClose()"></button>
      </div>

  </div>
</div>
<div class="scroller">
  <app-service-request-vehicle [vehEdit]="true" [close]="dialogRef" [isFromProfile]="true" [edititem]="data"></app-service-request-vehicle>
</div>

