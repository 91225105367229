<app-signup-header></app-signup-header>

<div class="login-card column is-10-tablet is-8-desktop is-6-widescreen is-4-fullhd p-5">
    <h2 class="signin-title has-text-weight-bold">FAQs</h2>
    <div *ngFor="let item of faq;let i = index" class="faq">
        <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="w-100">
            <mat-expansion-panel-header>
                <mat-panel-title class="mr-0">
                   <p class="question has-text-weight-semibold">{{item.question}}</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="columns m-0">
              <p class="answer">{{item.answer}}</p>
            </div>
        </mat-expansion-panel>
    </div>  
    <p class="faq-text pt-5 has-text-right"><span class=" is-clickable" (click)="help()">{{ 'DASHBOARD.HELP' | translate }}</span></p>
</div>
