<div class="header-fixed"> 
    <nav class="navbar" role="navigation" aria-label="main navigation" style="min-height: 48px!important;"> 
        <div  class="navbar-brand">
            <img class="ml-3 is-clickable" src="assets/img/arabama.png" style="display: block;" routerLink="/home"/>
        </div>
        <div class="inner-mobile-menu is-flex">
                <div class=" msg-icon icon-container mr-2 mbl-icon is-flex is-align-items-center">
                        <div class="is-flex is-align-items-center is-justify-content-center is-clickable" (click)="notification('notify')">
                          <img  class="pl-3 mr-3 icon is-medium notify-icon" src="/assets/svg/bell.svg" alt="" >
                        <div *ngIf="notification_count > 0" class="notification mobile-notification p-0 is-flex is-justify-content-center is-align-items-center">
                          <div class="has-text-weight-bold is-size-7">{{notification_count < 100 ? notification_count : 99}}
                          </div>
                        </div>
                        </div>
                </div>
                <div class="dropdown is-active">  
                    <div (click)="clickHammer('ham')" class="is-flex is-align-items-center">
                        <img class="ham-inner-img" src="../../../assets/img/ham-menu.png"/>
                    </div>  
                    <div class="dropdown-menu" *ngIf="showBurgerMenu" id="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <div class="navbar-dropdown is-boxed" >
                            <a class="navbar-item custom_msg home-page" (click)="homePage()">
                                {{'DASHBOARD.MY_DASHBOARD' | translate}}
                            </a>
                            <a (click)="clickProfile()" class="navbar-item custom_msg nav-profile">
                               {{'DASHBOARD.PROFILE' | translate}}
                            </a>
                            
                            <a class="navbar-item custom_msg nav-park" (click)="carParking()">
                                <span>{{'DASHBOARD.CAR_PARKING' | translate}}</span>
                            </a>   
                            <a class="navbar-item custom_msg ser-history" (click)="serviceHistory()">
                                {{'DASHBOARD.SERVICE_HISTORY' | translate}}
                            </a>
                            <a class="navbar-item custom_msg reviews" (click)="reviews()">
                                {{'PROFILE.REVIEWS' | translate}}
                            </a>

                            <a class="navbar-item custom_msg help-menu" (click)="help()">
                                {{'DASHBOARD.HELP' | translate}}
                            </a>
                            <a class="navbar-item custom_msg exit" (click)="logout()">
                                {{'DASHBOARD.LOGOUT' | translate}}
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>
        </div>  
        <div id="myNavBar" class="navbar-menu">
            <!-- <div  class="navbar-brand is-hidden-mobile">
                <img routerLink="/home" src="assets/img/arabama.png" style="display: block;" />
            </div> -->
            <div class="navbar-end"> 
                <div class="navbar-item">
                    <!-- <a class="navbar-item custom_msg nav-msg">
                        <span>{{'DASHBOARD.MESSAGE' | translate}}</span>
                    </a> -->
                </div>
                <div class="navbar-item">
                    <a class="navbar-item custom_msg nav-park" (click)="carParking()">
                        <span>{{'DASHBOARD.CAR_PARKING' | translate}}</span>
                    </a>
                </div>
                <div class="navbar-item is-hidden">
                    <a class="navbar-item custom_msg nav-setting">
                        <span>{{'DASHBOARD.SETTINGS' | translate}}</span>
                    </a>
                </div>
                <div class="navbar-item">
                    <a class="navbar-item custom_msg nav-profile" (click)="clickProfile()">
                        <span>{{'DASHBOARD.PROFILE' | translate}}</span>
                    </a>
                </div>
                <!-- <div class="navbar-item is-hoverable">
                    <a class="navbar-link custom_nav" style="text-transform: capitalize;">
                        {{user.firstName}} {{user.lastName}}
                    </a>
                    <div class="navbar-dropdown is-boxed">
                        <a (click)="clickProfile()" class="navbar-item is-hidden">
                           {{'DASHBOARD.PROFILE' | translate}}
                        </a>
                        <a class="navbar-item home-page" (click)="homePage()">
                            {{'DASHBOARD.MY_DASHBOARD' | translate}}
                        </a>
                        <a class="navbar-item ser-history" (click)="serviceHistory()">
                            {{'DASHBOARD.SERVICE_HISTORY' | translate}}
                        </a>
                        <a class="navbar-item reviews" (click)="reviews()">
                            {{'PROFILE.REVIEWS' | translate}}
                        </a>
                        <a class="navbar-item help-menu" (click)="help()">
                            {{'DASHBOARD.HELP' | translate}}
                        </a>
                        <a class="navbar-item exit" (click)="logout()">
                            {{'DASHBOARD.LOGOUT' | translate}}
                        </a>
                    </div>
                </div> -->
                <div class="navbar-item name-container is-clickable" (mouseenter)="nameHover = true" (mouseleave)="nameHover = false">
                    <div class="username">
                        {{user.firstName}} {{user.lastName}} <span><img width="14px" src="../../../assets/svg/down-arrow-green.svg" alt=""></span>
                    </div>
                    <div class="menu-options py-2" *ngIf="nameHover">
                        <!-- <div class="option">
                            <a (click)="clickProfile()" class="navbar-item is-hidden">
                                {{'DASHBOARD.PROFILE' | translate}}
                             </a>
                        </div> -->
                        <!-- <div class="option is-flex" style="border: 1px solid red;">
                            <div class="option-img">
                                <img src="https://asdev.nisissolutions.com/dashboard.svg" alt="">
                            </div>
                            <div>
                                <a (click)="clickProfile()" class="navbar-item">
                                    {{'DASHBOARD.PROFILE' | translate}}
                                </a>
                            </div>
                            
                        </div> -->
                        <div class="option" (click)="homePage()">
                            <div class="option-img">
                                <img src="https://asdev.nisissolutions.com/dashboard.svg" alt="">
                            </div>
                            <a class="navbar-item home-page">
                                {{'DASHBOARD.MY_DASHBOARD' | translate}}
                            </a>
                        </div>
                        <div class="option" (click)="serviceHistory()">
                            <div class="option-img">
                                <img src="https://asdev.nisissolutions.com/service-his.svg" alt="">
                            </div>
                            <a class="navbar-item ser-history" >
                                {{'DASHBOARD.SERVICE_HISTORY' | translate}}
                            </a>
                        </div>
                        <div class="option" (click)="reviews()">
                            <div class="option-img">
                                <img src="https://asdev.nisissolutions.com/reviews.svg" alt="">
                            </div>
                            <a class="navbar-item reviews" >
                                {{'PROFILE.REVIEWS' | translate}}
                            </a>
                        </div>
                        <div class="option" (click)="help()">
                            <div class="option-img">
                                <img src="https://asdev.nisissolutions.com/help.svg" alt="">
                            </div>
                            <a class="navbar-item help-menu">
                                {{'DASHBOARD.HELP' | translate}}
                            </a>
                        </div>
                        <div class="option" (click)="logout()">
                            <div class="option-img">
                                <img src="https://asdev.nisissolutions.com/exit.svg" alt="">
                            </div>
                            <a class="navbar-item exit" >
                                {{'DASHBOARD.LOGOUT' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
                
                
                <div class="msg-icon icon-container desk mr-2 is-flex is-align-items-center">
                    <div class="is-flex is-align-items-center is-justify-content-center is-clickable" (click)="notification('notify')">
                      <img  class="pl-3 mr-3 icon is-medium notify-icon" src="/assets/svg/bell.svg" alt="" >
                    <div *ngIf="notification_count > 0" class="notification p-0 is-flex is-justify-content-center is-align-items-center">
                      <div class="has-text-weight-bold is-size-7">{{notification_count < 100 ? notification_count : 99}}
                      </div>
                    </div>
                    </div>
                </div>
                <!-- <div class="navbar-item icon-container" (click)="notification()">
                    <a class="navbar-item custom_msg nav-notification"></a>
                </div> -->
            </div>
            <div class="navbar-item is-hidden">
                <a routerLink="/auth" class="navbar-item">
                    <span class="has-text-weight-bold">{{
                        "HOME.TEXT_55" | translate
                        }}</span>
                </a>
            </div>
        </div>
    </nav>
    <div class="inner_nav"> 
                <div class="is-flex is-justify-content-space-between" [ngClass]="isMobile? 'pl-3': 'pl-6'">
                     <h2  class="name " style="text-transform: capitalize;">
                        {{'DASHBOARD.HELLO' | translate}} 
                        <!-- <span *ngIf="(user.firstName+user.lastName).length < 21">{{user.firstName}} {{user.lastName}}</span>
                        <span *ngIf="(user.firstName+user.lastName).length > 20 && (user.firstName.length < 21)">{{user.firstName}}</span>
                        <span *ngIf="(user.firstName+user.lastName).length > 20 && (user.firstName.length > 20)">{{user.firstName.slice(0,20)}}</span> -->
                        {{user.firstName}} {{user.lastName}}
                    </h2> 
                    <p *ngIf="!searchField && isMobile && isSlideOutComplete" class="searcharea is-clickable" (click)="openSearch()">{{'DASHBOARD.ADD_SERVICE' | translate}}</p>
                    <!-- <img *ngIf="!searchField && isMobile && isSlideOutComplete" class="icon searcharea is-clickable" src="/assets/svg/search-icon.svg" alt="" (click)="openSearch()"> -->
                </div>       
        <div *ngIf="searchField && isMobile" [@slideInOut] class="search-bar" id="searchBarId">
                <app-new-location-search></app-new-location-search>

          </div>
          <div *ngIf="!isMobile" class="search-bar" id="searchBarId">
            <app-new-location-search></app-new-location-search>

      </div>
    </div>
    
</div>
<div class="side-notify" [class.slide-in]="show_notification" [class.slide-out]="!show_notification">
    <app-side-notification class="notify-content" [showNotification]="show_notification" (hideNotification)="hideNotification($event)" (notificationCount)="notification_count = ($event)"></app-side-notification>
  </div>