import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Utils } from 'src/app/common/utils';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-extisting-vehicle',
  templateUrl: './extisting-vehicle.component.html',
  styleUrls: ['./extisting-vehicle.component.scss']
})
export class ExtistingVehicleComponent implements OnInit {

  @Output() onClickAddVehcile: EventEmitter<any> = new EventEmitter<any>();

  vechileDetails: any =[]
  vechileName: any = []
  userDetails: any = {}
  isLoading: boolean = false
  Mobileview: boolean = false;
  selectedVehicle: any;
  showVehicleChangedPopup: boolean = false
  orderServiceVehicle: any;
  isAlive: boolean = true

  constructor(private passDataService: PassDataService,
    private route: Router,
    private sharedService: SharedServiceService,
    private utils: Utils,
    private BookingService: BookingServiceService) { }

  ngOnInit(): void {
    this.sharedService.updateData('2');
    this.isLoading = true
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    this.updateVehicle()
    if (userdata != undefined) {
      this.userDetails = JSON.parse(userdata)
      let vehicle = JSON.parse(sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE) || '{}')
      if(vehicle.quote_id){
        this.orderServiceVehicle = vehicle
      }
      this.fetchVehcile()
    }else{
      this.getdata()
    }
    this.Mobileview = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.Mobileview = window.innerWidth < 768;
    });
    this.sharedService.triggerForwardBtn$.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      this.clickContinue()    
    })
    this.sharedService.popupResponse$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
      if(data == 'vehicleChanged'){
        this.nextStepper()
      }
    })
  }

  fetchVehcile() {
    this.BookingService.fetchVehicleByUser(this.userDetails.user_id).subscribe({
      error: (error) => {
        this.isLoading = false
        // console.log(error.error)
      }, // errorHandler 
      next: (res) => {
        if (res.statusCode == 1 && res.vechicle) {
          let userVehcileData = res.vechicle
          if(userVehcileData){            
            userVehcileData.forEach((element:any) => {
              element.model_year = element.start_production_year
              element.vechile_make_name = element.make_name
              element.vechile_model_name = element.model_name
              element.vechile_make = element.make_id
              element.vechile_model = element.model_id
              element.generation = element.generation_id
              element.series = element.series_id
              element.img = element.logo
            });
          }
         
          sessionStorage.setItem(Constants.APP.SESSION_VECHICLE_LIST, JSON.stringify(userVehcileData))
          // this.vechileDetails = sessionStorage.getItem(Constants.APP.SESSION_VECHICLE_LIST)
          this.getdata()
        }
      }, // nextHandler
    });
  }

  getdata() {
    if (sessionStorage.getItem(Constants.APP.SESSION_VECHICLE_LIST) != undefined && sessionStorage.getItem(Constants.APP.SESSION_VECHICLE_LIST) != null) {
      this.vechileDetails = sessionStorage.getItem(Constants.APP.SESSION_VECHICLE_LIST)
      if (this.vechileDetails != undefined && this.vechileDetails != 'undefined' && this.vechileDetails.length > 0) {
        this.vechileDetails = JSON.parse(this.vechileDetails)
        // if(this.userDetails.user_id){
        //   this.updateVehicle()
        // }
        this.vechileDetails.forEach((data: any) => {
          let vehicleFullName = data.model_year + ' ' + data.vechile_make_name + ' ' + data.vechile_model_name
          data.vehicleFullName = vehicleFullName
          if (data.img != undefined) {
            data.img = data.img
          } else {
            data.img = ''
          }
          if(this.selectedVehicle?.vechicle_id && this.selectedVehicle?.vechicle_id === data.vechicle_id){
            this.vechileName.unshift(data)
          }else{
            this.vechileName.push(data)
          }
        })
      } else {
        this.vechileName = ''
      }
    }
    this.isLoading = false
  }

  updateVehicle(){
    let vehicleDetails:any
    if(sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)){
      vehicleDetails = JSON.parse(sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE) || '[]')
      this.selectedVehicle = vehicleDetails
    }
    // if(this.userDetails.user_id && sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)){
    //   let data = this.vechileDetails.every((item:any)=> (item.trim_id != this.selectedVehicle.vechile_engine && item.vechile_make != this.selectedVehicle.vechile_make && item.vechile_model != this.selectedVehicle.vechile_model ))
    //   if(data){
    //     this.vechileDetails.push(vehicleDetails)
    //   }
    // }
  }

  onClickAdd() {
    this.onClickAddVehcile.emit()
    this.sharedService.updateShowingAddService(false)
  }

  onClickCar(item: any) {
    this.selectedVehicle = item
    sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(item))
    this.passDataService.updateVehicleData('')
  }
  clickContinue(){
    if(this.orderServiceVehicle && (this.orderServiceVehicle?.vechicle_id !== this.selectedVehicle?.vechicle_id)){
      // this.showVehicleChangedPopup = true
      this.sharedService.triggerPopup('vehicleChanged')
    } else {
      if(this.selectedVehicle){
        this.sharedService.updateData('3');
        this.route.navigate(['/service/estimates'])
      } else {
        this.utils.openErrorSnackBar('DASHBOARD.SELECT_ANY_VEHICLE')
      }
    }
  }
  nextStepper(){
    if(this.selectedVehicle){
      this.sharedService.updateData('3');
      this.route.navigate(['/service/estimates'])
    } else {
      this.utils.openErrorSnackBar('DASHBOARD.SELECT_ANY_VEHICLE')
    }
  }
  ngOnDestroy(): void {
    this.isAlive = false
  }
}
