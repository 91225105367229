import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Utils } from 'src/app/common/utils';
import { AddReviewsComponent } from 'src/app/dialog/add-reviews/add-reviews.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  reviews:any = []
  garageList: any =[]
  isDataLoading: boolean = false
  

  constructor(private BookingService:BookingServiceService,
             public dialog: MatDialog,
             public UtilsClass: Utils,
             private route: Router,
             private translate:TranslateService) { }

  ngOnInit(): void {
    this.getReviewList()
  }

  getReviewList(){
    this.isDataLoading = true
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    this.BookingService.fetchReviewByUser(userDetails.user_id).subscribe({
      next:(res)=>{
        if(res.statusCode == 1){
            this.reviews=res.data
            // console.log(this.reviews)
        }else{
          this.reviews = []
        }
        this.isDataLoading = false
      },
      error:()=>{
        this.reviews = []
        this.isDataLoading = false
      }
    })

  }

//   openViewDirection(){
//     if(this.garageList.length==0){
//       this.UtilsClass.openErrorSnackBar(this.translate.instant('COMMON.NO_APPOINTMENT'))
//     }else{
//     let width = '50%'
//     let heigth = '60%'
//     let maxWidth = '80vw'
//     if(this.UtilsClass.isMobile()){
//       width= '100%'
//       heigth = '100%'
//       maxWidth = '100vw'
//     }
//     const viewDialogConfirm = this.dialog.open(AddReviewsComponent,{
//       height: heigth,
//       width: width,
//       maxWidth: maxWidth,
//       panelClass: ['directionCustom'],
//       data:{
//         dataItem: this.garageList
//       }
//     })
//     viewDialogConfirm.afterClosed().subscribe(result => {
//       // console.log(`Dialog result: ${result.data}`);
//       if(result.data == 'reviewAdd'){
//         this.getReviewList()
//       }
//     });
//   }
// }
onBackPressed(){
  this.route.navigate(['/dashboard'])
}
}