import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reviews-tab',
  templateUrl: './reviews-tab.component.html',
  styleUrls: ['./reviews-tab.component.scss']
})
export class ReviewsTabComponent implements OnInit {
  @Input() reviews: any;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.reviews)
  }

}
