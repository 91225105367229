import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/Constants/constants';
import { Utils } from 'src/app/common/utils';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-reviews',
  templateUrl: './add-reviews.component.html',
  styleUrls: ['./add-reviews.component.scss']
})
export class AddReviewsComponent implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<AddReviewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private UtilsClass: Utils,
    private translate: TranslateService,
    private BookService:BookingServiceService) { }

    userDetails: any;
    feedback: string="";
    starCount: any;
    review_details:any;
    
  ngOnInit(): void {
    if (this.data != null) {
      this.review_details = this.data
      this.starCount = this.data.rating? this.review_details.rating.newValue:0
    }
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userDetails = JSON.parse(userdata)
    }
}


addReview(){
  if(this.feedback.trim().length>0 && this.starCount ){
    let payload = {
      "user_id" : this.userDetails.user_id.toString(),
      "garage_id" : this.review_details.garage_id,
      "job_id": this.review_details.job_id,
      "comment" : this.feedback,
      "ratings"  : this.starCount
    }
    this.BookService.addReviewByUser(payload).subscribe({
      error: (error) => {
        console.log(error.error)
        this.UtilsClass.openErrorSnackBar(this.translate.instant(error.error.message))
      },
      next : (res) => {
        if(res.statusCode == 1)
        this.UtilsClass.openSuccessSnackBar(this.translate.instant(res.message))
        this.dialogRef.close({data : "reviewAdd"})
      }
    });
  }
else if(this.feedback.trim().length==0){
  this.UtilsClass.openErrorSnackBar(this.translate.instant('COMMON.PLEASE_ADD_COMMENT'))
}
else if(!this.starCount){
  this.UtilsClass.openErrorSnackBar(this.translate.instant('COMMON.PLEASE_RATE'))
}
}

closeReview(){
  this.dialogRef.close()
}
// removeDuplicatesName(arr:any){
//   return arr.reduce((acc:any, current:any) => {
//     const x = acc.find((item: { name: any; }) => item.name === current.name);
//     if (!x) {
//       return acc.concat([current]);
//     } else {
//       return acc;
//     }
//   }, [])
// }

starValue(data : any){
  this.starCount=data.newValue;
}

}