<!-- <div class="content">
    <div class="columns">
        <div class="column has-text-centered">
            <div class="columns  is-multiline is-mobile">
                <div class="card column cursor-default text-size-11" *ngFor="let item of servicesT;index as i">
                    <img src="assets/svg/car-wrench.svg" /><br>
                    <p class="text-size-14"> {{item}} </p>
                </div>
            </div>
        </div>
        <div class="column has-text-centered">
            2
        </div>
    </div>
</div> -->


<div style="background-color: #F9F9F9">
    <div class="content">
        <div class="columns is-align-self-center service-content">
            <div class="column popular-service py-0 pb-2 ">
                <h3 class="pad-l-100 mt-4 pt-5">{{ 'HOME.TEXT_3' | translate }}</h3>
                <!-- <div class="columns is-hidden-desktop is-hidden-tablet is-pa-15 has-text-centered is-multiline is-mobile">
                    <div class="card service-card cursor-default column" *ngFor="let item of popularService;index as i">
                        <div (click)="routeServices(item)">
                            <img src='assets/svg/{{item.icon}}' />
                        <p> {{item.answer}} </p>
                        </div>
                    </div>
                </div> -->
                <div class="columns is-pa-15 has-text-centered is-multiline popular-service-list mb-0  pad-l-100">
                    <div (click)="routeServices(item)" class="card service-card cursor-default column p-0 p-1 is-flex is-align-items-center" *ngFor="let item of popularService;index as i">
                        <div>
                            <img src='assets/svg/{{item.icon}}' />
                        <p class="has-text-left"> {{item.answer}} </p>
                        </div>
                    </div>
                    <div class="has-text-right" (click)="goToServices()">
                        <h5 class="has-text-success cursor-default all-service">  {{'SERVICE.TEXT_32' | translate}} </h5>
                </div>
                </div>
               
            </div>
            <div class="column popular-service py-0 problem pb-2">
                <h3 class="pad-r-100 mt-4 pt-5">{{ 'HOME.TEXT_56' | translate }}</h3>
                <!-- <div class="columns is-hidden-desktop is-pa-15 has-text-centered is-multiline is-mobile">
                    <div class="card service-card cursor-default column" *ngFor="let item of problemList;index as i">
                        <div (click)="routeProblem(item)">
                            <img src='assets/svg/{{item.icon}}' />
                        <p> {{item.answer}} </p>
                        </div>
                    </div>
                </div> -->
                <div class="columns is-pa-15 has-text-centered is-multiline popular-service-list pad-r-100">
                    <div (click)="routeProblem(item)" class="card service-card cursor-default column p-0 p-1 is-flex is-align-items-center" *ngFor="let item of problemList;index as i">
                        <div>
                            <!-- <img *ngIf="item.icon == 'unusual_smoke.svg'" [style.width.px]="item.icon == 'unusual_smoke.svg' ? 48 : 42" [style.margin-left.px]="item.icon == 'unusual_smoke.svg' ? '12' : ''" src='assets/svg/{{item.icon}}' />
                            <img *ngIf="item.icon == 'smell.svg'" [style.height.px]="item.icon == 'unusual_smoke.svg' ? 42 : 48" src='assets/svg/{{item.icon}}' />
                            <img *ngIf="item.icon == 'vibration.svg'" [style.width.px]="item.icon == 'unusual_smoke.svg' ? 42 : 50" src='assets/svg/{{item.icon}}' />
                            <img *ngIf="item.icon == 'noises.svg'" [style.width.px]="item.icon == 'unusual_smoke.svg' ? 42 : 50" src='assets/svg/{{item.icon}}' />
                            <img *ngIf="item.icon == 'Fluid-leak.svg'" src='assets/svg/{{item.icon}}' /> -->
                            <img  src='assets/svg/{{item.icon}}' />

                            <p class="has-text-left"> {{item.answer}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

