<div class="popular-container">
    <div class="test">

    </div>
<div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 300px;"><mat-spinner [diameter]="50"></mat-spinner></div>

<div *ngIf="question.length===0 && !isDataLoading"> 
</div>
<div *ngIf="question.length>0">
    <div class="sec-title-wrapper mt-3 mb-5 is-hidden">
        <img class="back_img"  (click)="resetQuestion()" src="assets/svg/arrow-left-circle.svg" /> 
        <h4 *ngIf="question.length>0" class="has-text-weight-bold pt-1" style="color: #9E9E9E;">
            {{ 'SERVICE.TEXT_35' | translate}}
        </h4>
    </div> 
</div>
<div  *ngIf="question.length===0 && !isDataLoading" class="all-service"> 
    <div class="columns is-multiline is-flex mb-3">
        <div class="column is-4-tablet is-6-mobile popular-card" *ngFor="let item of services; index as i">
            <img *ngIf="item.selected" class="tick-img" src="assets/svg/tick-green.svg" alt="">
            <div (click)="pushQuestion(item)" class="card is-height-120 has-text-centered is-pa-14 is-border-15 cursor-default" [ngClass]="item.selected ? 'selected': ''">
                <div class="has-text-centered"><img src={{item.ans_icon}} /></div>
                <div class="has-text-weight-bold text-size-14 subheading-color-2 pt-3"> <p style="line-height: 1;">{{item.answer}} </p></div>
            </div>
        </div> 
    </div>
    <div class="columns my-0 tabs is-medium is-boxed">
        <ul>
          <li id="categoryTab" class="custom-allservices" [ngClass]="isShowCategoryList? 'is-active': ''" (click)="clickCategoryTab()"><a>{{ 'SERVICE.TEXT_41' | translate}}</a></li>
          <li id="problemsTab" class="custom-allservices" [ngClass]="isShowProblemsList? 'is-active': ''" (click)="clickProblemsTab()"><a>{{ 'SERVICE.TEXT_42' | translate}}</a></li>
        </ul>
    </div>
    <div *ngIf="isShowCategoryList" class="tabs-list">
        <div *ngFor="let item of browseAllCategory; index as i">
            <button class="button list-btn" (click)="clickAllCategory(item)">
                <p class="text-size-15">{{item.name}}</p>
                <span class="icon"  style="color: #cccccc;"><i class="fas fa-angle-right" aria-hidden="true"></i></span>
            </button>
        </div>
    </div>
    <div *ngIf="isShowProblemsList" class="tabs-list">
        <div *ngFor="let item of problemsList; index as i">
            <button class="button list-btn" (click)="clickProblems(item)">
                <p class="text-size-15">{{item.answer}}</p>
                <span class="icon"  style="color: #cccccc;"><i class="fas fa-angle-right" aria-hidden="true"></i></span>
            </button>
        </div>
    </div>
</div>
<div *ngIf="question.length===1 && !isDataLoading">
    <p *ngIf="hasSubData" class="text-size-17 color-arabama-green has-text-weight-bold">{{service_name}}</p>
    <div *ngFor="let item of question; index as i">
        <h5 *ngIf="item.question" class="ques-heading pb-1 pt-2">{{item.question}}</h5>
    </div>
    <div *ngIf="hasSubData">
        <div *ngFor="let item of question[0].subdata; index as i">
            <div class="answer-bg" (click)="selectSubQuestion(item)">{{item.answer}}</div>
        </div>
    </div>
</div>

<div class=""  *ngIf="!hasSubData && !isDataLoading">
    <p *ngIf="service_name?.answer" class="text-size-17 has-text-weight-bold color-arabama-green">{{service_name.answer}}</p>
    <p *ngIf="!service_name?.answer" class="text-size-17 has-text-weight-bold color-arabama-green">{{service_name}}</p>
    <p *ngIf="selected_service?.answer && !service_name" class="text-size-17 has-text-weight-bold color-arabama-green">{{selected_service.answer}}</p>

    <textarea style="resize: none;" rows="10" class="textarea mt-2 text-align-left" [(ngModel)]="comment"
    :placeholder="{{'SERVICE.TEXT_3' | translate}}"></textarea>
        <div class="is-flex is-flex-wrap-wrap button-field">
            <button (click)="showSavePopup()" class="button mt-3 has-text-weight-semibold add-service">{{'COMMON.ANOTHER_SERVICE' | translate }}
            </button>
            <button (click)="goContinue()" class="button is-success mt-3 has-text-weight-semibold save-service"> {{'COMMON.SAVE_AND_SELECT_VEHICLE' | translate}}
            </button>  
        </div> 
</div>
</div>
<!-- <div *ngIf="show_popup" class="modal fade is-active">
    <div class="modal-card">
        <div class="modal-card-body py-5">
          <div class="has-text-centered pb-3">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_SAVE_SELECTED_SERVICE' | translate}}
          </div>
          <div class="is-flex is-justify-content-center">         
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="skipService()">
                {{'DASHBOARD.NO' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="anotherService()">
                {{'COMMON.YES' | translate}}
            </div>
          </div>
        </div>
    </div>
  </div> -->
  