
<div class="filter-container" [ngClass]="disable ? 'disable': ''">
    <div class="filter-input is-flex pl-3 pr-2 is-clickable"(click)="clickInput()" >
        <input #inputField type="text" class="input-field" [(ngModel)]="inputValue" (input)="inputChange()" (focus)="onFocus()">
        <label *ngIf="!background || !editVehicle" class="floating-label floating-white" [class.active]="isActive">{{ placeholder}}</label>
        <label *ngIf="background == 'profile'" class="floating-label floating-gradient" [class.active]="isActive">{{ placeholder}}</label>
        <label *ngIf="editVehicle" class="floating-label floating-veh-gradient" [class.active]="isActive">{{ placeholder}}</label>
        <div *ngIf="!showOptions" class="is-flex is-align-items-center is-justify-content-center btn-click">
            <img class="arrow-img" src="../../../assets/svg/VectorUp.svg" alt="" width="12px" height="12px">
        </div>
        <div  *ngIf="showOptions && inputValue"  class="is-flex is-align-items-center is-justify-content-center btn-click" (click)="clearInput()">
          <img class="arrow-img" src="../../../assets/img/ham-close.png" alt="" width="18px" height="18px" >
      </div>
    </div>
    <div class="filter-dropdown" *ngIf="showOptions">
        <div class="option-list">
            <div *ngFor="let option of filteredOption" class="option is-flex is-align-items-center pl-3 pr-2" (click)="selectOption(option)">   
              <span [innerHTML]="formatInput(option)"></span>
            </div>
          </div>
    </div>
</div>