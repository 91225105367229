import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-services-rotors-one',
  templateUrl: './step-services-rotors-one.component.html',
  styleUrls: ['./step-services-rotors-one.component.scss']
})
export class StepServicesRotorsOneComponent implements OnInit {

  @Output() nextStep:EventEmitter<any>= new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    // this.nextStep.emit()
  }

  moveNextStep(){
    this.nextStep.emit();
  }

}
