import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { FormControl } from '@angular/forms';
import { Utils } from 'src/app/common/utils';
import { PassDataService } from 'src/app/services/pass-data.service';
import { takeWhile } from 'rxjs';
import { Constants } from 'src/app/Constants/constants';

@Component({
  selector: 'app-service-request-confirm',
  templateUrl: './service-request-confirm.component.html',
  styleUrls: ['./service-request-confirm.component.scss']
})
export class ServiceRequestConfirmComponent implements OnInit, OnDestroy {
  @ViewChild('otp1', { static: false }) otp1: any = ElementRef;
  @ViewChild('otp2', { static: false }) otp2: any = ElementRef;
  @ViewChild('otp3', { static: false }) otp3: any = ElementRef;
  @ViewChild('otp4', { static: false }) otp4: any = ElementRef;

  constructor(private sharedService: SharedServiceService,
    private route: Router,
    private translate: TranslateService,
    private BookService: BookingServiceService,
    private utilsClass: Utils,
    private passDataService: PassDataService) {
      this.otpDetails = {} as OTP

     }

  firstNameFormControl = new FormControl();
  lastNameFormControl = new FormControl();
  emailIdFormControl = new FormControl();
  phoFormControl = new FormControl();
  cityFormControl = new FormControl();
  districtFormControl = new FormControl();
  pwdFormControl = new FormControl();
  memberApprovalFormControl = new FormControl();
  contactConfirmFormControl = new FormControl();
  commercialMsgFormControl = new FormControl();

  checkStatus: boolean = false
  isLoading: boolean = false
  isAliva: boolean = true
  ph: any;
  newUserNotification: any;

  //validation
  isFirstNameValid: boolean = true
  isLastNameValid: boolean = true
  isEmailValid: boolean = true
  isPhnValid: boolean = true
  isPwdValid: boolean = true
  isCityValid:boolean = true
  isDistictValid:boolean = true
  btnDisabled: boolean = false
  isOtpSend: boolean = false
  expire!: any;
  otpDetails: OTP;
  showMember_approval: boolean = false
showContact_confirm: boolean = false
showCommercial_msg: boolean = false
checkMember_approval : boolean = false
checkCommercial_msg : boolean = false
isFirstNameActive:boolean = false;
isLastNameActive:boolean = false;
isEmailActive:boolean = false;
isPhoneActive:boolean = false;

optionCity: string [] = []
optionDistrict: string [] = []
cityList:any;
districtList:any;
disableDistrict: boolean = true

  ngOnInit(): void {
    this.passDataService.getBackPressed().pipe(takeWhile(() => this.isAliva)).subscribe((data) => {
      if (data === '5') {
        this.previousStep()
      }
    })
    this.fetchCity()
  }

  ngOnDestroy(): void {
    this.isAliva = false
  }

  ngAfterViewInit() { setTimeout(() => { this.sharedService.updateData('5'); }, 500) }
  onFocus(field: string) {  
    if(field === 'firstname'){
      this.isFirstNameActive = true;
    }
    else if(field === 'lastname'){
      this.isLastNameActive = true;
    }
    else if(field === 'email'){
      this.isEmailActive = true;
    }
    else if(field === 'phone'){
      this.isPhoneActive = true;
    }
  }
  
  onBlur(field: string) {  
    if(field === 'firstname' && !this.firstNameFormControl.value){
      this.isFirstNameActive = false;
    }
    else if(field === 'lastname'&& !this.lastNameFormControl.value){
      this.isLastNameActive = false;
    }
    else if(field === 'email'&& !this.emailIdFormControl.value){
      this.isEmailActive = false;
    }
    else if(field === 'phone'&& !this.phoFormControl.value){
      this.isPhoneActive = false;
    }
  }
  previousStep() {
    this.sharedService.updateData('4');
    this.route.navigate(['/service/book'])
  }

  getCheckboxValue(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkStatus = checkbox.checked;
    this.btnValidate()
  }
  memberCheckbox(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkMember_approval = checkbox.checked;
    this.btnValidate()
  }
  messageCheckbox(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkCommercial_msg = checkbox.checked;
    
  }
  onInputPasswordChange(value: string) {
    if (value.length == 0) {
      this.isPwdValid = false
    } else {
      this.isPwdValid = true
    }
    // this.btnValidate()
  }
  onInputFirstNameChange(value: string) {
    if (value.length == 0) {
      this.isFirstNameValid = false
    } else {
      this.isFirstNameValid = true
    }
    this.btnValidate()
  }
  onInputLastNameChange(value: string) {
    if (value.length == 0) {
      this.isLastNameValid = false
    } else {
      this.isLastNameValid = true
    }
    this.btnValidate()
  }
  onInputEmailChange(value: string) {
    this.isEmailValid = this.utilsClass.validateEmail(value)
    this.btnValidate()
  }

  onInputPhChange(event:any) {
    this.isPhnValid = false
    var newVal = event.replace(/\D/g, '');
if(newVal.length === 0){
      this.isPhnValid = false
    } else{
      this.isPhnValid = true
      if (newVal.length == 0) {
        newVal = '';
      }else if (newVal.length <= 1) {
        if(newVal !=0){
          newVal = '0' + newVal
        }
        newVal =newVal.replace(/^(\d{0,1})/, '$1');
     } 
      else if (newVal.length <= 4) {
         newVal =newVal.replace(/^(\d{0,1})(\d{0,3})/, '$1 ($2)');
      } else if (newVal.length <= 7) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '$1 ($2) $3');
   
      } else if (newVal.length <= 11) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 ($2) $3 $4 $5');
      } else {
        newVal =newVal.substring(0, 11);
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4 $5');   
      }
        this.ph = newVal   
    }
    if(newVal.replace(/\D/g, '').length == 11){
      this.isPhnValid = true
    }
   this.btnValidate()
}


  btnValidate() {
    if (this.firstNameFormControl.value != null && this.firstNameFormControl.value != '' && this.lastNameFormControl.value != null && this.lastNameFormControl.value != '' && this.emailIdFormControl.value != null && this.emailIdFormControl.value != '' && this.phoFormControl.value != null && this.phoFormControl.value != '' &&  this.phoFormControl.value.length > 16 
    && this.memberApprovalFormControl.value == true && this.contactConfirmFormControl.value == true && this.cityFormControl.value != null && this.cityFormControl.value != ''
    && this.districtFormControl.value != null && this.districtFormControl.value != '') 
    {
      this.btnDisabled = true
    } else {
      this.btnDisabled = false
    }
  }

  // numberOnly(event: any): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
    
  // }
  numberOnly(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  }

  onClikShowPassword() {
    const x = document.getElementById("inputPass") as HTMLInputElement;
    const icon = document.getElementById("passEye")
    // console.log(x.type)
    if (x.type === 'password') {
      x.type = 'text'
      icon?.classList.add('fa-eye')
      icon?.classList.remove('fa-eye-slash')
    } else {
      x.type = 'password'
      icon?.classList.add('fa-eye-slash')
      icon?.classList.remove('fa-eye')
    }
  }
  verifyOTP() {
    if(this.otpDetails.otp1 && this.otpDetails.otp2 && this.otpDetails.otp3 && this.otpDetails.otp4){
    this.addLoader()
    let userOTP = this.otpDetails.otp1 + this.otpDetails.otp2 + this.otpDetails.otp3 + this.otpDetails.otp4
  
    let payload = {
      "first_name": this.firstNameFormControl.value,
      "last_name": this.lastNameFormControl.value,
      "email": this.emailIdFormControl.value,
      "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
      "city": this.cityFormControl.value,
      "province" : this.districtFormControl.value,
      "notification": this.checkStatus ? 1 : 0,
      "member_approval": this.checkMember_approval ? 1 : 0,
      "commercial_message": this.checkCommercial_msg ? 1 : 0,
      "otp" : userOTP
    }
    this.BookService.authUserRegistration(payload).subscribe(
      (res)=>{
        if(res.statusCode ===1){
          let user_data = res.data
          // this.firstNameFormControl.setValue('')
          // this.lastNameFormControl.setValue('')
          // this.emailIdFormControl.setValue('')
          // this.phoFormControl.setValue('')
          // this.pwdFormControl.setValue('')
          this.newUserNotification = {
              types : "New Driver",
              reference_id : user_data.user_id,
              title : `${user_data.first_name} ${user_data.last_name}`,
              content : `${user_data.first_name} ${user_data.last_name} newly registered on Arabama`
          }
          this.isLoading = false
      
          this.utilsClass.openSuccessSnackBar(this.translate.instant(res.message))
          localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(res.data))
          localStorage.setItem(Constants.APP.SESSION_ID, JSON.stringify(res.data.session_id))
          this.saveVehcile()
          this.removeLoader()  
        }

      },(err =>{
        // console.log(err)
        this.removeLoader()
        this.utilsClass.openErrorSnackBar(this.translate.instant(err.error.message))
      }))
  
    }
  }
  backspace(event: any, otpTab: number) {
    if (event.key == "Backspace") {
      if (otpTab === 4) {
        if (this.otpDetails.otp4 == '') {
          this.otp3.nativeElement.focus()
        }
      } else if (otpTab === 3) {
        if (this.otpDetails.otp3 == '') {
          this.otp2.nativeElement.focus()
        }
      } else if (otpTab === 2) {
        if (this.otpDetails.otp2 == '') {
          this.otp1.nativeElement.focus()
        }
      }
    }
  }
  
  tabChange(val: number) {
    let current_id = 'otp' + val
    val = val + 1
    let id = 'otp' + val
    let ele = document.getElementById(id) as HTMLInputElement;
    let current_ele = document.getElementById(current_id) as HTMLInputElement;
    if (current_ele.value != '') {
      ele?.focus()
    } else {
      current_ele?.focus()
    }
  }
  sendOtp(a: string) {
    if (a === 'Resend') {
      // this.isLoading = true;
    }
    this.addLoader()
    document.getElementById('btnLogin')?.classList.add('is-loading')
          this.otpDetails.otp1 = '';
          this.otpDetails.otp2 = '';
          this.otpDetails.otp3 = '';
          this.otpDetails.otp4 = '';
          this.otptimer()
          let payload = {
            "first_name": this.firstNameFormControl.value,
            "last_name": this.lastNameFormControl.value,
            "email": this.emailIdFormControl.value,
            "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
            "notification": this.checkStatus ? 1 : 0,
            "member_approval": this.checkMember_approval ? 1 : 0,
            "commercial_message": this.checkCommercial_msg ? 1 : 0
          }
          this.BookService.saveUserRegistration(payload).subscribe(
            (res)=>{
              if(res.statusCode ===1){
                this.isOtpSend = true
                this.removeLoader()
              }
            },(err =>{
              // console.log(err)
              this.removeLoader()
              this.utilsClass.openErrorSnackBar(this.translate.instant(err.error.message))
            }))
  }
  removeLoader(){
    const button = document.getElementById('btnSignUp');
    button?.classList.remove('is-loading');
  }
  addLoader(){
    const button = document.getElementById('btnSignUp');
    button?.classList.add('is-loading');
  }
  otptimer() {

    var m = "03";
    var s = "00";
  
    const timer = () => {
  
      this.expire = m + ':' + s;
      if (parseInt(s) == 0) {
        s = "59";
      } else {
        s = sec(parseInt(s));
      }
      if (parseInt(m) >= 0 && parseInt(s) == 59) {
        m = sec(parseInt(m))
      }
      // console.log(parseInt(m))
      if (parseInt(m) >= 0) {
        setTimeout(function () {
          timer();
        }, 1000);
        return;
      }
    }
  
    timer();
  
  }

  clickLoginBook() {
    this.route.navigate(['/auth'], { state: { isFromEstimateBooked: true } })
  }

  saveVehcile() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
    if (vehicleData != null) {
      let vehicleDataDetails = JSON.parse(vehicleData)
      let payload = {
        // "zip_code":localStorage.getItem("zipcode"),
        "user_id": userDetails.user_id,
        "make_id": vehicleDataDetails.vechile_make,
        "model_id": vehicleDataDetails.vechile_model,
        "series_id": vehicleDataDetails.series,
        "generation_id": vehicleDataDetails.generation,
        "trim_id": vehicleDataDetails.vechile_engine,
        "fuel_type": vehicleDataDetails.fuel_type,
        "year": vehicleDataDetails.model_year,
        "transmission_type": vehicleDataDetails.transmission_type,
        "mileage": Number(vehicleDataDetails.current_mileage)
      }
      this.BookService.addVehicleByUser(payload).subscribe({
        error: (error) => {
          // console.log(error.error)
        },
        next: (res) => {
          if (res.statusCode == 1) {
            let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
            if (vehicleData != null) {
              let vehicleDataDetails = JSON.parse(vehicleData)
              vehicleDataDetails.vechicle_id = res.vechicle_id
              sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(vehicleDataDetails))
              this.bookAppointment()
            }
          }
        },
      });
    }
  }

  getServiceListPayload() {
    let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
    let serviceDetailsList: any[] = [];
    if (serviceList != null) {
      serviceList = JSON.parse(serviceList)
      let serviceDetails: any = localStorage.getItem(Constants.APP.SERVICE_LIST)
      serviceDetails = JSON.parse(serviceDetails)
      serviceDetails.forEach((element: any) => {
        if(!element.question.fromMaster){
        let service = {
          id: element.answer.id,
          comment: element.comment,
          is_from_all_service: element.is_from_all_service,
          service_id : element.question.service_id,
          selected_answer : element.answer.selected_answer
        }
        serviceDetailsList.push(service)
      }
      });
    }
    return serviceDetailsList
  }

  bookAppointment() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let estimateData = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    let serviceData = this.getServiceListPayload()
    let serviceTime = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_TIME)
    let applied_garage_details = localStorage.getItem(Constants.APP.APPLIED_GARAGES_ID)

    if (userdata != undefined) {
      let userDetails = JSON.parse(userdata)
      let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
      let quote_id = sessionStorage.getItem(Constants.APP.QUOTE_ID);
      if (serviceList != null && estimateData != null && serviceTime != null) {
        let serviceListDetails = JSON.parse(serviceList)
        let estimate_Details = JSON.parse(estimateData)
        let selectedTimeDate = JSON.parse(serviceTime)
        let payload: any = {
          vechicle_id: serviceListDetails.vechicle_id,
          service: serviceData,
          user_id: userDetails.user_id,    
          garage_id: estimate_Details.id,
          status: "booked",
          estimation_type: estimate_Details.quote.estimation_type, 
          estimation: estimate_Details.quote.items,
          amount_to_pay: estimate_Details.quote.finalPrice,
          total_amount: estimate_Details.quote.finalPrice,
          appointments: {
            1: selectedTimeDate[0].exact_date + " " + selectedTimeDate[0].selectedTime,
            2: (selectedTimeDate[1] != undefined) ? selectedTimeDate[1].exact_date + " " + selectedTimeDate[1].selectedTime : ''
          }
        }
        if(applied_garage_details != undefined){
          let applied_garage_data = JSON.parse(applied_garage_details)
          applied_garage_data.forEach((item:any)=>{
            if(item.garage_id == estimate_Details.id ){
              item.status = 'booked'
            }else{
              item.status = 'not booked'
            }
          });
          payload.applied_garage_details = applied_garage_data
         
        }
        if (quote_id != undefined) {
          payload.quote_id = Number(quote_id)
        } else {
          payload.zip_code = localStorage.getItem("address")
        }
        this.BookService.saveEstimate(payload).subscribe({
          next: (res) => {
            if (res.statusCode == 1) {
              this.sharedService.setBookedPopup(true)
              this.route.navigate(['/dashboard'], {state: { notification_data : this.newUserNotification}})
              // this.route.navigate(['/dashboard'])
              sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
              sessionStorage.removeItem(Constants.APP.QUOTE_ID)
              localStorage.removeItem(Constants.APP.SERVICE_LIST)
              localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
              sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
              this.utilsClass.openSuccessSnackBar(this.translate.instant(res.message))
              this.isLoading = false
            }
          },
          error: (err) => {
            // console.log(err)
          }
        })
      }
    }
  }

  fetchCity(){
    this.BookService.fetchCity().subscribe({
      next: (res) => {
        if(res.statusCode == 1){
          this.cityList = res.data
          this.optionCity = res.data.map((item: any) => item.city_name).sort((a: string, b:string) => 
            a.localeCompare(b, 'tr', { sensitivity: 'variant' })
          );
          if(this.cityFormControl.value){
            let city = this.cityList?.find((item: any) => item.city_name?.toLowerCase() == this.cityFormControl.value?.toLowerCase())
            this.fetchDistrict(city.city_id)
            this.disableDistrict = false
          }
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }
  
  fetchDistrict(cityId: number){
    let data = { cityId }
    this.BookService.fetchDistrict(data).subscribe({
      next: (res) => {
        if(res.statusCode == 1){
          this.optionDistrict = res.data.map((item: any) => item.district_name).sort((a: string, b:string) => 
            a.localeCompare(b, 'tr', { sensitivity: 'variant' })
          );
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }
  
  selectCity(data:string){
    this.cityFormControl.setValue(data)
    this.districtFormControl.setValue('')
    if(data == '' || data == undefined){
      this.disableDistrict = true
      this.optionDistrict = []
      this.isCityValid = false
    } else{
      let city = this.cityList.find((item: any) => item.city_name?.toLowerCase() == data?.toLowerCase())
      this.fetchDistrict(city.city_id)
      this.disableDistrict = false
      this.isCityValid = true
    }
    this.btnValidate()
  }
  selectProvince(data:any){
    if(data){
      this.districtFormControl.setValue(data)
      this.isDistictValid = true
    }else{
      this.districtFormControl.setValue('')
      this.isDistictValid = false
    }
    this.btnValidate()
  }

}
function sec(a: number): string {

  if ((a - 1) < 10 && (a - 1) >= 0) return "0" + (a - 1) + "";
  return (a - 1) + ""
  }
  
  interface OTP {
  otp1: string,
  otp2: string,
  otp3: string,
  otp4: string
  }
