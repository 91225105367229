<div *ngIf="isLoading" class="spinner-wrapper" style="height: 300px;"><mat-spinner [diameter]="50"></mat-spinner></div>
<div *ngIf="!isLoading">
  <div class="buttons is-centered" *ngFor="let item of vechileName">
    <!-- <button *ngIf="!Mobileview" (click)="onClickCar(item)" class="button is-large is-fullwidth is-6">
      <figure *ngIf ="item.img != ''" class="image is-48x48">
        <img [src]="item.img">
      </figure>
      <span class="ml-4">{{item.vehicleFullName}}</span>
    </button> -->
    <div *ngIf="!Mobileview" (click)="onClickCar(item)" class="vehicle is-flex is-align-items-center is-justify-content-space-between is-clickable">
      <div class="is-flex is-align-items-center">
        <div>
          <figure *ngIf ="item.img != ''" class="image is-48x48 ml-3 my-1 mr-2">
            <img [src]="item.img">
          </figure>
        </div>   
        <div class="ml-2 veh-font">{{item.vehicleFullName}}
        </div>
      </div>
      <div *ngIf="(selectedVehicle?.vechile_engine && (item.vechile_engine === selectedVehicle?.vechile_engine)) && (!userDetails.user_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <div *ngIf="(selectedVehicle?.vechicle_id && item.vechicle_id === selectedVehicle?.vechicle_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <!-- <div *ngIf="(selectedVehicle?.vechile_engine && (item.vechile_engine === selectedVehicle?.vechile_engine)) && (userDetails.user_id) && !selectedVehicle?.vechicle_id">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div> -->
    </div>
    <div *ngIf="Mobileview" (click)="onClickCar(item)" class="vehicle is-fullwidth is-flex is-flex-direction-column is-align-items-center is-clickable">
      <div *ngIf="(selectedVehicle?.vechicle_id && item.vechicle_id === selectedVehicle?.vechicle_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <div *ngIf="(selectedVehicle?.vechile_engine && (item.vechile_engine === selectedVehicle?.vechile_engine)) && (!userDetails.user_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <figure *ngIf ="item.img != ''" class="image is-48x48">
        <img [src]="item.img">
      </figure>
      <span class="mt-2 has-text-centered mob-veh-font" >{{item.vehicleFullName}}</span>
    </div>
</div> 
<div class="is-flex is-flex-wrap-wrap button-field">
  <button  class="button has-text-weight-semibold add-vehicle is-flex is-justify-content-center" (click)="onClickAdd()"> <i class="fas fa-plus"></i> &nbsp; {{'SERVICE.TEXT_38' | translate }}
  </button>
  <button [disabled]="!selectedVehicle" [ngClass]="selectedVehicle ? 'is-success' : 'button-disabled'" class="button has-text-weight-semibold continue-btn is-flex is-justify-content-center" (click)="clickContinue()"> {{'COMMON.CONTINUE' | translate}}
  </button>  
</div> 
<!-- <button (click) = "onClickAdd()" class="button is-medium is-fullwidth" style="justify-content: center;height: 3.5rem;">
    <span style="color: #aad043;" class="icon is-small">
      <i class="fas fa-plus"></i>
    </span>
    <span class=" has-text-weight-semibold" style="color: #aad043;font-size: 1.2rem;">{{ 'SERVICE.TEXT_38' | translate}}</span>
</button> -->
</div>
<!-- <div
*ngIf="showVehicleChangedPopup"
class="modal fade is-active"
>
<div class="modal-card">
  <div class="modal-card-body py-5">
    <div class="is-flex is-justify-content-center">
    </div>
    <div class="has-text-centered pb-4 pt-3">
      {{ "DASHBOARD.YOU_CHANGED_THE_VEHICLE" | translate }}
    </div> 
    <div class="is-flex is-justify-content-space-evenly">
      <div
        class="button btn_save_estimate has-text-weight-semibold"
        (click)="nextStepper()"
      >
        {{ "DASHBOARD.OK" | translate }}
      </div>
    </div>
  </div>
</div>
</div> -->

