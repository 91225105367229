
<div class=" is-flex is-justify-content-space-between is-align-items-center">
    <h2>{{'PROFILE.ADD_REVIEW' | translate}}</h2>
    <button (click)="closeReview()"
    class="delete is-large is-clickable">
  </button>
</div>
<div>
  <star-rating [value]="starCount" [totalstars]="5" checkedcolor="#AAD043" uncheckedcolor="#838383" size="23px" [readonly]="false" (rate)="starValue($event)"></star-rating>
</div>

<div>
    <textarea style="resize: none;" rows="5" class="textarea mt-3 text-align-left" [(ngModel)]="feedback"
    :placeholder= "{{'PROFILE.REVIEW_PLACEHOLDER' | translate}}"></textarea>
</div>

<div>
    <div class="mt-3 columns">
        <div class="column">
            <button (click)="addReview()"  class="button is-pulled-right is-success has-text-weight-semibold mt-3 mr-3"> {{'PROFILE.SHARE' | translate}}
            </button> 
        </div> 
    </div>
</div>