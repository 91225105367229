import { Component, Input, OnInit, Output } from '@angular/core';
import { Utils } from 'src/app/common/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-location-tab',
  templateUrl: './location-tab.component.html',
  styleUrls: ['./location-tab.component.scss']
})
export class LocationTabComponent implements OnInit {
  @Input() estimate_item: Estimate = {}
  lat: number = 0;
  lng: number = 0;
  mapURL: any = ''
  timingData: any = []
  zipcode: string = ''
  public origin: any;
  public destination: any;
  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem("zipcode") != null){
      this.zipcode = localStorage.getItem("zipcode") || ''
    }
    let address = localStorage.getItem('address') || ''
      this.origin = address;
    if (this.estimate_item != null) {
      this.lat = Number(this.estimate_item.latitude)
      this.lng = Number(this.estimate_item.longitude)
      let destination = this.estimate_item.address1 + " "+this.estimate_item.address2 + " "+this.estimate_item.city+ " "+this.estimate_item.state+ " "+this.estimate_item.country+ " "+this.estimate_item.zip_code
      this.destination = destination;
      this.mapURL = "https://maps.google.com/maps?q=" + this.lat + "," + this.lng + "&z=15&output=embed"
     
      // console.log(this.estimate_item.timings);
      
      if (this.estimate_item.timings != undefined) {
        this.estimate_item.timings.sort((a: any, b: any) => {
          if ((a.day_of_week != undefined && b.day_of_week != undefined) || (a.day_of_week !== '' && b.day_of_week !== '')) {
            return a.day_of_week > b.day_of_week ? 1 : -1
          }
          return -1
        }
        );
        for (let index = 1; index < 8; index++) {
          const findTiming = this.estimate_item.timings.find(element => element.day_of_week === index);
          if (findTiming == undefined) {
            this.estimate_item.timings.push({
              day_of_week: index,
            })
          }
        }
        this.estimate_item.timings.forEach((element: any, index: number) => {
          element.day = moment().locale('tr').weekday(index).format('ddd')
          if (element.start_time.length !== 0 && element.end_time.length !== 0) {
            // console.log('inside')
            let start_time = moment(element.start_time, 'HH:mm').format('h:mm  ')
            let end_time = moment(element.end_time, 'HH:mm').format('  HH:mm ')
            element.time = start_time +' - '+ end_time
          }else{
            element.time = 'Kapalı'
          }
        });
      }
    }
    console.log(this.estimate_item.timings);
    
  }

}  
interface Estimate {
  latitude?: string;
  longitude?: string;
  locationUrl?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
  timings?: [{
    id?: number,
    day_of_week?: number | 0,
    start_time?: string,
    end_time?: string,
    time?: string,
    day?: string
  }];
}