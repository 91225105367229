import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Utils } from 'src/app/common/utils';
import { Constants } from 'src/app/Constants/constants';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({   
  selector: 'app-dashboard-estimate-line-item',
  templateUrl: './dashboard-estimate-line-item.component.html',
  styleUrls: ['./dashboard-estimate-line-item.component.scss']
})
export class DashboardEstimateLineItemComponent implements OnInit {
  Router: any;
  amenitiesLength: number = 5;
  defaultAmenitiesCount: number = 5;
  specializationLength: number = 5;
  defaultSpecializationCount: number = 5;

  constructor(private BookingService: BookingServiceService,
    private sharedService: SharedServiceService,
    private Translate: TranslateService,
    private Route: Router,
    private socketService: SocketService,
    private Utils: Utils) { }
  @Input() estimateDashboardItem: any;
  @Output() initCancel = new EventEmitter<string>();
  @Input() estimateindex :any;
    @Input() estimatecurrIndex:any;
    @Output() currIndexChanged : EventEmitter<number> = new EventEmitter<number>();
  statusTAG: string = ''   
  statusMain: number = 0
  displayInfo: string = ''
  estimationDetails: any = null
  mapURL: any
  show_message: boolean = false
  show_cancel: boolean = false
  Data: any=[];
  selectedItemIndex: number | null = null;
  showCount : boolean = false
  public destination: any
  openAccordian: boolean = false;
  isMobile: boolean = false;
  estimation_cancel: boolean = false;
  show_popup:boolean =false;

  ngOnInit(): void {
    if(this.Utils.isMobile()){
      this.defaultAmenitiesCount = 2
      this.amenitiesLength = 2 
    }
    if(this.Utils.isMobile()){
      this.defaultSpecializationCount = 2
      this.specializationLength = 2 
    }
    if (this.estimateDashboardItem.status == 'booked' || this.estimateDashboardItem.status == 'confirmed') {      
      this.statusTAG = 'Rezervasyon yapıldı'
      let appDate = ''
      if (this.estimateDashboardItem.appointment2 != null && this.estimateDashboardItem.appointment2 != '') {
        appDate = this.estimateDashboardItem.appointment2
      } else {
        appDate = this.estimateDashboardItem.appointment1
      }
      let todayDate = moment()
      let isApp1Reached = moment(this.estimateDashboardItem.appointment1, 'DD/MM/YYYY HH:mm');
      // console.log('estimate time')
      if(this.estimateDashboardItem.appointment2 != null && this.estimateDashboardItem.appointment2 != '' && moment(this.estimateDashboardItem.appointment1, 'DD/MM/YYYY HH:mm').isAfter(todayDate)){
        let isApp2Reached = moment(this.estimateDashboardItem.appointment2, 'DD/MM/YYYY HH:mm');
        let diff_app1 = isApp1Reached.diff(todayDate,'days')
        let diff_app2 = isApp2Reached.diff(todayDate,'days')
        
        if(diff_app1 < diff_app2 || diff_app1 == 0 || diff_app1 == diff_app2){
          appDate = this.estimateDashboardItem.appointment1
        }else{
          appDate = this.estimateDashboardItem.appointment2
        }
      }

      let updateDate = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('MMMM DD, YYYY')
      let updateTime = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('HH:mm')

      this.estimateDashboardItem.updateDate = updateDate
      this.estimateDashboardItem.updateTime = updateTime
      if(this.estimateDashboardItem.invoice_items?.total > 0 ){
        this.estimateDashboardItem.total = Number(this.estimateDashboardItem.invoice_items?.total).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
      }
      else{
        this.estimateDashboardItem.total = 0
      }

      this.estimateDashboardItem.booked_date = moment(this.estimateDashboardItem.date_create).locale('tr').format('MMMM DD, YYYY')
     
      this.statusMain = 1
    } else if (this.estimateDashboardItem.status == 'estimate' || this.estimateDashboardItem.status == 'Awaiting') {
      this.statusTAG = '10 gün içinde sona erecek'
      this.statusMain = 2
      this.Translate.get('DASHBOARD.ESTIMATE_TEXT', { estimate_count: this.estimateDashboardItem.garage_count, zipcode: this.estimateDashboardItem.zip_code }).subscribe((res: string) => {
        this.displayInfo = res
        this.estimateDashboardItem.estimate_date = moment(this.estimateDashboardItem.date_create).locale('tr').format('MMMM DD, YYYY')
      let estimate_expiry = moment(this.estimateDashboardItem.date_create).add(10, 'day').toDate();
      this.estimateDashboardItem.estimate_expiry = moment(estimate_expiry).locale('tr').format('MMMM DD, YYYY')
       
      });
    } else if (this.estimateDashboardItem.status == 'cancelled') {
      this.statusTAG = 'iptal edilen tahmin'
      let updateDate = moment(this.estimateDashboardItem.date_update).locale('tr').format('MMMM DD, YYYY')
      this.estimateDashboardItem.cancelledFormatDate = updateDate
      this.statusMain = 3
      if(this.estimateDashboardItem.invoice_items?.total > 0 ){
        this.estimateDashboardItem.total = Number(this.estimateDashboardItem.invoice_items?.total).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
      }
      else{
        this.estimateDashboardItem.total = 0
      }
      this.estimateDashboardItem.estimate_date = moment(this.estimateDashboardItem.date_create).locale('tr').format('MMMM DD, YYYY')
      let estimate_expiry = moment(this.estimateDashboardItem.date_create).add(10, 'day').toDate();
      this.estimateDashboardItem.estimate_expiry = moment(estimate_expiry).locale('tr').format('MMMM DD, YYYY')
      this.estimateDashboardItem.appointmentDate = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('MMMM DD, YYYY')
      this.estimateDashboardItem.appointmentTime = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('HH:mm')
    } else if (this.estimateDashboardItem.status == 'expired') {
      this.statusTAG = 'süresi dolmuş tahmin'
      let appDate = ''
      if (this.estimateDashboardItem.appointment2 != null && this.estimateDashboardItem.appointment2 != '') {
        appDate = this.estimateDashboardItem.appointment2
      } else {
        appDate = this.estimateDashboardItem.appointment1
      }
      let updateDate = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('MMMM DD, YYYY')
      this.estimateDashboardItem.expiredFormatDate = updateDate
      this.statusMain = 4      
      if(this.estimateDashboardItem.invoice_items?.total > 0 ){
        this.estimateDashboardItem.total = Number(this.estimateDashboardItem.invoice_items?.total).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
      }
      else{
        this.estimateDashboardItem.total = 0
      } 
      this.estimateDashboardItem.appointmentDate = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('MMMM DD, YYYY')
      this.estimateDashboardItem.appointmentTime = moment(this.estimateDashboardItem.confirmed_appointment,'DD/MM/YYYY HH:mm').locale('tr').format('HH:mm')
      this.estimateDashboardItem.dateUpdate = moment(this.estimateDashboardItem.date_update,'YYYY/MM/DD HH:mm').locale('tr').format('MMMM DD, YYYY')

    
    }  
  

    if(this.estimateDashboardItem.items != null){

      this.estimateDashboardItem.items[0].base_price = Number(this.estimateDashboardItem.items[0].base_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}) 
    }
   

    if(this.estimateDashboardItem.garage){
      let destination = this.estimateDashboardItem.garage[0].address1 + " "+this.estimateDashboardItem.garage[0].address2 + " "+this.estimateDashboardItem.garage[0].city+ " "+this.estimateDashboardItem.garage[0].state+ " "+this.estimateDashboardItem.garage[0].country+ " "+this.estimateDashboardItem.garage[0].zip_code
      this.destination = destination;
      // console.log(this.destination);

    }

    this.mapURL = "https://maps.google.com/maps?q="+this.estimateDashboardItem.lat+","+this.estimateDashboardItem.lan+"&z=15&output=embed"
    this.estimateDashboardItem.quote_id = this.estimateDashboardItem?.items[0]?.quote_id

    this.getRating()
    this.setupSocketListeners()
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });    
    this.estimateDashboardItem.original_price = 0
    this.estimateDashboardItem?.invoice_items?.items?.forEach((element: any) => {   
      this.estimateDashboardItem.original_price += element?.base_price
    })
    this.estimateDashboardItem.original_price = this.estimateDashboardItem.original_price.toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')      
    
  }
  setupSocketListeners() {
    this.socketService
      .listen('chat')
      .subscribe((data) => {
        if(this.estimateDashboardItem.quote_id === data.job_id){
          this.estimateDashboardItem.notification_count++
        } 
      });
  }
  viewDetails() {
    if(this.estimateDashboardItem.garage_id){
      this.Route.navigate(['/service/estimates'], { state: { estimateViewItem: this.estimateDashboardItem},queryParams : {garage_id : this.estimateDashboardItem.garage_id} })  
    }else{
      this.Route.navigate(['/service/estimates'], { state: { estimateViewItem: this.estimateDashboardItem} })  
    }
  } 
  viewQuotation() {
    this.Route.navigate(['/service/estimates'], { state: { estimateViewItem: this.estimateDashboardItem, estimateQuotationItem:"Quotation" }, queryParams: {from : 'dashboard'} })  
  } 

  editBooking(item : any) {
    if(item.vehcileName){
      
      this.Route.navigate(['/service/book'], { state: { estimateViewItem: this.estimateDashboardItem, reschedule : true } })
    }
    else{
      this.Utils.openErrorSnackBar('DASHBOARD.VEHICLE_DELETED');
    }
  }  
  requestQuote(item: any){
    if(item.vehcileName){
      this.show_popup= true
    }
    else{
      this.Utils.openErrorSnackBar('DASHBOARD.VEHICLE_DELETED');
    }
  }
  getRating(){
    
    if(this.estimateDashboardItem.overAllReviews != null){
      
      this.estimateDashboardItem.rating = {}
      let totalRating = 0;
      this.estimateDashboardItem.overAllReviews.forEach((element:any) => {
         totalRating += element.ratings;
      });
      this.estimateDashboardItem.rating.cum_rating = totalRating / this.estimateDashboardItem.overAllReviews.length
      this.estimateDashboardItem.rating.totalRating = this.estimateDashboardItem.overAllReviews.length
    }
  }


  cancelEstimate() {
    this.initCancel.emit('0')
    let payload = {
      "quote_id": this.estimateDashboardItem.quote_id,
      "status": "cancelled",
      "garage_id": this.estimateDashboardItem.garage_id
    }
    this.BookingService.saveEstimate(payload).subscribe({
      next: (res) => {
        if (res.statusCode == 1) {
          if(res.notification_data){
            this.socketService.emit('garageNotificationById',res.notification_data)
          }
          this.initCancel.emit('1')
          this.Utils.openSuccessSnackBar(this.Translate.instant('DASHBOARD.ESTIMATION_CANCEL'))
        }
      },
      error: (err) => {
        // console.log(err)
        this.Utils.openErrorSnackBar(this.Translate.instant('DASHBOARD.ESTIMATION_NOT_CANCEL'))
        this.initCancel.emit('-1')
      },
      complete: () => {
        this.initCancel.emit('2')
      }
    })
    this.initCancel.emit('-1')
  }

  onExpandEstimationCollapse(index:any) {

    const i = index
    this.currIndexChanged.emit(i)
    if(this.estimatecurrIndex === index){
      this.openAccordian = !this.openAccordian
    } else {
      this.openAccordian = false
    }
  }
  messagePopup(){ 
    this.show_message = !this.show_message
  }
  hideMessagePopup(ev: any){
    this.show_message = ev;
    this.estimateDashboardItem.notification_count = 0
  }

  openCancelPopup(data:any){
    if(data == 1){
    this.estimation_cancel = true
    }
    this.show_cancel = true
  }

  toggleDetails(index: number,item:any) {
    this.selectedItemIndex = this.selectedItemIndex === index ? null : index;    
    this.Data = JSON.parse(item.selected_answer)  
    if(item.notes.length > 25){
      item.showMore = true
    }else{
      item.showMore = false
    }  
  }
}
