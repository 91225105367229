<script> bulmaSteps.attach(); </script>
<div class="columns m-0 has-text-centered">
  <ul *ngIf="!mobileview"  class="progressbar w-100 d-center">
    <li [ngClass]="activeStepper >= 1? 'active' : '' "> <span>{{'STEEPER.STEP_1' | translate}}</span> </li>
    <li [ngClass]="activeStepper >= 2? 'active' : '' "><span>{{'STEEPER.STEP_2' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 3? 'active' : '' "><span>{{'STEEPER.STEP_3' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 4? 'active' : '' "><span>{{'STEEPER.STEP_4' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 5? 'active' : '' "><span>{{'STEEPER.STEP_5' | translate}}</span></li>
  </ul>
  <ul *ngIf="mobileview"  class="progressbar w-100 d-center">
    <li [ngClass]="activeStepper >= 1? 'active' : '' "> <span>{{'STEEPER.STEP_1_1' | translate}}</span> </li>
    <li [ngClass]="activeStepper >= 2? 'active' : '' "><span>{{'STEEPER.STEP_2_1' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 3? 'active' : '' "><span>{{'STEEPER.STEP_3_1' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 4? 'active' : '' "><span>{{'STEEPER.STEP_4_1' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 5? 'active' : '' "><span>{{'STEEPER.STEP_5' | translate}}</span></li>
  </ul>
</div>

<!-- <div class="columns  is-hidden-desktop has-text-centered">
<ul>
  <hr class="hr-style2" />
  <li class="stepper-design" *ngIf="activeStepper===1" [ngClass]="activeStepper===1? 'active' : '' ">Services</li>
  <li class="stepper-design" *ngIf="activeStepper===2" [ngClass]="activeStepper===2? 'active' : '' ">Vehicle</li>
  <li class="stepper-design" *ngIf="activeStepper===3" [ngClass]="activeStepper===3? 'active' : '' ">Estimates</li>
  <li class="stepper-design" *ngIf="activeStepper===4" [ngClass]="activeStepper===4? 'active' : '' ">Book</li>
  <li class="stepper-design" *ngIf="activeStepper===5" [ngClass]="activeStepper===5? 'active' : '' ">Confirm</li>
  </ul>
</div> -->

<!-- <div class="steps is-small">
  <div class="step-item" [ngClass]="activeStepper===1? 'is-completed' : '' ">
    <div class="step-marker" [ngClass]="activeStepper===1? 'has-background-success' : '' ">
    </div>
    <div class="step-details">
      <p>{{ 'COMMON.SERVICES' | translate }}</p>
    </div>
  </div>
  <div class="step-item" [ngClass]="activeStepper===2? 'is-completed' : '' ">
    <div class="step-marker"  [ngClass]="activeStepper===2? 'has-background-success' : '' "></div>
    <div class="step-details">
      <p>{{ 'COMMON.VEHICLE' | translate }}</p>
    </div>
  </div>
  <div class="step-item" [ngClass]="activeStepper===3? 'is-completed' : '' ">
    <div class="step-marker" [ngClass]="activeStepper===3? 'has-background-success' : '' "></div>
    <div class="step-details">
      <p>{{ 'COMMON.BOOK' | translate }}</p>
    </div>
  </div>
  <div class="step-item" [ngClass]="activeStepper===4? 'is-completed' : '' ">
    <div class="step-marker" [ngClass]="activeStepper===4? 'has-background-success' : '' "></div>
    <div class="step-details">
      <p>{{ 'COMMON.ESTIMATES' | translate }}</p>
    </div>
  </div>
  <div class="step-item" [ngClass]="activeStepper===5? 'is-completed' : '' ">
    <div class="step-marker" [ngClass]="activeStepper===5? 'has-background-success' : '' "></div>
    <div class="step-details">
      <p>{{ 'COMMON.CONFIRM' | translate }}</p>
    </div>
  </div>
</div> -->




<!-- <div class="pl-6 pr-6">
        <hr class="hr-style">
<div class="columns  is-hidden-mobile has-text-centered">
    <div class="column">
        <button class="button is-small is-rounded" [ngClass]="activeStepper === 1 || activeStepper>=1? 'stepper-is-active is-success' : 'stepper-is-inactive' ">
            <span class="has-text-weight-bold">
              1
            </span>
          </button>
          <p class="is-size-6" [ngClass]="activeStepper === 1? 'has-text-weight-bold' : '' ">Services</p>
    </div>
    <div class="column">
        <button class="button is-small is-rounded" [ngClass]="activeStepper ===2 || activeStepper>=3? 'stepper-is-active is-success' : 'stepper-is-inactive' ">
            <span class="has-text-weight-bold">
              2
            </span>
          </button>
          <p class="has-text-weight-normal is-size-6" [ngClass]="activeStepper === 2 ? 'has-text-weight-bold' : '' ">Vehicle</p>
    </div>
    <div class="column">
        <button class="button is-small is-rounded" [ngClass]="activeStepper === 3 || activeStepper>=4? 'stepper-is-active is-success' : 'stepper-is-inactive' ">
            <span class="has-text-weight-bold">
              3
            </span>
          </button>
          <p class="has-text-weight-normal is-size-6"  [ngClass]="activeStepper === 3 ? 'has-text-weight-bold' : '' ">Estimates</p>
    </div>
    <div class="column">
        <button class="button is-small is-rounded" [ngClass]="activeStepper === 4 || activeStepper>=4? 'stepper-is-active is-success' : 'stepper-is-inactive' ">
            <span class="has-text-weight-bold">
              4
            </span>
          </button>
          <p class="has-text-weight-normal is-size-6"  [ngClass]="activeStepper === 4 ? 'has-text-weight-bold' : '' ">Book</p>
    </div>
    <div class="column">
        <button class="button is-small is-rounded" [ngClass]="activeStepper === 5 ? 'stepper-is-active is-success' : 'stepper-is-inactive' ">
            <span class="has-text-weight-bold">
              5
            </span>
          </button>
          <p class="has-text-weight-normal is-size-6"  [ngClass]="activeStepper === 5 ? 'has-text-weight-bold' : '' ">Confirm</p>
    </div>
</div>
<div class="columns  is-hidden-desktop has-text-centered">
  <div class="column">
      <button class="button is-small is-rounded" [ngClass]="activeStepper === 1 || activeStepper>=1? 'stepper-is-active is-success' : 'stepper-is-inactive' ">
          <span class="has-text-weight-bold">
            {{activeStepper}}
          </span>
        </button>
        <p class="is-size-6 has-text-weight-bold" *ngIf="activeStepper === 1">Services</p>
        <p class="is-size-6 has-text-weight-bold" *ngIf="activeStepper === 2">Vehicle</p>
        <p class="is-size-6 has-text-weight-bold" *ngIf="activeStepper === 3">Estimates</p>
        <p class="is-size-6 has-text-weight-bold" *ngIf="activeStepper === 4">Book</p>
        <p class="is-size-6 has-text-weight-bold" *ngIf="activeStepper === 5">Confirm</p>
  </div>
  </div>
</div> -->
