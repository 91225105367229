<div (click)="onClickOutFunction()">
  <app-user-header (hideSearchField)="searchField($event)"></app-user-header>
  <div *ngIf="isLoading" class="spinner-wrapper" style="height: 300px">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="back-to-top">
    <a *ngIf="!showButton" (click)="goToTop()"
      ><i class="fas fa-arrow-circle-up"></i
    ></a>
  </div>
  <nav
    *ngIf="!isLoading"
    class="container-wrapper dashboard-nav is-flex py-2 is-hidden-mobile"
    [ngClass]="isSearchField ? 'sticky-section' : 'mobile-sticky'"
  >
    <div
      *ngIf="bookedEstimateList.length != 0"
      class="tab-list px-3 is-clickable"
      [ngClass]="appointment ? 'filter-bg ' : ''"
      (click)="
        bookedEstimateList.length != 0
          ? scrollToElementById('appointment')
          : scrollError('appointment')
      "
    >
      <img src="../../../assets/svg/appoinment.svg" class="mb-1" />
      <p class="">{{ "DASHBOARD.MY_APPOINTMENTS" | translate }}</p>
    </div>
    <div
      *ngIf="estimateList.length != 0"
      class="tab-list px-3 is-clickable"
      [ngClass]="estimate ? 'filter-bg ' : ''"
      (click)="
        estimateList.length != 0
          ? scrollToElementById('estimation')
          : scrollError('estimation')
      "
    >
      <img src="../../../assets/svg/estimated_price.svg" class="mb-1" />
      <p>{{ "DASHBOARD.OFFERS" | translate }}</p>
    </div>
    <div
      *ngIf="expcancellist.length != 0"
      class="tab-list px-3 is-clickable"
      [ngClass]="expired ? 'filter-bg ' : ''"
      (click)="
        expcancellist.length != 0
          ? scrollToElementById('expired')
          : scrollError('expired')
      "
    >
      <img src="../../../assets/svg/expired.svg" class="mb-1" />
      <p>{{ "DASHBOARD.OLD_REQUESTS" | translate }}</p>
    </div>
  </nav>
  <div
    *ngIf="!isLoading && dropList"
    class="dropDown is-hidden-tablet is-flex is-justify-content-center"
    [ngClass]="isSearchField ? 'desktopsticky' : 'mobsticky'"
  >
    <div style="width: 150px">
      <app-dropdown
        [option_list]="dropdown_list"
        [selected_value]="selected_option"
        (option_changed)="serviceChanged($event)"
      ></app-dropdown>
    </div>
  </div>
  <div class="is-flex is-flex-direction-column is-justify-content-space-between" style="min-height: calc(100vh - 144px);">
    <div *ngIf="!isLoading" class="container-wrapper" id="appointment">
      <h2 class="pt-2 text-size-24 selva">
        {{ "DASHBOARD.UPCOMING_APPOINTMENTS" | translate }}
      </h2>
      <div class="mt-2" *ngIf="!isMobile && bookedEstimateList.length > 0 ">
        <p>
          {{ "DASHBOARD.APPOINTMENT_CONTENT_1" | translate }}  {{ "DASHBOARD.APPOINTMENT_CONTENT_2" | translate }}
        </p>
      </div>

      <div class="mt-2" *ngIf="isMobile && bookedEstimateList.length > 0 ">
        <p *ngIf="!showApp">
          {{ "DASHBOARD.APPOINTMENT_CONTENT_1" | translate }} <span class="is-clickable more" (click)="showApp = true">{{'DASHBOARD.MORE' | translate}}</span>
        </p>
      <p *ngIf="showApp">
        {{ "DASHBOARD.APPOINTMENT_CONTENT_1" | translate }}  {{ "DASHBOARD.APPOINTMENT_CONTENT_2" | translate }} <span class="is-clickable more" (click)="showApp = false">{{'DASHBOARD.LESS' | translate}}</span>
      </p>
    </div>
  
      <div
        class="mt-3 w-bg-with-border-radius is-justify-content-space-around"
        style="background-color: transparent">
        <div class="colums" *ngIf="bookedEstimateList.length == 0">
          <div class="column is-12  p-0">
            <div class="no-appointments is-flex-tablet is-align-items-center ">
              <div class="appoiment-img is-flex is-align-items-center is-justify-content-center">
                <img src="../../../assets/img/estimate.png" />
              </div>
              <div>
              <p [ngClass]="isMobile?'has-text-centered': '' " class="text-size-20" style="color: #a3a3a3">
                {{ "DASHBOARD.NO_UPCOMING_APPOINTMENTS" | translate }}
              </p>
              <!-- <p [ngClass]="isMobile?'has-text-centered': '' " style="color: #a3a3a3">
                  {{ "DASHBOARD.APPOINTMENT_TEXT_2" | translate }}
                </p> -->
          </div>
            </div>
          </div>
        </div>
        <div class="mt-4" *ngIf="bookedEstimateList.length > 0">
          <!-- <div class="exp-tag text-size-14 has-text-weight-bold" style="text-transform:none">{{'DASHBOARD.RESERVATION_DONE' | translate}}</div> -->
          <div *ngFor="let item of bookedEstimateList; index as i">
            <div class="mt-2">
              <app-dashboard-estimate-line-item
                [id]="'job-' + item.id"
                [estimateDashboardItem]="item"
                [estimateindex]="i"
                [estimatecurrIndex]="estimatecurrIndexAppoinment"
                (currIndexChanged)="estimatecurrIndexAppoinment = $event"
                (initCancel)="updateFromChild($event)"
              ></app-dashboard-estimate-line-item>
            </div>
          </div>
        </div>
        <div class="colums" id="estimation">
          <div class="column is-12 pl-0 pb-0" *ngIf="estimateList.length > 0">
            <h2 class="text-size-24">
              {{ "DASHBOARD.YOUR_OFFER_REQUESTS" | translate }}
            </h2>
          </div>
          <!-- <p class="" *ngIf="estimateList.length > 0">
            {{ "DASHBOARD.APPOINTMENT_TEXT_1" | translate }}
          </p> -->
          <div class="mt-2" *ngIf="estimateList.length > 0">
            <p *ngIf="!isMobile">
              {{ "DASHBOARD.IT_WILL_EXPIRE_IN_10DAYS_TEXT_1" | translate }}{{ "DASHBOARD.IT_WILL_EXPIRE_IN_10DAYS_TEXT_2" | translate }}
            </p>
            <p *ngIf="!showEst && isMobile">
              {{ "DASHBOARD.IT_WILL_EXPIRE_IN_10DAYS_TEXT_1" | translate }} <span class="is-clickable more" (click)="showEst = true">{{'DASHBOARD.MORE' | translate}}</span>
            </p>
          <p *ngIf="showEst && isMobile">
            {{ "DASHBOARD.IT_WILL_EXPIRE_IN_10DAYS_TEXT_1" | translate }}{{ "DASHBOARD.IT_WILL_EXPIRE_IN_10DAYS_TEXT_2" | translate }} <span class="is-clickable more" (click)="showEst = false">{{'DASHBOARD.LESS' | translate}}</span>
          </p>
          </div>
          <div *ngFor="let item of estimateList; index as i">
            <div class="mt-2">
              <app-dashboard-estimate-line-item
                [id]="'job-' + item.id"
                [estimateDashboardItem]="item"
                [estimateindex]="i"
                [estimatecurrIndex]="estimatecurrIndexEstimation"
                (currIndexChanged)="estimatecurrIndexEstimation = $event"
                (initCancel)="updateFromChild($event)"
              ></app-dashboard-estimate-line-item>
            </div>
          </div>
          <div class="colums" id="expired">
            <div class="column is-12 p-0" *ngIf="expcancellist.length > 0">
              <h2 class="text-size-24 mt-6">
                {{ "DASHBOARD.OLD_REQUESTS" | translate }}
              </h2>
            </div>
            <!-- <p class="mt-3" *ngIf="expcancellist.length > 0">
              {{ "DASHBOARD.APPOINTMENT_TEXT_1" | translate }}
            </p> -->
            <!-- <div class="mt-2" *ngIf="expcancellist.length > 0">
              <div
                class="text-size-16"
                style="text-transform: none"
              >
                {{ "DASHBOARD.EXPIRED_PREDICTION" | translate }}
              </div>
            </div> -->
            <div *ngFor="let item of expcancellist; index as i">
              <div class="mt-4">
                <app-dashboard-estimate-line-item
                  [id]="'job-' + item.id"
                  [estimateDashboardItem]="item"
                  [estimateindex]="i"
                  [estimatecurrIndex]="estimatecurrIndexExpired"
                  (currIndexChanged)="estimatecurrIndexExpired = $event"
                  (initCancel)="updateFromChild($event)"
                ></app-dashboard-estimate-line-item>
              </div>
            </div>
            <!-- <div class="colums">
              <div class="column is-12 p-0" *ngIf="cancelledEstimateList.length > 0">
                  <h2 class="text-size-28 mt-6">İptal edilen tahminler</h2>
              </div>   
              <p class="mt-3" *ngIf="cancelledEstimateList.length > 0">Teklif fiyatları, verdiğiniz bilgiler kapsamında hazırlanan en yakın tahmini fiyatlardır. Nihayi fiyat, servis ziyaretinizde yapılacak ilk inceleme ile değişiklik gösterebilir.</p>
              <div class="mt-4" *ngIf="cancelledEstimateList.length > 0"><div class="exp-tag text-size-12" style="background-color: #666666;text-transform:none">iptal edilen tahmin</div>
          </div>
          <div *ngFor="let item of cancelledEstimateList;index as i">
              <div class="mt-5">
                  <app-dashboard-estimate-line-item [estimateDashboardItem]="item" (initCancel)="updateFromChild($event)"></app-dashboard-estimate-line-item>
              </div>
          </div>
  </div> -->
          </div>
        </div>
        <div
          *ngIf="sharedService.bookedPopup$ | async"
          class="modal fade is-active"
        >
          <div class="modal-card">
            <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
              <div>
                <div class="is-flex is-justify-content-center">
                  <img
                    src="../../../assets/svg/booking-confirmed.svg"
                    alt=""
                    style="width: 70px; height: 70px"
                  />
                </div>
                <div class="has-text-centered pb-4 pt-3 has-text-weight-bold">
                  {{ "DASHBOARD.THANKYOU_MSG" | translate }}
                </div>
                <div class="is-flex is-justify-content-space-evenly">
                  <div
                    class="button btn_save_estimate has-text-weight-semibold"
                    (click)="closeBookedPopup()"
                  >
                    {{ "DASHBOARD.OK" | translate }}
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="footer-padding">
      <app-footer></app-footer>
    </div>
  </div>

</div>
