<div (click)="clickOutside()">
<app-header (headerPage)="openguarantee($event)" (howWorksPage)="openHowWorks($event)"></app-header> 
<app-home-banner-one ></app-home-banner-one>
<app-card-one-services-list></app-card-one-services-list>
<div #howWorksSection >
    <app-card-two-booking-steps></app-card-two-booking-steps>
</div>
<app-card-two-booking-steps-two></app-card-two-booking-steps-two>
<div #guaranteeSection >
    <app-card-four-guaranteed-service ></app-card-four-guaranteed-service>

</div>
<!-- <app-card-five-comments></app-card-five-comments> -->
<app-footer></app-footer>
</div>   