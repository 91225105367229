<div style="z-index: 1000 !important; position: relative;">
  <div class="columns ps-relative m-0">
    <div class="column is-12-mobile is-flex is-justify-content-end p-0" >
      <div class="is-clickable"  (click)="clickClose()">
        <button class="delete"></button>
      </div>
    </div>
    
  </div>
  <div class="service-option">
    <div class="columns ps-relative m-0 is-centered">
      <div class="column is-12-mobile pt-0">
        <div class="columns is-mobile m-0">
          <div class="column is-narrow px-0 py-0">
            <div class="profile-field">
              <div class="profile-img">
                <img src="{{estimateItem.logo_url}}">
              </div> 
            </div>
          </div>
          <div class="column is-fluid px-2 py-0">
            <h3 class="is-size-6-mobile my-0" style="font-size: 24px;">{{estimateItem?.company_name}}</h3>
            <div class="">
              <div class="star-rating">
                <star-rating [value]="estimateItem.rating.cum_rating" [totalstars]="5" checkedcolor="#AAD043"
                  uncheckedcolor="#c7c6c6" size="23px" [readonly]="true"></star-rating>
                <span *ngIf="estimateItem.rating.totalRating" class="rating-count text-size-13">( {{estimateItem.rating.cum_rating | number: '1.0-1'}}/{{estimateItem.rating.totalRating}} )</span>
              </div>
              <p class="text-size-14 pb-1"><span class="brand-color has-text-weight-bold"> {{estimateItem.distance | number :
                '1.2-2'}} Km</span> - {{estimateItem.state}}</p>
            </div>
            <div class="features-wrapper columns is-multiline pt-3 pl-3">
              <div class="features-inner pt-2" *ngFor="let item of estimateItem?.amenities?.slice(0, amenitiesLength) index as i;">
                <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center">
                  <span class="icon column p-0 is-narrow">
                    <img class="image is-24x24 garage-facility" src="{{item.logo_url}}" />
                  </span>
                  <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
                </div>
              </div>
              <div class="is-clickable more pt-3" *ngIf="estimateItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateItem?.amenities?.length" (click)="amenitiesLength = estimateItem?.amenities?.length">+{{estimateItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
              <div class="is-clickable more pt-3" *ngIf="estimateItem?.amenities?.length === amenitiesLength && estimateItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
            </div>
            <div class="columns ps-relative m-0 is-centered is-hidden-mobile">
              <div class=" column is-12-mobile p-0">
                <div class="pt-1">
                  <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
                    <div class="total-price">
                      <p class="text-size-18 has-text-weight-bold color-arabama-green">{{"COMMON.TOTAL_PRICE" | translate }}</p>
                      <p class="pl-2 text-size-18 has-text-weight-bold">{{estimateItem.invoice_quote.total}} 
                        <span *ngIf="!showQuote" class="view-details is-hidden-desktop" (click)="showQuote = !showQuote"> <img class="arrow-img" src="../../../assets/svg/VectorUp.svg" alt=""></span>
                        <span *ngIf="showQuote" class="view-details is-hidden-desktop" (click)="showQuote = !showQuote"> <img class="arrow-img" src="../../../assets/svg/Vectordown.svg" alt=""></span>
                        <span *ngIf="!showQuote" class="view-details is-hidden-mobile is-hidden-tablet-only" (click)="showQuote = !showQuote">{{ 'COMMON.VIEW_DETAILS' | translate}} <img class="arrow-img" src="../../../assets/svg/VectorUp.svg" alt=""></span>
                        <span *ngIf="showQuote" class="view-details is-hidden-mobile is-hidden-tablet-only" (click)="showQuote = !showQuote">{{ 'COMMON.HIDE_DETAILS' | translate}} <img class="arrow-img" src="../../../assets/svg/Vectordown.svg" alt=""></span>
                      </p>
                    </div>
                    <div class="buttons">
                        <button class="button is-success button_padding has-text-weight-semibold" (click)="nextStepper()">{{
                          'COMMON.BOOK_APPOINTMENT' | translate }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
    </div>
    <div class="columns ps-relative m-0 is-centered pb-2 is-hidden-tablet">
      <div class=" column is-12-mobile py-0">
        <div class="pt-1">
          <div class="is-flex is-flex-wrap-wrap is-justify-content-end">
            <div class="total-price">
              <p class="text-size-18 has-text-weight-bold color-arabama-green">{{"COMMON.TOTAL_PRICE" | translate }}</p>
              <p class="pl-2 text-size-18 has-text-weight-bold">{{estimateItem.invoice_quote.total}}
                <span *ngIf="!showQuote" class="view-details" (click)="showQuote = !showQuote"><img class="arrow-img" src="../../../assets/svg/VectorUp.svg" alt=""></span>
                        <span *ngIf="showQuote" class="view-details" (click)="showQuote = !showQuote"> <img class="arrow-img" src="../../../assets/svg/Vectordown.svg" alt=""></span>
              </p>
            </div>
            <div class="buttons">
                <button class="button is-success button_padding has-text-weight-semibold" (click)="nextStepper()">{{
                  'COMMON.BOOK_APPOINTMENT' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-estimate-tab *ngIf="showQuote" [fromMap]="true" [estimate_item]="estimateItem" [dialog]="dialogRef" [quotation]="quotation"></app-estimate-tab>
  </div>
  
</div>
