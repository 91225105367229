import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-card-two-booking-steps',
  templateUrl: './card-two-booking-steps.component.html',
  styleUrls: ['./card-two-booking-steps.component.scss']
})
export class CardTwoBookingStepsComponent implements OnInit {

  constructor(private translate: TranslateService, private router: Router,) { }

  ngOnInit(): void {    
  }
  goToServices(){
    if(localStorage.getItem("address") == undefined || localStorage.getItem("address") == ''){
      this.router.navigate(['/location'])
    }else{
      this.router.navigate(['/service'])
    }
  }
}
