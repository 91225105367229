import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehcile-delete-confirm-dialog',
  templateUrl: './vehcile-delete-confirm-dialog.component.html',
  styleUrls: ['./vehcile-delete-confirm-dialog.component.scss']
})
export class VehcileDeleteConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VehcileDeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bookingService: BookingServiceService,
    private Translate:TranslateService,) { }

  vehcileDetails: any
  displayInfo: string = ''
  ngOnInit(): void {
    if (this.data != null) {
      this.vehcileDetails = this.data.dataItem
      this.Translate.get('PROFILE.DELETE_INFO', {vehcile_name: this.vehcileDetails.vehicleFullName}).subscribe((res: string) => {
        this.displayInfo = res
    });
    }
  }

  clickClose() {
    this.dialogRef.close()
  }

  deleteVehcile() {
    let payload = {
      "user_id": this.vehcileDetails.user_id,
      "vechicle_id": this.vehcileDetails.vechicle_id
    }
    this.bookingService.removeVehicleByUser(payload).subscribe({
      // complete: () => {  }, // completeHandler
      error: (error) => {
        this.dialogRef.close(0)
        // console.log(error.error)
      }, // errorHandler 
      next: (res) => {
        this.dialogRef.close(1)
        // console.log(res)
      }, // nextHandler
    });
  }

}
