import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VehcileDeleteConfirmDialogComponent } from '../vehcile-delete-confirm-dialog/vehcile-delete-confirm-dialog.component';

@Component({
  selector: 'app-vehcile-edit-confirm-dialog',
  templateUrl: './vehcile-edit-confirm-dialog.component.html',
  styleUrls: ['./vehcile-edit-confirm-dialog.component.scss']
})
export class VehcileEditConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VehcileEditConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {

  }

  CancelVechile(){
    this.dialogRef.close({data : "cancel"})
  }
  clickClose(){
    this.dialogRef.close();
  }
}
