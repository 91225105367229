import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-services-rotors-two',
  templateUrl: './step-services-rotors-two.component.html',
  styleUrls: ['./step-services-rotors-two.component.scss']
})
export class StepServicesRotorsTwoComponent implements OnInit {

  @Output() nextStep:EventEmitter<any>= new EventEmitter();
  @Output() previousStep:EventEmitter<any>= new EventEmitter();
  
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  moveNextStep(){
    this.nextStep.emit();
  }

  movePreviousStep(){
    this.previousStep.emit();
  }

}
