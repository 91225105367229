<!-- <img src="assets/img/arabama.png" height="200" width="200" class="p-4"/> -->
<!-- <app-stepper [activeStepper]="stepperCount"></app-stepper> -->
<!-- <div class="section"> -->
<div class="columns p-2">
    <div class="column">
        <h6 class="is-size-6">
            {{'SERVICE.TEXT_1' | translate}}
    <span style="float: right;color: #C4C4C4;" class="text-size-14">{{isStep}}/3</span><br><br>
        </h6>
        <h6 class="is-size-6 has-text-weight-bold pt-1">
            {{'SERVICE.TEXT_2' | translate}}
            <img *ngIf="isStep>1" (click)="previousStep()" src="assets/svg/arrow-left-circle.svg" style="float: right;"/>
        </h6>
        <app-step-services-rotors-one *ngIf="isStep==1" (nextStep)="nextStep()"  (previousStep)="previousStep()"></app-step-services-rotors-one>
        <app-step-services-rotors-two *ngIf="isStep==2"  (nextStep)="nextStep()" (previousStep)="previousStep()"></app-step-services-rotors-two>
        <app-step-services-rotors-three *ngIf="isStep==3" (nextStep)="nextStep()" (previousStep)="previousStep()"></app-step-services-rotors-three>
    </div>
</div>
<!-- </div> -->
