import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/Constants/constants';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  showBurgerMenu: boolean = false;
  userData:any;
  @Output() headerPage = new EventEmitter<any>();
  @Output() howWorksPage = new EventEmitter<any>();
  nameHover: boolean = false



  constructor(private router: Router, private translate: TranslateService, private sharedServices : SharedServiceService,private socketService:SocketService) { }

  ngOnInit(): void {
    if(localStorage.getItem(Constants.APP.SESSION_USER_DATA)){
      this.userData = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA) || '')
    }
  }

  goLogin(){
    this.router.navigate(['/auth'])
  }

  serviceHistory(){
      this.router.navigate(['/profile/service-history'])
    }
  
  reviews(){
    this.router.navigate(['/profile/reviews'])
  }
  homePage(){
    this.router.navigate(['/dashboard'])

  }

  help(){
    this.router.navigate(['/help'])
  }
  signup(){
    this.router.navigate(['/signup']) 
  }
  guarantee(){
    this.headerPage.emit('guarantee')
  }
  howWorks(){
    this.howWorksPage.emit('how-works')
  }
  logout(){
    localStorage.removeItem(Constants.APP.SERVICE_LIST)
    localStorage.removeItem("address")
    localStorage.removeItem("zipcode")
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
    localStorage.removeItem(Constants.APP.LOCATION_ARRAY)
    localStorage.removeItem(Constants.APP.SESSION_ID)
    localStorage.removeItem(Constants.APP.SESSION_USER_DATA)
    sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
    sessionStorage.removeItem(Constants.APP.QUOTE_ID)
    localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
    this.sharedServices.clearData()
    document.body.classList.remove('inner-stepper');
    this.router.navigate(['/auth'])
    this.socketService.disconnect()
  }

  goToServices(){
    // if(localStorage.getItem("address") == undefined || localStorage.getItem("address") == ''){
    //   this.router.navigate(['/location'])
    // }else{
    //   this.router.navigate(['/service'])
    // }
    const fullUrl = `https://garagedev.nisissolutions.com/`; 
    const newTab = window.open(fullUrl, '_blank');
    if (newTab) {
      newTab.opener = null;
    } else {
      console.error('Failed to open new tab');
    }
  }

}
