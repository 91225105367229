import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.scss']
})
export class LocationSelectComponent implements OnInit {

  constructor(private route:Router) { }

  ngOnInit(): void {
    document.body.classList.add('inner-class');
  }
  backPressed(){
    this.route.navigate(['/home'])
  }

}
