import { Component, OnInit,AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Utils } from 'src/app/common/utils';
import { Constants } from 'src/app/Constants/constants';
declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,AfterViewInit {

  userNameFormcontrol = new FormControl();
  passwordFormControl = new FormControl();
  public auth2: any;
  isValidEmail:boolean = true;
  isValidPassword: boolean = false;
  btnSignInEnabled : boolean = false

  constructor(private router: Router, 
    private translate: TranslateService,
    private gmailService: SocialAuthService,
    private BookingService: BookingServiceService,
    private utilsClass: Utils) { }

  ngOnInit(): void {
    document.body.classList.remove('inner-class');
    document.body.classList.remove('inner-stepper');
    this.removeLoader()
  }

  ngAfterViewInit() {
    this.googleInit();
  }

  public googleInit() {
    
    gapi.signin2.render('my-signin2', {
      'scope': 'profile email',
      'width': 240,
      'height': 50,
      'longtitle': true,
      'theme': 'dark',
      'onsuccess': (param:any) => this.attachSignin(param)
  });
  }

  public attachSignin(element:any) {    
    console.log(element)
  }

  signUP(){
    this.router.navigate(['/signup'])
  }

  loginWithGmail(){
    this.gmailService.signIn(GoogleLoginProvider.PROVIDER_ID)
    .then((res)=>{
      let payload = {
        "provider":res.provider,
        "first_name":res.firstName,
        "last_name":res.lastName,
        "email": res.email,
        "access_token": res.authToken
     }
     this.clickSocialLogin(payload)
    })
  }

  loginWithFaceBook(){
    this.gmailService.signIn(FacebookLoginProvider.PROVIDER_ID)
    .then((res)=>{
      console.log("res ",res);
    })
  }

  clickLogin(){
    this.addLoader()
    let payload={
      user_name: this.userNameFormcontrol.value,
      password: this.passwordFormControl.value
    }
    this.BookingService.login(payload).subscribe((res=>{
      if(res.statusCode === 1){
        this.utilsClass.openSuccessSnackBar(res.message)
        localStorage.setItem(Constants.APP.SESSION_USER_DATA,JSON.stringify(res.data))
        localStorage.setItem(Constants.APP.SESSION_ID,JSON.stringify(res.data.session_id))
        this.userNameFormcontrol.setValue('')
        this.passwordFormControl.setValue('')
        this.router.navigate(['/dashboard'])
        this.removeLoader()
      }
    }),(err)=>{
      console.log(err)
      this.utilsClass.openErrorSnackBar(err.error.message)
      this.removeLoader()
    })
  }

  clickSocialLogin(payload:any){
    this.addLoader();
    this.BookingService.socialLogin(payload).subscribe((res:any)=>{
      if(res.statusCode === 1){
        this.utilsClass.openSuccessSnackBar(res.message)
        localStorage.setItem(Constants.APP.SESSION_USER_DATA,JSON.stringify(res.data))
        localStorage.setItem(Constants.APP.SESSION_ID,JSON.stringify(res.data.session_id))
        this.userNameFormcontrol.setValue('')
        this.passwordFormControl.setValue('')
        this.router.navigate(['/dashboard'])
        this.removeLoader()
      }else{
        this.removeLoader()
      }
    })
  }

  removeLoader(){
    const button = document.getElementById('btnLogin');
    button?.classList.remove('is-loading');
  }
  addLoader(){
    const button = document.getElementById('btnLogin');
    console.log(button)
    button?.classList.add('is-loading');
  }

  onClikShowPassword(){
    const x = document.getElementById("inputPass") as HTMLInputElement;
    const icon = document.getElementById("passEye")
    console.log(x.type)
    if(x.type === 'password'){
      x.type = 'text'
      icon?.classList.add('fa-eye')
      icon?.classList.remove('fa-eye-slash')
    }else{
      x.type = 'password'
      icon?.classList.add('fa-eye-slash')
      icon?.classList.remove('fa-eye')
    }
  }

  onInputChange(value:string){
    if(value.length != 0){
      this.isValidEmail = this.utilsClass.validateEmail(value)
    }
    this.btnSignInEnabled = (this.isValidEmail && this.isValidPassword) ? true : false
    
  }
  onInputPasswordChange(value:string){
    if(value.length != 0){
      this.isValidPassword = true
    }else{
      this.isValidPassword = false
    }
    this.btnSignInEnabled = (this.isValidEmail && this.isValidPassword) ? true : false

  }
}
